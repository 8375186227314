import { createContext, useContext, useMemo, type ReactNode } from 'react';

import { Contract, ethers } from 'ethers';

import { useEthersSigner } from '@/hooks/useEthersSigner';
import config from '@/config/index';
import usdcAbi from '@/abis/usdc.json';
import stoneAbi from '@/abis/stone.json';
import stoneBridgeAbi from '@/abis/stoneBridge.json';
import wUsdmAbi from '@/abis/wUsdm.json';
import airdropController from '@/abis/AirdropController.json';
import burnController from '@/abis/BurnController.json';
import treasureNFTAbi from '@/abis/treasureNFT.json';
import airdropNFTAbi from '@/abis/airdropNFT.json';
import nftTokenController from '@/abis/NftTokenController.json';
import routerAbi from '@/abis/Router.json';
import stakerRouterAbi from '@/abis/StakerRouter.json';
import mTokenStakeAbi from '@/abis/MTokenStake.json';
import tokenDistributerAbi from '@/abis/TokenDistributer.json';

export type ContractsContextValue = {
  usdcContract: null | Contract;
  stoneBridgeContract: null | Contract;
  stoneContract: null | Contract;
  wUsdmContract: null | Contract;
  airdropControllerContract: null | Contract;
  treasureNFTContract: null | Contract;
  airDropNFTContract: null | Contract;
  nftToTokenControllerContract: null | Contract;
  routerContract: null | Contract;
  stakerRouterContract: null | Contract;
  airdropControllerBoxNFTContract: null | Contract;
  airdropControllerBoxNFTContractRead: null | Contract;
  burnControllerContract: null | Contract;
  mTokenStateContract: null | Contract;
  tokenDistributerContract: null | Contract;
};

const {
  USDC_CONTRACT,
  STONE_BRIDGE_CONTRACT,
  STONE_CONTRACT,
  MANTA_PACIFIC_CHAIN,
  WUSDM_CONTRACT,
  AIRDROP_CONTROLLER_CONTRACT,
  BURN_CONTROLLER_CONTRACT,
  AirdropControllerBoxNFT,
  TREASURE_NFT,
  AIRDROP_NFT,
  NFT_TO_TOKEN_CONTROLLER,
  ROUTER_CONTRACT,
  STAKERROUTER_CONTRACT,
  MTOKEN_STAKE,
  TOKEN_DISTRIBUTER_CONTRACT
} = config;
const mantaRPC = MANTA_PACIFIC_CHAIN.rpcUrls.public.http[0];

const mantaProvider = new ethers.providers.StaticJsonRpcProvider(mantaRPC);

const ContractsContext = createContext<ContractsContextValue | null>(null);

export const ContractProvider = ({ children }: { children: ReactNode }) => {
  const signer = useEthersSigner();
  const usdcContract = useMemo(() => {
    if (!signer) {
      return null;
    }
    return new Contract(USDC_CONTRACT, usdcAbi, signer);
  }, [signer]);

  const mTokenStateContract = useMemo(() => {
    if (!signer) {
      return null;
    }
    return new Contract(MTOKEN_STAKE, mTokenStakeAbi, signer);
  }, [signer]);

  const stoneBridgeContract = useMemo(() => {
    if (!signer) {
      return null;
    }
    return new Contract(STONE_BRIDGE_CONTRACT, stoneBridgeAbi, signer);
  }, [signer]);

  const stoneContract = useMemo(() => {
    return new Contract(STONE_CONTRACT, stoneAbi, mantaProvider);
  }, []);

  const wUsdmContract = useMemo(() => {
    return new Contract(WUSDM_CONTRACT, wUsdmAbi, mantaProvider);
  }, []);

  const airdropControllerContract = useMemo(() => {
    return new Contract(
      AIRDROP_CONTROLLER_CONTRACT,
      airdropController,
      mantaProvider
    );
  }, []);

  const airdropControllerBoxNFTContract = useMemo(() => {
    return new Contract(AirdropControllerBoxNFT, airdropController, signer);
  }, [signer]);

  const airdropControllerBoxNFTContractRead = useMemo(() => {
    return new Contract(
      AirdropControllerBoxNFT,
      airdropController,
      mantaProvider
    );
  }, []);

  const treasureNFTContract = useMemo(
    () => new Contract(TREASURE_NFT, treasureNFTAbi, mantaProvider),
    []
  );

  const airDropNFTContract = useMemo(
    () => new Contract(AIRDROP_NFT, airdropNFTAbi, mantaProvider),
    []
  );

  const nftToTokenControllerContract = useMemo(
    () =>
      new Contract(NFT_TO_TOKEN_CONTROLLER, nftTokenController, mantaProvider),
    []
  );

  const routerContract = useMemo(() => {
    if (!signer) {
      return null;
    }
    return new Contract(ROUTER_CONTRACT, routerAbi, signer);
  }, [signer]);

  const stakerRouterContract = useMemo(() => {
    if (!signer) {
      return null;
    }
    return new Contract(STAKERROUTER_CONTRACT, stakerRouterAbi, signer);
  }, [signer]);

  const burnControllerContract = useMemo(() => {
    return new Contract(
      BURN_CONTROLLER_CONTRACT,
      burnController,
      mantaProvider
    );
  }, []);

  const tokenDistributerContract = useMemo(() => {
    if (!signer) {
      return null;
    }
    return new Contract(
      TOKEN_DISTRIBUTER_CONTRACT,
      tokenDistributerAbi,
      signer
    );
  }, [signer]);

  const value = useMemo(
    () => ({
      usdcContract,
      stoneBridgeContract,
      stoneContract,
      wUsdmContract,
      airdropControllerContract,
      treasureNFTContract,
      airDropNFTContract,
      nftToTokenControllerContract,
      routerContract,
      stakerRouterContract,
      airdropControllerBoxNFTContract,
      airdropControllerBoxNFTContractRead,
      burnControllerContract,
      mTokenStateContract,
      tokenDistributerContract
    }),
    [
      usdcContract,
      stoneBridgeContract,
      stoneContract,
      wUsdmContract,
      airdropControllerContract,
      treasureNFTContract,
      airDropNFTContract,
      nftToTokenControllerContract,
      routerContract,
      stakerRouterContract,
      airdropControllerBoxNFTContract,
      airdropControllerBoxNFTContractRead,
      burnControllerContract,
      mTokenStateContract,
      tokenDistributerContract
    ]
  );

  return (
    <ContractsContext.Provider value={value}>
      {children}
    </ContractsContext.Provider>
  );
};

export const useContracts = () => {
  const data = useContext(ContractsContext);
  if (!data || !Object.keys(data)?.length) {
    throw new Error(
      'useContracts can only be used inside of <ContractsContext />, please declare it at a higher level.'
    );
  }
  return data;
};
