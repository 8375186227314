import LoadingIcon from '@/components/Loading';
import config from '@/config';
import useMToken from '@/hooks/useMtoken';
import useBatchMtokenInfo, { DECIMAL_PLACES } from '@/store/useBatchMtoken';
import useUsdPrices from '@/store/useUsdPrices';
import { cleanNumString } from '@/utils/formatNumber';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { notification, Select } from 'antd';
import BigNumber from 'bignumber.js';
import numbro from 'numbro';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAccount, useChainId } from 'wagmi';

import { useContracts } from '@/contexts/ContractsContext';
import { useCheckNetwork } from '@/hooks/useCheckNetwork';
import useTransaction from '@/hooks/useTransaction';
import useGetMtokenStake, {
  MTokenStakeResponse
} from '@/store/useGetMTokenStake';
import useUserInfo from '@/store/useUserInfo';
import { sleep, toWei } from '@/utils/format';
import MTokenUnstakeItem from './components/MTokenUnstakeItem';

const { mETH, mUSD, mBTC, mwBETH, MTOKEN_STAKE } = config;

const tokenList = [
  { label: 'mBTC', value: 'mBTC', min: 0.01, data: mBTC },
  { label: 'mETH', value: 'mETH', min: 0.1, data: mETH },
  { label: 'mUSD', value: 'mUSD', min: 100, data: mUSD },
  { label: 'mwBETH', value: 'mwBETH', min: 0.1, data: mwBETH }
];

function StakeMToken() {
  const {
    userInfo: { user_address }
  } = useUserInfo();

  const { open } = useWeb3Modal();
  const { isConnected } = useAccount();
  const [amount, setAmount] = useState<string>('');
  const [selectedToken, setSelectedToken] = useState<string>();
  const { mTokenStateContract } = useContracts();
  const usdPrices = useUsdPrices();

  const chainId = useChainId();
  const { isMantaPacific, switchToMantaPacific } = useCheckNetwork();
  const {
    data,
    isLoading: mTokenLoading,
    mutate: mTokenMutate
  } = useBatchMtokenInfo();

  const { data: mtokenStakeList, mutate: mTokenStateMutate } =
    useGetMtokenStake();

  const { run: stakeRun, loading: stakeLoading } = useTransaction(
    mTokenStateContract?.stake,
    {
      wait: true
    }
  );

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (tokenListBalanceGtZero?.length <= 0) return;
    setAmount(cleanNumString(e?.target?.value?.trim(), 5));
  };

  const tokenListWithBalance = tokenList.map((item, index) => {
    return { ...item, balance: data?.[index]?.balanceOfOriginData };
  });

  const tokenListBalanceGtZero = (tokenListWithBalance || []).filter(item =>
    item.balance?.gt('0')
  );

  const mtokenNotEmpty = (mtokenStakeList || []).filter(
    item => !!item
  ) as MTokenStakeResponse[];

  const mtokenStakeWithInfo = (mtokenNotEmpty || []).map(item => {
    const findInfo = tokenList.find(
      token =>
        token?.data?.address?.toLowerCase() === item?.mToken?.toLowerCase()
    );
    return {
      ...item,
      name: findInfo?.label,
      price: findInfo?.label ? usdPrices?.[findInfo?.label] : 0
    };
  });

  const mtokenStakeGtZero = mtokenStakeWithInfo?.filter(item =>
    new BigNumber(item?.stakedAmount || 0)?.gt(0)
  );

  useEffect(() => {
    if (mTokenLoading) return;
    if (tokenListBalanceGtZero?.length > 0 && !selectedToken) {
      setSelectedToken(tokenListBalanceGtZero?.[0]?.value);
      return;
    }
    if (tokenListBalanceGtZero?.length == 0 && !selectedToken) {
      setSelectedToken(tokenList?.[0]?.value);
      return;
    }
  }, [selectedToken, tokenListBalanceGtZero, mTokenLoading]);

  useEffect(() => {
    if (user_address) {
      setSelectedToken('');
      setAmount('');
    }
  }, [user_address]);

  const findSelectToken = (tokenList || []).find(
    item => item.value === selectedToken
  );

  const {
    balance,
    isBalanceLoading,
    isOverAllowance,
    isAllowanceLoading,
    approveState,
    getBalance,
    getAllowance
  } = useMToken({
    tokenAddress: findSelectToken?.data?.address,
    approveAddress: MTOKEN_STAKE,
    approveAmount: amount
  });

  const handleMax = () => {
    if (isBalanceLoading) return;
    setAmount((balance as string) || '');
  };

  const handleStake = async () => {
    try {
      if (!findSelectToken?.data?.address || !amount) {
        return;
      }

      if (!isOverAllowance) {
        await approveState.run();
        await getAllowance();
      }

      await stakeRun(findSelectToken.data.address, toWei(amount).toFixed());
    } catch (error: any) {
      console.error('Stake error:', error);
      notification.error({
        message: `Stake error ${error?.message || error}`
      });
    } finally {
      setAmount('');
      await handleRefresh();
    }
  };

  const handleTokenChange = (e: string) => {
    setAmount('');
    setSelectedToken(e);
  };

  const handleRefresh = async () => {
    await getBalance();
    await mTokenMutate();
    await sleep(1200);
    await mTokenStateMutate();
  };

  const renderButton = () => {
    if (!isConnected || !user_address) {
      return (
        <button
          className="flex w-full justify-center btn-primary h-[51px] max-md:h-max"
          onClick={() => open()}
        >
          Connect wallet
        </button>
      );
    }

    if (!isMantaPacific && chainId) {
      return (
        <button
          className="flex w-full justify-center btn-primary h-[51px] max-md:h-max"
          onClick={() => {
            switchToMantaPacific();
          }}
        >
          Switch to Manta Pacific
        </button>
      );
    }

    if (isOverAllowance || !amount) {
      return (
        <button
          className={`flex w-full justify-center btn-primary h-[51px]  max-md:h-max  `}
          disabled={isDisabled || stakeLoading}
          onClick={handleStake}
        >
          {stakeLoading ? <LoadingIcon className="ml-2" /> : 'Stake'}
        </button>
      );
    } else {
      return (
        <button
          className="flex w-full justify-center btn-primary h-[51px] max-md:h-max"
          disabled={isDisabled || approveState?.loading}
          onClick={() => {
            approveState.run();
          }}
        >
          {approveState?.loading ? <LoadingIcon className="ml-2" /> : 'Approve'}
        </button>
      );
    }
  };

  const totalPrice = findSelectToken?.label
    ? (usdPrices[findSelectToken?.label] ?? 0) * Number(amount)
    : 0;

  const isGteMin = new BigNumber(amount).gte(findSelectToken?.min || 0);

  const isDisabled =
    !isConnected ||
    !balance ||
    !selectedToken ||
    !Number(amount) ||
    Number(amount) > Number(balance) ||
    isAllowanceLoading ||
    isBalanceLoading ||
    mTokenLoading ||
    tokenListBalanceGtZero?.length <= 0 ||
    !isGteMin;

  const options =
    tokenListBalanceGtZero?.length > 0 ? tokenListBalanceGtZero : tokenList;
  return (
    <div className="flex flex-row gap-4 max-md:flex-col">
      <div className="relative flex flex-col gap-4 border border-green/60 bg-white/80 rounded-2xl p-4 w-[550px] max-md:w-full">
        <div className="flex flex-col">
          <h3 className="max-md:max-w-[130px] max-md:text-[15px] text-black-title text-base mb-6">
            Stake M-Tokens
          </h3>
          <span className="text-black-title text-sm">
            Stake your M-Tokens to earn more points now!
          </span>
          <span className="text-black-title text-sm">
            The snapshot and distribution will be taken automatically daily at 4
            AM UTC.
          </span>
        </div>
        {isMantaPacific && (
          <div
            className={`flex flex-col p-4 rounded-lg text-primary-black border border-green/40`}
          >
            <div className="flex items-center mb-1">
              <input
                placeholder="0"
                className={`flex-1 font-medium text-xl ${
                  !isGteMin && amount ? 'text-[#f00]' : ''
                } max-md:min-w-[20px]`}
                onChange={handleAmountChange}
                value={amount}
              />
              {mTokenLoading ? (
                <LoadingIcon isDark />
              ) : (
                <Select
                  options={options}
                  className={`stake-select w-[155px] max-md:w-[140px]`}
                  value={selectedToken}
                  onChange={handleTokenChange}
                  popupClassName="stake-select-menu"
                />
              )}
            </div>
            <div className="flex items-center justify-between text-sm max-md:justify-end">
              <span className="mr-auto ">
                ${' '}
                {findSelectToken?.label
                  ? numbro(totalPrice ?? 0).format({
                      thousandSeparated: true,
                      mantissa: DECIMAL_PLACES,
                      trimMantissa: true
                    })
                  : ' --'}
              </span>

              <span>
                <span className="font-medium">Balance: </span>
                <span className="break-all">
                  {findSelectToken &&
                  !isBalanceLoading &&
                  tokenListBalanceGtZero?.length > 0
                    ? balance
                      ? numbro(balance ?? 0).format({
                          thousandSeparated: true,
                          mantissa: DECIMAL_PLACES,
                          trimMantissa: true
                        })
                      : '0'
                    : ' --'}
                </span>
              </span>
              <button
                className="ml-2 mr-3 text-green font-medium"
                onClick={handleMax}
              >
                Max
              </button>
            </div>
          </div>
        )}

        {renderButton()}
        {!isGteMin && amount && (
          <p className="absolute text-[#f00] -bottom-8">
            Did not meet the minimum stake amount
          </p>
        )}
      </div>

      {isMantaPacific && mtokenStakeGtZero?.length > 0 ? (
        <div className="flex flex-col border border-green/60 bg-white/80 rounded-2xl p-4 w-[550px] h-max max-md:mb-4 max-md:w-full">
          <span className="text-black-title font-medium text-base mb-6">
            Staking Status
          </span>

          {(mtokenStakeGtZero || []).map(item => {
            return (
              <MTokenUnstakeItem
                key={item.mToken}
                record={item}
                handleRefresh={handleRefresh}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default StakeMToken;
