import { useEffect } from 'react';
import { notification } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

function useNotificationFromUrl(paramName: any) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get(paramName);

    if (message) {
      notification.info({
        message: message
      });

      queryParams.delete(paramName);
      navigate(
        {
          pathname: location.pathname,
          search: queryParams?.toString()
        },
        { replace: true }
      );
    }
  }, [location, navigate, paramName]);
}

export default useNotificationFromUrl;
