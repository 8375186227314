export function truncateAddress(str: string | undefined, keepLength = 5) {
  if (!str) {
    return '';
  }
  if (str.length <= 10) {
    return str;
  }
  return (
    str.substring(0, keepLength) +
    '...' +
    str.substring(str.length - keepLength)
  );
}
