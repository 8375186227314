const WEB_SITE_URL = window.location.origin;

export default function UserAgreement() {
  const navTerms = () => {
    window.open(WEB_SITE_URL + '/terms');
  };
  return (
    <div
      onClick={navTerms}
      className="cursor-pointer pl-12 flex flex-col w-[260px] rounded-tr-2xl rounded-br-2xl bg-white/80 pr-6 py-6 border border-[#05D9A6]/60 text-black-title text-[20px] font-[400] "
    >
      User Agreement
    </div>
  );
}
