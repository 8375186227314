import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';
import useSWR from 'swr';
import useUserInfo from './useUserInfo';

export type IClaimableScoresRes = {
  code: number;
  msg: string;
  data: {
    scores: string;
    rewardTokenAmount: string;
  };
};

export function useClaimableToken() {
  const { userInfo } = useUserInfo();
  const claimableScores = useSWR<IClaimableScoresRes>(
    userInfo?.user_address
      ? [ENDPOINTS.getClaimableScores, userInfo?.user_address]
      : null,
    ([url, address]: [string, string]): Promise<IClaimableScoresRes> => {
      return fetcher(
        url,
        {
          method: 'GET'
        },
        false
      );
    },
    {
      revalidateOnFocus: false
    }
  );

  return { claimableScores };
}
