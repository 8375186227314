import infoIcon from '@/assets/svgs/info-gray.svg';
import LoadingIcon from '@/components/Loading';
import config from '@/config';
import { TX_SUCCESS } from '@/constants/walletConstants';
import { useEthersSigner } from '@/hooks/useEthersSigner';
import { unstakeContract } from '@/pages/Stake/UnstakeContent';
import { Popover, notification } from 'antd';
import { formatUnits } from 'ethers/lib/utils';
import { useState } from 'react';
import { useAccount, useSwitchChain } from 'wagmi';
import { FinalHistoryItem, MANTA_DES_END_ID } from '../UnstakeHistory';

const { MANTA_PACIFIC_CHAIN } = config;

const ClaimButton = (record: FinalHistoryItem) => {
  const [loading, toggleLoading] = useState(false);

  const { chain } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const { address } = useAccount();
  const signer = useEthersSigner();

  const formatedGasFee = Number(
    formatUnits(record.gasFee.toBigInt(), 18)
  ).toFixed(9);
  const formatedLayerZeroFee = Number(
    formatUnits(record.layerzeroFee.toBigInt(), 18)
  ).toFixed(9);
  const formatedTotalFee = Number(
    formatUnits(record.layerzeroFee.add(record.gasFee).toBigInt(), 18)
  ).toFixed(9);

  const handleSwitchNetwork = async () => {
    try {
      toggleLoading(true);
      if (chain?.id !== MANTA_PACIFIC_CHAIN.id) {
        await switchChainAsync?.({
          chainId: MANTA_PACIFIC_CHAIN.id
        });
      }
    } catch (e) {
      console.log('switch network error: ', e);
    } finally {
      toggleLoading(false);
    }
  };

  const handleClaim = async () => {
    const claimParams = {
      mToken: record.mToken,
      desEndId: record.desEndId,
      refundToken: record.refundToken,
      indexInQueue: record.indexInQueue,
      checkpointIndex: record.checkpointIndex,
      msgValueRefundReceiver: address,
      desTokenRefundReceiver: address
    };
    try {
      toggleLoading(true);
      if (chain?.id !== MANTA_PACIFIC_CHAIN.id) {
        await switchChainAsync?.({
          chainId: MANTA_PACIFIC_CHAIN.id
        });
      }
      if (!signer) {
        console.log('Not connect wallet');
        return;
      }
      const tx = await (
        await unstakeContract
          ?.connect(signer)
          ?.claim(claimParams, { value: record.layerzeroFee })
      )?.wait(2); // wait for 2 blocks
      if (tx.status === TX_SUCCESS) {
        record.updateHistoryList?.();
        notification.success({
          message: 'Claim success'
        });
      }
    } catch (e: any) {
      console.log('claim action error:', e);
      let message =
        e?.data?.message ??
        e?.error?.data?.message ??
        e?.error?.message ??
        e?.message ??
        '';
      if (message.includes('insufficient funds for gas * price')) {
        message = `Error: Not enough gas to cover the transaction.`;
      } else if (message?.toLocaleLowerCase().includes('user rejected')) {
        return;
      }
      notification.error({
        message: message ?? 'Withdraw action error'
      });
    } finally {
      toggleLoading(false);
    }
  };
  return (
    <div className="flex items-center text-green text-sm font-medium cursor-pointer">
      <span className="w-2 h-2 rounded-full bg-green mr-2 flex-shrink-0"></span>
      {loading ? (
        <div className="flex-1">
          <LoadingIcon isDark />
        </div>
      ) : chain && chain.id === MANTA_PACIFIC_CHAIN.id ? (
        <span onClick={handleClaim} className="underline flex-1">
          Ready to claim
        </span>
      ) : (
        <span onClick={handleSwitchNetwork} className="underline flex-1">
          Switch network to {MANTA_PACIFIC_CHAIN.name}
        </span>
      )}

      {chain && chain.id === MANTA_PACIFIC_CHAIN.id && (
        <Popover
          overlayInnerStyle={{
            padding: '0',
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
          arrow={false}
          placement="right"
          trigger="hover"
          content={() => (
            <div className="bg-[#1D314C99] text-white p-2 rounded-[4px]">
              <div className="flex items-cener justify-between w-full mb-4">
                Claim Fee
                <span className="ml-2">≈ {formatedTotalFee} ETH</span>
              </div>
              <div className="flex items-cener justify-between w-full">
                Gas Fee
                <span className="ml-2">≈ {formatedGasFee} ETH</span>
              </div>
              {record.desEndId !== MANTA_DES_END_ID && (
                <div className="flex items-cener justify-between w-full">
                  LayerZero Network Fee
                  <span className="ml-2">
                    ≈ {formatedLayerZeroFee} <span>ETH</span>
                  </span>
                </div>
              )}
            </div>
          )}
        >
          <img src={infoIcon} alt="infoIcon" width={17} height={17} />
        </Popover>
      )}
    </div>
  );
};

export default ClaimButton;
