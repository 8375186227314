import { useEffect, useRef } from 'react';
import { MTokenInfo, MyPoint } from '@/components/SubNav';
import useHistoryRecord from '@/store/useHistoryRecord';
import useHistoryRecordStatus from '@/store/useHistoryRecordStatus';
import useUserInfo from '@/store/useUserInfo';
import HistoryTable from './History';

export interface HistoryRecordDataType {
  id: number;
  amount: string;
  converted_amount: number;
  block_number: number;
  chain_id: number;
  created_at: string;
  eth_spot_price: string;
  is_stake: number;
  manta_spot_price: string;
  sender: string;
  symbol: string;
  token_contract_address: string;
  token_name: string;
  token_spot_price: string;
  transaction_hash: string;
}

export interface HistoryRecordStatusDataType {
  account: string;
  chain_id: number;
  chain_name: string;
  converted_amount: number;
  created_at: string;
  is_m_token_received: number;
  symbol: string;
  transaction_hash: string;
}

function History() {
  const firstRender = useRef(false);
  const { userInfo } = useUserInfo();
  const { history: historyRecord, mutate: mutateHisotryRecord } =
    useHistoryRecord();
  const { history: historyRecordStatus, mutate: mutateHisotryRecordStatus } =
    useHistoryRecordStatus();
  const composedRecord = historyRecord.map((r: HistoryRecordDataType) => {
    const cur = historyRecordStatus?.filter(
      (s: HistoryRecordStatusDataType) =>
        s.transaction_hash == r.transaction_hash
    )[0];
    return { ...r, is_m_token_received: cur?.is_m_token_received };
  });

  useEffect(() => {
    if (userInfo?.user_address) firstRender.current = true;
    if (firstRender.current) {
      mutateHisotryRecord();
      mutateHisotryRecordStatus();
    } // skip first render
  }, [userInfo?.user_address]);

  const Title = (
    <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
      <div className="text-base font-medium text-black-title ">
        Deposit History
      </div>
      <div className="mt-2 md:mt-auto text-sm italic text-black-title opacity-70">
        *Assets deposited from the ETH/BSC arrives in about 30 minutes. The rest
        normally arrives within minutes.
      </div>
    </div>
  );

  return (
    <>
      {/** PC */}
      <div className="history-page flex gap-4 max-md:hidden">
        <div className="flex flex-col gap-4 items-start relative"></div>
        <div className="flex w-full flex-col gap-4 items-start">
          <div className="flex flex-col bg-white-80 justify-between items-center p-6 w-full border border-primary-green-border rounded-2xl">
            {Title}
            <div className="mt-6 flex flex-col">
              <HistoryTable data={composedRecord} />
            </div>
          </div>
        </div>
      </div>
      {/* H5 */}
      <div className="history-mobile-page flex flex-col items-center gap-4 md:hidden">
        <div className="w-full flex flex-col items-center">
          <MyPoint />
          <MTokenInfo />
        </div>
        <div className="flex flex-col bg-white-80 justify-between items-center p-6 w-full border border-primary-green-border rounded-2xl">
          {Title}
          <HistoryTable data={composedRecord} />
        </div>
      </div>
    </>
  );
}

export default History;
