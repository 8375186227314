import { useEffect, useMemo, useState } from 'react';
import Decimal from 'decimal.js';
import { useAccount, useBalance } from 'wagmi';
import { BigNumber } from 'ethers';
import { Popover } from 'antd';
import { formatUnits } from 'ethers/lib/utils';
import metamask from '@/assets/imgs/metamask.png';
import config from '@/config';
import { useAddToken } from '@/hooks/useAddToken';
import infoIcon from '@/assets/svgs/info-gray.svg';
import { StakeToken } from '..';

const { MANTA_PACIFIC_CHAIN } = config;

export const StakeTo = ({
  selectedToken,
  amount,
  isMantaPacificChain,
  layerZeroGasFee,
  estimatedGasFee
}: {
  amount: string;
  selectedToken: StakeToken | null;
  isMantaPacificChain: boolean;
  layerZeroGasFee: BigNumber;
  estimatedGasFee: BigNumber;
}) => {
  const [ratio, setRatio] = useState(0);

  const { addTokenToWalletWithCertainChain } = useAddToken();
  const { address } = useAccount();
  const { data: nativeToken } = useBalance({
    address
  });

  useEffect(() => {
    const getRatio = async () => {
      const ratio =
        await selectedToken?.mToken?.tokenContract?.rewardMultiplier();
      if (ratio) {
        const formatRatio = formatUnits(ratio, 18); // Using static 18
        setRatio(1 / Number(formatRatio));
      }
    };
    getRatio();
  }, [selectedToken]);

  const finalRatio = useMemo(() => {
    const conversionRate = formatUnits(selectedToken?.conversionRate ?? 0, 18);
    if (selectedToken?.name === 'STONE') {
      return (Number(conversionRate) * ratio).toFixed(5);
    } else if (selectedToken?.name === 'wUSDM') {
      return (Number(conversionRate) * ratio).toFixed(5);
    }
    return ratio.toFixed(5);
  }, [ratio, selectedToken]);

  const addTokenToWallet = async () => {
    if (selectedToken?.mToken) {
      addTokenToWalletWithCertainChain(MANTA_PACIFIC_CHAIN?.id, {
        address: selectedToken?.mToken?.address,
        symbol: selectedToken?.mToken?.name,
        decimals: selectedToken?.mToken?.decimals
      });
    }
  };

  const renderArrivalTime = () => {
    if (isMantaPacificChain) {
      return '1 minute';
    }
    return '30 minutes';
  };

  const totalGasFee = useMemo(() => {
    return Number(
      formatUnits(
        estimatedGasFee.add(layerZeroGasFee).toBigInt(),
        nativeToken?.decimals ?? 18
      )
    ).toFixed(9);
  }, [estimatedGasFee, layerZeroGasFee, nativeToken?.decimals]);

  const formatedGasFee = useMemo(() => {
    return Number(
      formatUnits(estimatedGasFee.toBigInt(), nativeToken?.decimals ?? 18)
    ).toFixed(9);
  }, [nativeToken, estimatedGasFee]);

  const formatedLayerZeroFee = useMemo(() => {
    return Number(
      formatUnits(layerZeroGasFee.toBigInt(), nativeToken?.decimals ?? 18)
    ).toFixed(9);
  }, [nativeToken, layerZeroGasFee]);

  return (
    <>
      <div className="mb-2">
        <span className="font-medium mr-2">To:</span>
        {MANTA_PACIFIC_CHAIN.name}
      </div>
      <div className="flex flex-wrap justify-between mb-2 border border-green/40 p-4 rounded-lg text-primary-black/80 text-sm">
        <span>You will receive:</span>
        <div className="flex flex-col gap-1 text-right text-primary-black">
          <span className="break-all">
            {new Decimal(amount || 0).mul(finalRatio).toNumber().toFixed(5)}{' '}
            {selectedToken?.mToken?.name ?? '--'}
          </span>
          <span>+ CeFi yield</span>
          <span>+ Points</span>
        </div>
      </div>
      <div className="mb-4 font-normal text-primary-black/80 text-sm">
        <span className="font-medium">
          Estimated time of arrival for {selectedToken?.mToken?.name}:{' '}
        </span>
        {renderArrivalTime()}
      </div>
      <div className="flex items-center justify-between border-t-[1px] border-green/40 pt-4 text-sm text-primary-black/80">
        <span>Exchange rate</span>
        {selectedToken && (
          <div className="flex items-center">
            <span className="text-primary-black">
              1 {selectedToken?.label} ≈ {finalRatio}{' '}
              {selectedToken?.mToken?.name}
            </span>
            <img
              src={metamask}
              alt="metamask"
              className="ml-2 w-5 h-5 hover:opacity-80 hover:cursor-pointer"
              onClick={addTokenToWallet}
            />
          </div>
        )}
      </div>
      <div className="mb-4 flex items-center justify-between pt-1 text-sm text-primary-black/80">
        <span>Transaction Fee</span>
        <Popover
          overlayInnerStyle={{
            padding: '0',
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
          arrow={false}
          placement="right"
          trigger="hover"
          content={() => (
            <div className="bg-[#1D314C99] text-white p-2 rounded-[4px]">
              <div className="flex items-cener justify-between w-full">
                Gas Fee:{' '}
                <span className="ml-2">
                  ≈ {formatedGasFee} {nativeToken?.symbol}
                </span>
              </div>
              {!isMantaPacificChain && (
                <div className="flex items-cener justify-between w-full">
                  LayerZero Network Fee:{' '}
                  <span className="ml-2">
                    ≈ {formatedLayerZeroFee} <span>{nativeToken?.symbol}</span>
                  </span>
                </div>
              )}
            </div>
          )}
        >
          <div className="flex items-center">
            ≈{totalGasFee} {nativeToken?.symbol}
            <img src={infoIcon} alt="infoIcon" width={17} height={17} />
          </div>
        </Popover>
      </div>
    </>
  );
};
