import { useAccount } from 'wagmi';
import { Suspense, useEffect } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import Home from './pages/Home';
import Invite from './pages/Invite';
import About from './pages/About';
import Terms from './pages/Terms';
import Dashboard from './pages/Dashboard';
import Stake from './pages/Stake';
import Points from './pages/Points';
import StakingHistory from './pages/StakingHistory';

import Header from './components/Header';
import WatchAccount from './components/WatchAccount';

import Banner from './components/Banner';
import IPBlockingModal from './components/IPBlockingModal';
import { useResponsive } from './hooks/useResonposive';
import MobileNav from './components/MobileNav/Navbar';
import WatchTerms from './components/WatchTerms';
import EarnMore from './pages/EarnMore';
import StakeMToken from './pages/StakeMToken';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingIcon from './components/Loading';

function AppRouter() {
  const { address } = useAccount();
  const { isMobile } = useResponsive();
  useEffect(() => {
    if (window?.MetaCRMWidget?.manualConnectWallet) {
      window.MetaCRMWidget.manualConnectWallet(address);
    }

    const handleConnectWidget = () => {
      window.MetaCRMWidget.manualConnectWallet(address);
    };
    document.addEventListener('MetaCRMLoaded', handleConnectWidget);

    return () => {
      document.removeEventListener('MetaCRMLoaded', handleConnectWidget);
    };
  }, [address]);

  return (
    <div className="min-h-screen">
      <Suspense
        fallback={
          <div className="flex items-center h-[calc(100vh-108px)] justify-center">
            <LoadingIcon isDark />
          </div>
        }
      >
        <ErrorBoundary name="default">
          <Router>
            <WatchAccount />
            <WatchTerms />
            <IPBlockingModal />
            {isMobile ? <MobileNav /> : <Header />}
            <Banner />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/dashboard" element={<Dashboard />}>
                <Route index element={<Stake />} />
                <Route path="m_token" element={<StakeMToken />} />
                <Route path="stake" element={<Stake />} />
                <Route path="history" element={<StakingHistory />} />
                <Route path="points" element={<Points />} />
                <Route path="earn_more" element={<EarnMore />} />
                <Route path="invites" element={<Invite />} />
                <Route path="*" element={<Stake />} />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
        </ErrorBoundary>
      </Suspense>
    </div>
  );
}

export default AppRouter;
