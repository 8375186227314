import arrowRight from '@/assets/fuel/arrowRight.svg';
import close from '@/assets/fuel/close.svg';

interface CytonicBannerProps {
  onClose?: () => void;
  depositLink?: string;
}

export const CytonicBanner = ({
  onClose,
  depositLink = 'https://app.cytonic.com/deposit?referral=MantaCedefi'
}: CytonicBannerProps) => {
  return (
    <div className="w-full mt-4 bg-green-2 rounded-lg border border-primary-green-border">
      <div className="px-4 py-2 flex items-start md:items-center gap-2">
        <div className="grow flex flex-col md:flex-row md:justify-start justify-center items-start md:gap-4 gap-1">
          <div className="text-black-title text-sm font-normal font-tomorrow">
            Deposit your mETH, mBTC, or mUSD on Cytonic now to earn Cytonic
            airdrop
          </div>
          <div className="flex items-center gap-1">
            <a
              href={depositLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-black-title text-sm font-medium font-tomorrow flex items-center gap-1"
            >
              Deposit Now
              <img src={arrowRight} alt="arrowRight" className="w-3.5 h-3.5" />
            </a>
          </div>
        </div>
        <button
          onClick={onClose}
          className="w-4 h-4 md:p-[2.67px] flex justify-center items-center"
        >
          <img src={close} alt="close" />
        </button>
      </div>
    </div>
  );
};
