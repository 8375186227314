import arrowRight from '@/assets/fuel/arrowRight.svg';
import FuelModals, { FuelModalKeys } from '@/components/Fuels/FuelModals';
import { COMMON_STYLES } from '@/constants/fuelStyles';
import { useFuelWallet } from '@/hooks/useFuelWallet';
import useUserInfo from '@/store/useUserInfo';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useEffect, useRef, useState } from 'react';
import { useAccount } from 'wagmi';

const BindFuelAddress = ({ gap = 1 }: { gap?: number }) => {
  const { open } = useWeb3Modal();
  const { userInfo } = useUserInfo();
  const [initialView, setInitialView] = useState<FuelModalKeys | ''>('');
  const [timestamp, setTimestamp] = useState<number>(0);
  const { address: evmAddress, isConnected: isEVMConnected } = useAccount();
  const { isConnected: fuelConnected } = useFuelWallet();
  const userAddress = userInfo?.user_address;
  const evmConnected = isEVMConnected && evmAddress;
  const isAddressSame =
    evmAddress?.toLowerCase() === userAddress?.toLowerCase();
  const [isBindAddressClicked, setIsBindAddressClicked] = useState(false);
  const prevFuelConnectedRef = useRef(fuelConnected);

  const handleOpenBindFuelModal = () => {
    setInitialView(FuelModalKeys.BIND_FUEL);
    setTimestamp(Date.now());
  };

  const handleBindAddress = async () => {
    setIsBindAddressClicked(true);
    if (!userAddress) {
      await open();
      return;
    }
    handleOpenBindFuelModal();
  };

  useEffect(() => {
    if (
      evmConnected &&
      userAddress &&
      isAddressSame &&
      isBindAddressClicked &&
      !prevFuelConnectedRef.current
    ) {
      handleOpenBindFuelModal();
    }

    prevFuelConnectedRef.current = fuelConnected;
  }, [
    evmConnected,
    fuelConnected,
    userAddress,
    isAddressSame,
    isBindAddressClicked
  ]);

  return (
    <div className={`flex gap-${gap}`}>
      <FuelModals initialView={initialView} timestamp={timestamp} />
      <div
        className={`${COMMON_STYLES.textTitle} ${COMMON_STYLES.clickable}`}
        onClick={handleBindAddress}
      >
        Bind Fuel Address
      </div>
      <img src={arrowRight} alt="arrowRight" />
    </div>
  );
};
export default BindFuelAddress;
