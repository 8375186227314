import classNames from 'classnames';
import { COMMON_STYLES } from '@/constants/fuelStyles';

interface FuelModalButtonProps {
  onButtonClick: () => void;
  text: string | React.ReactNode;
  className?: string;
}

const FuelModalButton: React.FC<FuelModalButtonProps> = ({
  onButtonClick,
  text,
  className
}) => {
  return (
    <div className={COMMON_STYLES.flexColumn}>
      <div
        onClick={onButtonClick}
        className={classNames(
          'w-full px-6 py-4 bg-fuel-dark rounded-[10px] justify-center items-center gap-2.5 inline-flex',
          !className ? 'cursor-pointer' : '',
          className
        )}
      >
        <div className="text-center text-white text-base font-normal tracking-wide">
          {text}
        </div>
      </div>
    </div>
  );
};

export default FuelModalButton;
