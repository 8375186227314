import Decimal from 'decimal.js';
import { useAccount, useSwitchChain } from 'wagmi';
import { useState } from 'react';
import { BigNumber, Contract } from 'ethers';
import { notification } from 'antd';
import { parseUnits } from 'viem';
import LoadingIcon from '@/components/Loading';
import { useEthersSigner } from '@/hooks/useEthersSigner';
import useModal from '@/hooks/useModal';
import config from '@/config';
import UnstakeSuccessContent, {
  UnstakeSuccessContentProps
} from '@/components/ModalContent/UnstakeSuccessContent';
import { TX_SUCCESS } from '@/constants/walletConstants';

import {
  UnstakeChain,
  UnstakeToken,
  layerzeroDesIdMap,
  unstakeContract
} from '../..';
import useUnstakeHistory from '../../hooks/useUnstakeHistory';

const { MANTA_PACIFIC_CHAIN, stakeContractMap } = config;

export type UnstakeButtonProps = {
  amount: string;
  fromToken: UnstakeToken;
  toToken: null | UnstakeToken;
  tokenBalance: string;
  toChain: UnstakeChain | null;
  fromTokenContract: Contract;
};
const commonCls =
  'flex w-full justify-center btn-primary h-[51px] max-md:h-max';

const mantaPacificRouter =
  stakeContractMap[MANTA_PACIFIC_CHAIN.id]?.routerContract;

const UnstakeButton = ({
  amount,
  toToken,
  fromToken,
  tokenBalance,
  toChain,
  fromTokenContract
}: UnstakeButtonProps) => {
  const [unstakeLoading, setUnstakeLoading] = useState(false);

  const { chain } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const signer = useEthersSigner();
  const { address } = useAccount();

  const { updateHistoryList } = useUnstakeHistory();

  const [UnstakeSuccessModal, { onOpen, onCancel }] =
    useModal<UnstakeSuccessContentProps>(UnstakeSuccessContent, {
      title: 'Withdraw Successful!',
      width: 434
    });

  if (
    // can not using '' as argument for new Decimal Constructor
    new Decimal(amount === '' ? '0' : amount).gt(
      new Decimal(tokenBalance == '' ? '0' : tokenBalance)
    )
  ) {
    return (
      <button className={commonCls} disabled>
        Insufficient Balance
      </button>
    );
  }

  if (chain?.id !== MANTA_PACIFIC_CHAIN?.id) {
    return (
      <button
        className={commonCls}
        onClick={async () => {
          try {
            await switchChainAsync?.({
              chainId: MANTA_PACIFIC_CHAIN.id
            });
          } catch (e) {
            console.log('switch network error', e);
          }
        }}
      >
        Switch to {MANTA_PACIFIC_CHAIN?.name}
      </button>
    );
  }
  const handleUnstake = async () => {
    setUnstakeLoading(true);
    try {
      if (!signer || !toChain) {
        return;
      }
      const amountBigNumber = parseUnits(amount, fromToken?.decimals);
      const desId = layerzeroDesIdMap[toChain?.id];

      const allowance: BigNumber = await fromTokenContract
        ?.connect(signer)
        .allowance(address, mantaPacificRouter);
      if (allowance.lt(amountBigNumber)) {
        await (
          await fromTokenContract
            ?.connect(signer)
            ?.approve(mantaPacificRouter, amountBigNumber)
        )?.wait();
      }

      const tx = await (
        await unstakeContract
          .connect(signer)
          .requestWithdraw(
            fromToken?.address,
            amountBigNumber,
            desId,
            toToken?.address
          )
      )?.wait();
      if (tx.status === TX_SUCCESS && toToken) {
        updateHistoryList();
        onOpen({
          amount,
          fromToken,
          txHash: tx.transactionHash,
          onCancel,
          toToken
        });
      }
    } catch (e: any) {
      console.log('Withdraw action error:', e);
      let message = e?.data?.message ?? e?.error?.message ?? e?.message;
      if (message.includes('insufficient funds for gas * price')) {
        message = `Error: Not enough gas to cover the transaction.`;
      } else if (message.includes('user rejected transaction')) {
        return;
      }
      notification.error({
        message: message ?? 'Withdraw action error'
      });
    } finally {
      setUnstakeLoading(false);
    }
  };

  const btnDisabled = !amount || +amount <= 0 || unstakeLoading;

  return (
    <>
      {UnstakeSuccessModal}
      <button
        className={commonCls}
        disabled={btnDisabled}
        onClick={handleUnstake}
      >
        {unstakeLoading ? <LoadingIcon className="ml-2" /> : 'Withdraw'}
      </button>
    </>
  );
};

export default UnstakeButton;
