import { watchAccount, signMessage } from '@wagmi/core';
import { useEffect, useRef } from 'react';
import {
  useAccount,
  useAccountEffect,
  useConfig,
  useDisconnect,
  useWalletClient
} from 'wagmi';
import { mutate } from 'swr';
import { notification } from 'antd';
import ENDPOINTS from '@/constants/apiEndpoints';
import useUserInfo from '@/store/useUserInfo';
import useShowSignWalletModal from '@/store/useShowSignWalletModal';
import useInviteCode from '@/store/useInviteCode';
import { fetcher } from '@/utils/request';

import SignWallet from '../SignWallet/SignWallet';
import { useModal } from './useModal';

window.isFromConnectWalletToContinueBtn = true;
function WatchAccount() {
  const {
    ModalWrapper: BlockWalletModal,
    showModal: showBlockModal,
    hideModal: hideBlockModal
  } = useModal({
    closeDisabled: true
  });
  const { ModalWrapper, showModal, hideModal } = useModal({
    closeDisabled: true
  });
  const { setShowModal } = useShowSignWalletModal();
  const { data, userInfo } = useUserInfo();
  const { user_address } = userInfo;
  const { inviteCode, setInviteCode } = useInviteCode();
  const { disconnect } = useDisconnect();
  const { data: walletClient, isLoading: isLoadingWalletClient } =
    useWalletClient();
  const { address, isConnected, connector } = useAccount();
  const wagmiConfig = useConfig();
  useAccountEffect({
    onConnect: async ({ address, isReconnected }) => {
      const walletBlockRes = await fetcher(
        ENDPOINTS.walletBlock + `?address=${address}`,
        {
          method: 'GET'
        },
        false
      );
      if (walletBlockRes?.data?.is_sanctioned_address === 1) {
        showBlockModal();
        disconnect();
        hideModal();
        return;
      }
      console.log('isReconnected', isReconnected);
      // when user changed address from wallet, but did not sign, and then refresh the page
      // here isReconnected === true, caused a bug: cookie's address !== wallet address
      // how to fix: compare two addresses when page mounted
      if (!isReconnected) {
        try {
          const res = await fetch(ENDPOINTS.getSignData, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ address })
          });
          const data = await res.json();
          if (data.success) {
            const message = data.message;
            const signature = await signMessage(wagmiConfig, { message });
            const res = await fetch(ENDPOINTS.login, {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                address,
                signature,
                message,
                inviteCode
              })
            });
            const loginRes = await res.json();
            if (loginRes.success) {
              mutate(ENDPOINTS.userInfo);
              setInviteCode('');
            } else {
              notification.error({
                message: loginRes.message
              });
            }
          } else {
            if (data?.code === -13) {
              showBlockModal();
              disconnect();
            } else {
              notification.error({
                message: data.message
              });
            }
          }
        } catch (e: any) {
          console.log(e.message);
        }
      }
    },
    onDisconnect: async () => {
      window.isFromConnectWalletToContinueBtn = true;
      await fetch(ENDPOINTS.logout, { method: 'POST', credentials: 'include' });
      mutate(ENDPOINTS.userInfo);
    }
  });

  // wagmi bug https://github.com/wevm/wagmi/issues/2784
  useEffect(() => {
    const timer = setTimeout(() => {
      // console.log(isConnected, connector, isLoadingWalletClient, walletClient);
      if (
        isConnected &&
        !connector?.name &&
        !isLoadingWalletClient &&
        !walletClient
      ) {
        disconnect();
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [isConnected, connector, isLoadingWalletClient, walletClient, disconnect]);

  const isAddressChecked = useRef(false);
  useEffect(() => {
    if (user_address && address && !isAddressChecked.current) {
      if (user_address.toLowerCase() !== address.toLowerCase()) {
        showModal();
      }
      isAddressChecked.current = true;
    }
  }, [user_address, address, showModal]);

  useEffect(() => {
    const unwatch = watchAccount(wagmiConfig, {
      // this function will trigger when chain changed
      onChange(newAccount, prevAccount) {
        const newAddress = newAccount.address;
        const prevAddress = prevAccount.address;
        if (newAddress) {
          if (window.isFromConnectWalletToContinueBtn) {
            window.isFromConnectWalletToContinueBtn = false;
          } else if (
            prevAddress &&
            newAddress.toLocaleLowerCase() !== prevAddress?.toLocaleLowerCase()
          ) {
            showModal();
          }
        }
      }
    });
    return () => unwatch();
  }, [showModal]);

  useEffect(() => {
    // console.log(data, address);
    if (data?.statusCode === 401 && address) {
      disconnect();
      console.log('disconnected');
    }
  }, [data]);

  useEffect(() => {
    setShowModal(showModal);
  }, [setShowModal, showModal]);

  return (
    <>
      <BlockWalletModal>
        <div className="max-md:w-[350px] md:w-[540px] bg-fourth -mx-6 -my-6 rounded-[16px] p-6">
          <div className="text-[16px] font-[500] text-[#010811] text-center">
            Wallet prohibited
          </div>
          <div className="text-[14px] text-black-title mt-4">
            Sorry, this wallet address is prohibited from accessing our
            services.
          </div>
          <div className="w-full flex justify-center mt-4">
            <button className="btn-primary w-full" onClick={hideBlockModal}>
              Back
            </button>
          </div>
        </div>
      </BlockWalletModal>
      <ModalWrapper>
        <div>
          <div className="text-base font-bold leading-[19px]">New Wallet</div>
          <div className="my-6">You are logging in with a new wallet</div>
          <SignWallet
            className="w-full"
            hideModal={hideModal}
            showBlockModal={showBlockModal}
            isFromWatchAccount
          />
        </div>
      </ModalWrapper>
    </>
  );
}

export default WatchAccount;
