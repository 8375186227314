import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useInViewport } from 'ahooks';
import { isMobile } from 'react-device-detect';
import FaqList from '@/components/FaqList';
import arrowDownGif from '@/assets/arrow-down.gif';

export const HomeFaq = () => {
  const faqRef = useRef<null | HTMLDivElement>(null);

  const [inViewport] = useInViewport(faqRef, {
    threshold: 0.15
  });
  const scrollIntoView = (element: Element) => {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const scrollDown = () => {
    if (faqRef.current) {
      scrollIntoView(faqRef.current);
    }
  };
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));

      if (element) {
        scrollIntoView(element);
      }
    }
  }, [hash]);

  return (
    <>
      <div
        className="w-[742px] max-md:w-auto max-md:mx-6 mx-auto pb-[120px] max-md:pb-6 text-left"
        ref={faqRef}
        id="faq"
      >
        <h3 className="text-[32px] text-center my-6">FAQ</h3>
        <FaqList />
      </div>
      {!inViewport && !isMobile && (
        <img
          src={arrowDownGif}
          alt="arrow-down"
          className="inline-block fixed bottom-[120px] text-center w-[34px] h-[34px] rounded-full z-10 cursor-pointer"
          onClick={scrollDown}
        />
      )}
    </>
  );
};
