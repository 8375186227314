import { Table } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import numbro from 'numbro';
import { DECIMAL_PLACES } from '@/store/useBatchMtoken';
import { HistoryRecordDataType, HistoryRecordStatusDataType } from '.';
import type { TableColumnsType } from 'antd';

type DataType = HistoryRecordDataType | HistoryRecordStatusDataType;

type statusType = 0 | 1;

const statusMap: Record<statusType, { text: string; color: string }> = {
  0: { text: 'Pending', color: '#FE8311' },
  1: { text: 'Completed', color: '#00D27A' }
};

const StatusComp = ({ status }: { status: statusType }) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className={classNames(
          'w-2 h-2 rounded-lg mr-2',
          `bg-[${statusMap[status]?.color}]`,
          `text-[${statusMap[status]?.color}]`
        )}
      ></div>
      <div>{statusMap[status]?.text}</div>
    </div>
  );
};

const columns: TableColumnsType<DataType> = [
  {
    align: 'center',
    title: (
      <div className="text-sm font-normal text-[#1D314C99]">Staked Amount</div>
    ),
    dataIndex: 'staked_amount',
    width: 100
  },
  {
    align: 'center',
    title: <div className="text-sm font-normal text-[#1D314C99]">Chain</div>,
    dataIndex: 'chain_name',
    width: 100
  },
  {
    align: 'center',
    title: <div className="text-sm font-normal text-[#1D314C99]">Status</div>,
    dataIndex: 'is_m_token_received',
    width: 100,
    render(value) {
      return <StatusComp status={value} />;
    }
  },
  {
    align: 'center',
    title: (
      <div className="text-sm font-normal text-[#1D314C99]">Staked Time</div>
    ),
    dataIndex: 'staked_at',
    width: 150
  },
  {
    align: 'center',
    title: <div className="text-sm font-normal text-[#1D314C99]">Txn</div>,
    dataIndex: 'explorer_url',
    width: 100,
    render: (text, record) => (
      <a
        className="text-green"
        href={text}
        target="_blank"
        rel="noreferrer noopener"
      >
        {`${record.transaction_hash.slice(
          0,
          6
        )}...${record.transaction_hash.slice(-6)}`}
      </a>
    )
  }
];

const EXPLORER_URL_MAP: Record<number, string> = {
  1: 'https://etherscan.io',
  11155111: 'https://sepolia.etherscan.io',
  56: 'https://bscscan.com',
  97: 'https://testnet.bscscan.com',
  169: 'https://pacific-explorer.manta.network',
  3441006: 'https://pacific-explorer.sepolia-testnet.manta.network'
};

const HistoryTable = ({ data }: { data: DataType[] }) => {
  const finalData = data.reverse().map(d => {
    return {
      ...d,
      staked_at: dayjs(d?.created_at).format('MMM D YYYY, HH:mm:ss'),
      staked_amount: `${numbro(d?.converted_amount ?? 0).format({
        thousandSeparated: true,
        mantissa: DECIMAL_PLACES,
        trimMantissa: true
      })} ${d?.symbol}`,
      explorer_url: `${EXPLORER_URL_MAP[d?.chain_id]}/tx/${d?.transaction_hash}`
    };
  });

  return (
    <Table
      virtual
      columns={columns}
      dataSource={finalData}
      bordered
      size="middle"
      scroll={{ x: 700, y: 450 }}
      locale={{
        emptyText: (
          <div className="w-full text-left max-md:text-sm text-black-title">
            You have not deposit any assets yet
          </div>
        )
      }}
      pagination={false}
      showHeader={!!finalData.length}
      rowKey={r => r.transaction_hash}
    />
  );
};

export default HistoryTable;
