import { CytonicBanner } from '@/components/Cytonic/CytonicBanner';
import ErrorBoundary from '@/components/ErrorBoundary';
import SubNav from '@/components/SubNav';
import { useFuelWalletsList } from '@/hooks/useFuelWalletsList';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import useNotificationFromUrl from '@/hooks/useNotificationFromUrl';
import useUserInfo from '@/store/useUserInfo';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi';
import EarnMore from '../EarnMore';
import Invite from '../Invite';
import Points from '../Points';
import Stake from '../Stake';
import StakeMToken from '../StakeMToken';
import StakingHistory from '../StakingHistory';

function Content() {
  const { pathname } = useLocation();
  if (pathname.includes('m_token')) {
    return (
      <ErrorBoundary name="StakeMToken">
        <StakeMToken />
      </ErrorBoundary>
    );
  }
  if (pathname.includes('stake')) {
    return (
      <ErrorBoundary name="Stake">
        <Stake />
      </ErrorBoundary>
    );
  }
  if (pathname.includes('history')) {
    return (
      <ErrorBoundary name="StakingHistory">
        <StakingHistory />
      </ErrorBoundary>
    );
  }
  if (pathname.includes('points')) {
    return (
      <ErrorBoundary name="Points">
        <Points />
      </ErrorBoundary>
    );
  }
  if (pathname.includes('earn_more')) {
    return (
      <ErrorBoundary name="EarnMore">
        <EarnMore />
      </ErrorBoundary>
    );
  }
  if (pathname.includes('invites')) {
    return (
      <ErrorBoundary name="Invite">
        <Invite />
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary name="Stake">
      <Stake />
    </ErrorBoundary>
  );
}

function Dashboard() {
  useNotificationFromUrl('message');
  const { userInfo } = useUserInfo();
  const { address: rawAddress = '' } = useAccount();
  const address = rawAddress.toLowerCase();
  const userInfoAddress = userInfo?.user_address?.toLowerCase();
  const userAddress = address === userInfoAddress ? address : '';
  const { mutate } = useFuelWalletsList();

  const cytonicBannerClosedDefaultKey = userAddress || 'handleBannerClose';

  const [isBannerClosed, setIsBannerClosed] = useLocalStorage(
    'cytonicBannerClosed',
    cytonicBannerClosedDefaultKey
  );

  const handleBannerClose = () => {
    setIsBannerClosed(cytonicBannerClosedDefaultKey);
  };

  useEffect(() => {
    if (userAddress) {
      mutate();
    }
  }, [userAddress]);

  return (
    <>
      {/** PC */}
      <div>
        <div className="animate-fade-out flex mx-6 md:mx-12">
          {!isBannerClosed && <CytonicBanner onClose={handleBannerClose} />}
        </div>
        <div className="flex items-start pr-12 pt-4 gap-4 dashboard-bg max-md:hidden">
          <ErrorBoundary name="SubNav">
            <SubNav />
          </ErrorBoundary>
          <div className="flex-1">
            <Content />
          </div>
        </div>
      </div>
      {/* H5 */}
      <div className="flex min-h-full flex-col px-6 py-4 gap-2 dashboard-bg  md:hidden">
        <Content />
      </div>
    </>
  );
}

export default Dashboard;
