import { create } from 'zustand';

interface ShowSignWalletModalState {
  showModal: () => void;
  setShowModal: (_: () => void) => void;
}

const useShowSignWalletModal = create<ShowSignWalletModalState>(set => ({
  showModal: () => {},
  setShowModal: (showModal: () => void) => set({ showModal })
}));

export default useShowSignWalletModal;
