import classNames from 'classnames';
import { isBrowser, isMobile } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
import { HomeFaq } from './HomeFaq';
import Ceffu from './svg/ceffu.svg';
import Ethena from './svg/ena.svg';
import Higgs from './svg/higgs.svg';
import Legends from './svg/legends.svg';
import LTP from './svg/ltp.svg';
import Manta1 from './svg/manta-1.png';
import Manta2 from './svg/manta-2.png';
import Pythagoras from './svg/pythagoras.svg';

const Partners = [
  {
    name: 'CEFFU',
    url: 'https://www.ceffu.com/',
    logo: Ceffu
  },
  {
    name: 'Ethena',
    url: 'https://ethena.fi/',
    logo: Ethena
  },
  {
    name: 'LTP',
    // url: 'https://liquiditytech.com/',
    logo: LTP
  },
  {
    name: 'Higgs',
    url: '',
    logo: Higgs
  },
  {
    name: 'Pythagoras',
    url: 'https://pythagoras.investments/',
    logo: Pythagoras
  },
  {
    name: 'Legends',
    url: '',
    logo: Legends
  }
];

function Home() {
  const { search } = useLocation();

  return (
    <div className="text-center home">
      <div
        className={classNames('flex items-center justify-center', {
          'h-[545px] home-bg': isBrowser,
          'h-[475px] home-bg-h5': isMobile
        })}
      >
        <div className="bg-[#FFFFFFCC] backdrop-blur-[5px] border rounded-2xl p-12 max-md:mx-6 max-md:px-6 border-[#05D9A699] relative">
          <div className="text-[32px] max-md:text-[20px]">
            Welcome to Manta CeDeFi
          </div>
          <div className="text-[20px] mt-4 mb-2 max-md:text-[16px]">
            Earn CeFi/DeFi yield & Manta token rewards with your
          </div>
          <div className=" text-[32px] max-md:text-[24px]">
            BTC, ETH, STONE, USDT, USDC wUSDM and wBETH
          </div>
          <div className="text-[20px] mt-2 mb-4 max-md:text-[16px]">
            on Manta Pacific.
          </div>
          <div className="text-[32px] mb-4 max-md:text-[24px]">
            Zero Liquidation Risk!
          </div>
          <Link
            to={`/dashboard/stake${search}`}
            className="btn-primary inline-block"
          >
            JOIN EARLY ACCESS
          </Link>
          <img
            src={Manta1}
            alt="manta"
            className="w-[314px] max-md:w-[169px] absolute -left-[127px] max-md:-left-[16px] top-[343px]"
          />
          <img
            src={Manta2}
            alt="manta"
            className="w-[364px] max-md:w-[200px] absolute left-[564px] max-md:left-[158px] -top-[40px]"
          />
        </div>
      </div>
      <HomeFaq />
      <div className="fixed max-md:relative bottom-0 px-12 py-6 max-md:py-8 flex max-md:flex-col max-md:items-center gap-12 max-md:gap-8 bg-[#DDFBF5] w-full">
        <div className="text-[40px] max-md:text-[32px] font-light max-md:font-normal text-left uppercase flex items-center">
          our partners
        </div>
        <div className="flex-1 grid grid-cols-6 max-md:grid-cols-1 gap-4">
          {Partners.map(partner =>
            partner.url ? (
              <a
                key={partner.name}
                href={partner.url}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center gap-2 p-4 rounded-2xl bg-white max-md:w-[188px] max-md:h-[58px]"
              >
                <img src={partner.logo} alt={partner.name} />
              </a>
            ) : (
              <div
                key={partner.name}
                className="flex items-center justify-center gap-2 p-4 rounded-2xl bg-white max-md:w-[188px] max-md:h-[58px]"
              >
                <img src={partner.logo} alt={partner.name} />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
