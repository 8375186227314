import FuelModals, { FuelModalKeys } from '@/components/Fuels/FuelModals';
import { COMMON_STYLES } from '@/constants/fuelStyles';
import useUserInfo from '@/store/useUserInfo';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useState } from 'react';

const MyBoundAddress = () => {
  const [initialView, setInitialView] = useState<FuelModalKeys | ''>('');
  const [timestamp, setTimestamp] = useState<number>(0);
  const { open } = useWeb3Modal();
  const { userInfo } = useUserInfo();
  const userAddress = userInfo?.user_address;

  const handleMyBoundAddressClick = async () => {
    if (!userAddress) {
      await open();
      return;
    }
    setInitialView(FuelModalKeys.MY_BOUND_ADDRESS);
    setTimestamp(Date.now());
  };
  return (
    <div onClick={e => e.stopPropagation()}>
      <FuelModals initialView={initialView} timestamp={timestamp} />
      <div
        className={`text-nowrap text-right ${COMMON_STYLES.textPrimary} ${COMMON_STYLES.clickable}`}
        onClick={handleMyBoundAddressClick}
      >
        My Bound Addresses
      </div>
    </div>
  );
};
export default MyBoundAddress;
