import { useAccount } from 'wagmi';
import useSWR from 'swr';
import config from '@/config';
import { UnstakeStatus } from '../components/UnstakePending';

export type UnstakeHistoryItem = {
  id: string;
  account: string;
  mToken: string;
  desEndId: string;
  refundToken: string;
  mTokenAmount: string;
  indexInQueue: string;
  checkpointIndex: string | null;
  requestWithdrawTimestamp: string;
  requestWithdrawBlockNumber: string;
  appliedRatio: string | null;
  status: UnstakeStatus;
};

export type HistoryResult = { withdrawRequests: Array<UnstakeHistoryItem> };

const { SUB_GRAPHQL_URL } = config;

const useUnstakeHistory = () => {
  const { address } = useAccount();
  const {
    data: { withdrawRequests: historyList } = { withdrawRequests: [] },
    mutate,
    isLoading,
    isValidating
  } = useSWR<HistoryResult>(
    address ? address : null,
    async (address: string) => {
      const params = {
        query: `{
          withdrawRequests(where:{account:"${address}"}, orderBy: requestWithdrawTimestamp,orderDirection:desc){
            id
            account # account who unstakes
            mToken # mToken address
            desEndId # destination end Id (layerzero)
            refundToken # refund token address
            mTokenAmount # unstake mToken amount
            indexInQueue # withdraw request in withdraw queue
            checkpointIndex # checkpoint index
            requestWithdrawTimestamp 
            requestWithdrawBlockNumber
            appliedRatio
            status # 0: pending, 1: finalized, 2: claimed
          }
        }`
      };
      const response = await fetch(SUB_GRAPHQL_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      });
      const res = await response.json();
      return res.data;
    },
    {
      revalidateOnFocus: false
    }
  );
  return {
    unstakeHistoryList: historyList,
    historyLoading: isLoading,
    updateHistoryList: mutate,
    historyValidating: isValidating
  };
};

export default useUnstakeHistory;
