import useSWR from 'swr';
import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';
import useUserInfo from './useUserInfo';

export function useQueryEligibleExtra() {
  const { userInfo } = useUserInfo();
  const status = useSWR<IPointsResponse<IExtraPointsData>>(
    userInfo?.user_address
      ? [`${ENDPOINTS.queryEligibleExtra}`, userInfo?.user_address]
      : null,
    ([url]: [string, string]): Promise<IPointsResponse<IExtraPointsData>> => {
      return fetcher(
        url,
        {
          method: 'POST'
        },
        false
      );
    },
    {
      revalidateOnFocus: false
    }
  );

  return status;
}
export function useQueryMantaEligibleExtra() {
  const { userInfo } = useUserInfo();
  const status = useSWR<IPointsResponse<IMantaExtraPointsData>>(
    userInfo?.user_address
      ? [`${ENDPOINTS.queryMantaEligibleExtra}`, userInfo?.user_address]
      : null,
    ([url]: [string, string]): Promise<
      IPointsResponse<IMantaExtraPointsData>
    > => {
      return fetcher(
        url,
        {
          method: 'POST'
        },
        false
      );
    },
    {
      revalidateOnFocus: false
    }
  );

  return status;
}
