import './index.css';
import { notification } from 'antd';
import StakeStone from './imgs/StakeStone.png';
import LayerBank from './imgs/LayerBank.png';
import Shoebill from './imgs/Shoebill.png';
import ZeroLend from './imgs/ZeroLend.png';
import Keom from './imgs/Keom.png';
import KiloEx from './imgs/KiloEx.png';
import Realperp from './imgs/Realperp.png';
import Pacificswap from './imgs/Pacificswap.png';
import Aperture from './imgs/Aperture.png';
import Monroe from './imgs/Monroe.png';
import GokuMoney from './imgs/GokuMoney.png';
import zkHoldem from './imgs/zkHoldem.png';
import asset1 from './imgs/asset1.png';
import asset2 from './imgs/asset2.png';
import manta from './imgs/manta.png';
import stone from './imgs/stone.png';
import wUSDM from './imgs/wUSDM.png';
import stoneText from './imgs/stone-text.png';
import table from './imgs/table.png';
import chart from './imgs/chart.png';
import bg2 from './imgs/bg2.png';
import bg3 from './imgs/bg3.png';
import box from './imgs/box.png';
import card from './imgs/card.png';
import okx from './imgs/okx.png';
import alien from './imgs/alien.png';

const Partners = [
  {
    name: 'StakeStone',
    url: 'https://stakestone.io/',
    logo: StakeStone
  },
  {
    name: 'LayerBank',
    url: 'https://layerbank.finance/',
    logo: LayerBank
  },
  { name: 'Shoebill', url: 'https://shoebill.finance/#/', logo: Shoebill },
  {
    name: 'ZeroLend',
    url: 'https://zerolend.xyz/',
    logo: ZeroLend
  },
  {
    name: 'Keom',
    url: 'https://www.keom.io/',
    logo: Keom
  },
  {
    name: 'KiloEx',
    url: 'https://www.kiloex.io',
    logo: KiloEx
  },
  { name: 'Realperp', url: 'https://realperp.com/#/', logo: Realperp },
  {
    name: 'PacificSwap',
    url: 'https://pacificswap.xyz/',
    logo: Pacificswap
  },
  {
    name: 'Aperture',
    url: 'https://app.aperture.finance/',
    logo: Aperture
  },
  {
    name: 'Monroe',
    url: 'https://www.monroeprotocol.com/',
    logo: Monroe
  },
  {
    name: 'Goku.money',
    url: 'https://www.goku.money/',
    logo: GokuMoney
  },

  {
    name: 'zkHoldem',
    url: 'https://zkholdem.xyz/',
    logo: zkHoldem
  }
];

const handleCopy = async (address: string) => {
  try {
    await navigator.clipboard.writeText(address);
    notification.success({ message: 'Copied to clipboard' });
  } catch (err) {
    console.error('copy error:', err);
  }
};

function About() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <main className="flex flex-col w-full">
        <section className="banner relative -z-10">
          <div className="mt-[217px] ml-12">
            <h1 className="text-5xl uppercase">Renew Paradigm is now live!</h1>
            <p className="mt-3">
              Built upon the success of Manta New Paradigm and
              <br />
              amplifying the ecosystem incentives of MANTA, STONE, and wUSDM
            </p>
          </div>

          <div className="banner-text-bg"></div>

          <h1 className="text-white text-[40px] uppercase absolute bottom-0 h-[88px] w-full flex justify-center items-center">
            Renew Paradigm
          </h1>
        </section>

        <section className="flex items-center justify-center py-6">
          <div className="text-[40px] uppercase text-center">
            Monster
            <br />
            yield
          </div>
          <div className="text-[32px] px-6">=</div>
          <div>
            <div className="text-[40px] uppercase text-center">
              Double yield
            </div>
            <div className="gradient-text-3 mt-2 font-medium">
              native eth/usdc yield
              <br />
              EigenLayer yield
            </div>
          </div>
          <div className="text-[32px] px-6">+</div>
          <div>
            <div className="text-[40px] uppercase text-center">
              Quadra yield
            </div>
            <div className="gradient-text-3 mt-2 font-medium">
              StakeStone yield,Dapp native yield
              <br />
              MANTA yield, Dapp native token yield
            </div>
          </div>
        </section>

        <section className="p-12 bg-green-bg flex flex-col items-center">
          <p className="text-center  mb-12">
            Now you can interact with 11 projects within the Manta ecosystem
            <br />
            using MANTA, STONE, and wUSDM.
            <br />
            Stake the packaged tokens into Manta Renew Paradigm.
          </p>

          <div className="grid grid-flow-row grid-cols-6 gap-4">
            {Partners.map(partner => (
              <a
                href={partner.url}
                target="_blank"
                rel="noreferrer"
                className="w-[210px] h-[62px] flex items-center justify-center bg-white rounded-2xl"
                key={partner.name}
              >
                <img src={partner.logo} className="mr-2 w-[30px] h-[30px]" />
                {partner.name}
              </a>
            ))}
          </div>
        </section>

        <section className="flex justify-center p-12">
          <div>
            <h1 className="mb-12 uppercase text-5xl text-center">
              3 Underlying Assets
            </h1>

            <div className="flex items-center justify-between primary-border p-6 rounded-2xl mb-4">
              <div className="flex items-center justify-between w-[440px]">
                <div className="flex justify-start">
                  <img src={manta} className="w-[48px] h-[48px]" />
                  <div className="mx-4">
                    <h1 className="text-[24px] font-medium">MANTA</h1>
                    <p>Manta Network</p>
                  </div>
                </div>

                <button
                  className="btn-primary"
                  onClick={() =>
                    handleCopy('0x95CeF13441Be50d20cA4558CC0a27B601aC544E5')
                  }
                >
                  Copy Address
                </button>
              </div>

              <div className="w-[560px] mx-12">
                Compatible Projects MANTA Copy Address MANTA, the native token
                of both Manta Atlantic (L1) and Manta Pacific (L2), is utilized
                for staking, transaction fees, and governance. Manta Network
              </div>

              <img src={asset1} className="w-[200px] h-[103px]" />
            </div>

            <div className="flex items-center justify-between primary-border p-6 rounded-2xl mb-4">
              <div>
                <div className="flex items-center justify-between mb-4">
                  <div className="flex justify-start">
                    <img src={stone} className="w-[48px] h-[48px]" />
                    <div className="ml-4">
                      <h1 className="text-[24px] font-medium">STONE</h1>
                      <p>StakeStone</p>
                    </div>
                  </div>

                  <button
                    className="btn-primary"
                    onClick={() =>
                      handleCopy('0xEc901DA9c68E90798BbBb74c11406A32A70652C3')
                    }
                  >
                    Copy Address
                  </button>
                </div>
                <img src={stoneText} alt="" width={440} />
              </div>

              <div className="w-[560px] mx-12">
                Based on LayerZero, STONE is a non-rebase OFT (Omnichain
                Fungible Token) that supports both assets and prices to be
                transferred across multiple blockchains seamlessly. Developers
                on Manta can effortlessly integrate STONE without additional
                complexities, leaving it as the most accessible LST ready for
                mass adoption.
              </div>

              <img src={asset2} className="w-[200px] h-[103px]" />
            </div>

            <div className="flex items-center justify-between primary-border p-6 rounded-2xl mb-4">
              <div className="flex items-center justify-between w-[440px]">
                <div className="flex justify-start">
                  <img src={wUSDM} className="w-[48px] h-[48px]" />
                  <div className="mx-4">
                    <h1 className="text-[24px] font-medium">wUSDM</h1>
                    <p>Mountain Protocol</p>
                  </div>
                </div>

                <button
                  className="btn-primary"
                  onClick={() =>
                    handleCopy('0xbdAd407F77f44F7Da6684B416b1951ECa461FB07')
                  }
                >
                  Copy Address
                </button>
              </div>

              <div className="w-[560px] mx-12">
                The wUSDM is a non-rebasing token designed to facilitate
                seamless integration into DeFi protocols. It accrues new reward
                tokens irrespective of the location where the USDM is held,
                contributing to the appreciation of the wUSDM token&apos;s
                value.
              </div>

              <img src={asset1} className="w-[200px] h-[103px]" />
            </div>
          </div>
        </section>

        <section className="p-12 flex flex-col items-center justify-center">
          <h1 className="mb-12 uppercase text-5xl text-center">
            BEYOND PENTA YIELD
          </h1>

          <div className="flex">
            <div className="grid gap-4">
              <div className="w-[753px] bg-green-bg p-4 rounded-lg">
                Fixed yield on STONE and wUSDM
              </div>
              <div className="w-[753px] bg-green-bg p-4 rounded-lg">
                Ecosystem yield through defi protocols (Lending, CDP, and DEX)
              </div>
              <div className="w-[753px] bg-green-bg p-4 rounded-lg">
                <p className="mb-4 font-medium">
                  Utilize STONE as the underlying asset for restaking on Manta
                  Renew Paradigm to unlock additional point incentives,
                  including:
                </p>
                <ul
                  style={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside'
                  }}
                >
                  <li>STONE points (Potential for StakeStone token airdrop)</li>
                  <li>Eigenlayer points (Will be distributed by StakeStone)</li>
                </ul>
              </div>
              <div className="w-[753px] bg-green-bg p-4 rounded-lg">
                <p className="mb-4 font-medium">
                  Fixed yield on STONE and wUSDM
                </p>
                <ul
                  style={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside'
                  }}
                >
                  <li>
                    Share a 3M MANTA token incentive based oneach project&apos;s
                    TVL rank.
                  </li>
                  <li>
                    Token incentives from Manta Pacific ecosystem projects.
                  </li>
                </ul>
              </div>
            </div>

            <img src={table} alt="" width={543} className="ml-12" />
          </div>
        </section>

        <section className="flex items-center justify-center">
          <div className="bg-2 w-[1440px] p-12">
            <img src={chart} alt="" width={825} />
          </div>
        </section>

        <section className="p-12 pb-0 flex items-center justify-center">
          <div className="w-[664px] mr-4">
            <h1 className=" uppercase text-5xl">
              25 box pieces
              <br />
              to open lucky box
            </h1>
            <p className="my-6">
              Through Renew Paradigm, You have the opportunity to gain
              significant yield from the token incentives of 11 ecoprojects and
              MANTA rewards through Lucky Boxes. To obtain a Lucky Box, you need
              25 Box Pieces. There are three ways to earn Box Pieces:
            </p>

            <div className="grid grid-cols-3 gap-4">
              <div className="p-4 bg-green-bg rounded-2xl">
                <div className="text-[32px] mb-4">1.</div>
                <div className="text-[14px">
                  Stake eco-staked token listed above on Renew Paradigm page.
                </div>
              </div>

              <div className="p-4 bg-green-bg rounded-2xl">
                <div className="text-[32px] mb-4">2.</div>
                <div className="text-[14px">Invite friends</div>
              </div>

              <div className="p-4 bg-green-bg rounded-2xl">
                <div className="text-[32px] mb-4">3.</div>
                <div className="text-[14px">
                  Achieve team goals together with friends.
                </div>
              </div>
            </div>
          </div>
          <img src={box} alt="" width={664} />
        </section>

        <section className="flex items-center justify-center">
          <div className="bg-3 flex flex-col items-center justify-center w-[1440px]">
            <h1 className=" uppercase text-5xl">5 NFTs for 11 projects</h1>
            <p className="p-6">
              Each project will provide you 4 regular NFTs, stake your assets to
              collect them.
              <br />
              Once you have all the 4 regular NFTs, you will get the Legendary
              NFT.
              <br />
              Redeem your NFTs for rewards.You may also purchase the NFTs
              directly.
            </p>

            {/* <div className="flex items-center">
              <div>Get More NFTs on: </div>
              <img src={okx} alt="" className="ml-4 mr-2" />

              <a href="">OKX NFT Marketplace</a>
              <img src={alien} alt="" className="ml-4 mr-2" />

              <a href="">Alienswap</a>
            </div> */}

            <div className="h-[190px]"></div>
          </div>
        </section>

        <section className="bg-4 flex items-center justify-center w-full">
          <div className="p-12 bg-white w-full">
            <h1 className=" uppercase text-5xl text-center mb-6">timeline</h1>
            <div className="flex items-center justify-center py-6">
              <div>
                <div className="text-[40px] uppercase text-center">30 DAYS</div>
                <div className="gradient-text-3 mt-2 font-medium">
                  Deposit Period
                </div>
              </div>
              <div className="text-[32px] px-6">+</div>
              <div>
                <div className="text-[40px] uppercase text-center">39 DAYS</div>
                <div className="gradient-text-3 mt-2 font-medium">
                  Lock Period
                </div>
              </div>
              <div className="text-[32px] px-6">=</div>
              <div>
                <div className="text-[64px] uppercase text-center gradient-text-3 ">
                  69 DAYS
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default About;
