import { useEffect, useMemo, useState } from 'react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import LoadingIcon from '@/components/Loading';
import UnstakeHistoryContent, {
  UnstakeHistoryContentProps
} from '@/pages/Stake/UnstakeContent/components/UnstakeHistory';
import BaseModal from '@/components/BaseModal';
import { UnstakeToken } from '../..';
import useUnstakeHistory from '../../hooks/useUnstakeHistory';

export enum UnstakeStatus {
  pending = '0', // user clicked unstake
  finalized = '1', // after unstake, before claim
  claimed = '2'
}

export const Arrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="ml-2"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.96967 10.2803C3.67678 9.98744 3.67678 9.51256 3.96967 9.21967L7.18934 6L3.96967 2.78033C3.67678 2.48744 3.67678 2.01256 3.96967 1.71967C4.26256 1.42678 4.73744 1.42678 5.03033 1.71967L8.78033 5.46967C9.07322 5.76256 9.07322 6.23744 8.78033 6.53033L5.03033 10.2803C4.73744 10.5732 4.26256 10.5732 3.96967 10.2803Z"
      fill="currentColor"
    />
  </svg>
);

const UnstakePending = ({ fromToken }: { fromToken: UnstakeToken }) => {
  const [isOpen, toggleIsOpen] = useState(false);

  const { unstakeHistoryList, historyLoading } = useUnstakeHistory();

  const mTokenHistoryList = useMemo(() => {
    return unstakeHistoryList.filter(
      history =>
        history.mToken.toLocaleLowerCase() ===
        fromToken.address.toLocaleLowerCase()
    );
  }, [fromToken.address, unstakeHistoryList]);

  const pendingAmount = useMemo(() => {
    const pendingList = unstakeHistoryList.filter(
      history =>
        history.mToken.toLocaleLowerCase() ===
          fromToken.address.toLocaleLowerCase() &&
        history.status === UnstakeStatus.pending
    );
    const amount = pendingList.reduce(
      (prev, cur) => prev.add(cur.mTokenAmount),
      BigNumber.from(0)
    );
    return formatUnits(amount, fromToken.decimals);
  }, [fromToken.address, fromToken.decimals, unstakeHistoryList]);

  const hasReadyClaimHistory = useMemo(() => {
    return unstakeHistoryList.find(
      history =>
        history.status === UnstakeStatus.finalized &&
        history.mToken.toLocaleLowerCase() ===
          fromToken.address.toLocaleLowerCase()
    );
  }, [unstakeHistoryList, fromToken.address]);

  useEffect(() => {
    // prevents horizontal scroll when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const openHistory = () => {
    toggleIsOpen(true);
  };

  if (historyLoading) {
    return <LoadingIcon isDark className="my-4" />;
  }
  if (!mTokenHistoryList.length) {
    return null;
  }

  return (
    <>
      <div className="mb-4 px-6 py-4 border border-green/80 rounded-lg text-sm w-full bg-white text-black-title">
        <div className="flex justify-between">
          <span>Pending Amount ({fromToken?.name})</span>
          <span
            className="cursor-pointer flex items-center text-black-title/80"
            onClick={openHistory}
          >
            History <Arrow />
          </span>
        </div>
        <div className="text-2xl mt-2 flex justify-between items-center flex-wrap">
          <span>{pendingAmount}</span>
          {hasReadyClaimHistory && (
            <span
              className="flex items-center text-sm text-green cursor-pointer"
              onClick={openHistory}
            >
              You have assets ready to claim <Arrow />
            </span>
          )}
        </div>
      </div>
      <BaseModal<UnstakeHistoryContentProps>
        centered
        open={isOpen}
        title={'Withdraw History'}
        width={1000}
        onCancel={() => toggleIsOpen(false)}
      >
        <UnstakeHistoryContent
          historyList={unstakeHistoryList ?? []}
          mTokenAddress={fromToken.address}
        />
      </BaseModal>
    </>
  );
};

export default UnstakePending;
