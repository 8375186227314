import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import React, { useState } from 'react';
import twitterAuth from '@/utils/twitterAuth';
import useTwitterAuthCaptcha from '@/hooks/useTwitterAuthCaptcha';

import PointItem from './components/PointItem';
import BoostExtraPoints from './components/BoostExtraPoints';
import MantaStakerExtraPoints from './components/MantaStakerExrtaPoints';

export interface ICollapseProps {
  0: boolean;
  1: boolean;
}

export default function ExtraPoints() {
  const navigate = useNavigate();

  const handleInviteFriends = () => {
    navigate('dashboard/invites');
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = async (captcha: any) => {
    if (captcha) {
      try {
        closeCaptcha();
        const res = await twitterAuth(captcha);
        const jsonRes = await res.json();
        if (jsonRes.success) {
          window.open(jsonRes.url, '_self');
        } else {
          notification.error({
            message: jsonRes.message
          });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.log('getAuthUrl error', e.message);
      }
    }
  };
  const { AuthModal, closeCaptcha } = useTwitterAuthCaptcha({
    onChange
  });

  const [collapseDisplay, setCollapseDisplay] = useState<ICollapseProps>({
    0: true,
    1: false
  });
  const changeDisplay = (index: keyof ICollapseProps) => {
    setCollapseDisplay(preState => {
      preState[index] = !preState[index];
      return { ...preState };
    });
  };
  return (
    <div className="w-full bg-white-80 flex flex-col border border-primary-green-border rounded-2xl p-6  gap-4 ">
      <p className="text-[20px] text-black-title font-[400] ">
        Earn Extra Points
      </p>
      <MantaStakerExtraPoints
        show={collapseDisplay[0]}
        changeDisplay={changeDisplay}
      />
      <PointItem name="Refer Friends" isFinished={true}>
        <button
          className="btn-primary w-[140px] max-md:w-[7.5rem]  "
          onClick={handleInviteFriends}
        >
          Open
        </button>
      </PointItem>

      {/* <MantaStakerExtraPoints
        show={collapseDisplay[0]}
        changeDisplay={changeDisplay}
      />
      <BoostExtraPoints
        show={collapseDisplay[1]}
        changeDisplay={changeDisplay}
      /> */}

      {/* <PointItem name="Golden Shovel Holders" isFinished={true}>
        <button disabled={!goldenResults} className="btn-primary w-[140px]">
          Claim
        </button>
      </PointItem>
      <PointItem name="$MANTA Stakers" isFinished={true}>
        <button disabled={!stakerResults} className="btn-primary w-[140px]">
          Claim
        </button>
      </PointItem> */}
      {/* <p className="text-center text-[16px] font-[400] text-black-title">
        More coming soon...
      </p> */}

      {AuthModal}
    </div>
  );
}
