import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { useAccount } from 'wagmi';
import boxBg from '@/assets/points/box-bg.png';
import LoadingIcon from '@/components/Loading';
import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';
import xIcon from '@/assets/svgs/x.svg';
import ProcessBar from '@/components/CheckInProcessBar';
import useTwitterAuthCaptcha from '@/hooks/useTwitterAuthCaptcha';
import twitterAuth from '@/utils/twitterAuth';
import { useCheckInStatus } from '@/store/useCheckInStatus';
import useBatchMtokenInfo from '@/store/useBatchMtoken';
import usePointsBox from './usePointsBox';
import CountdownTimer from './components/StakeCountDown';

export interface IProps {
  showConnectModal: () => void;
  user_address?: string;
  pointsData?: IPointsStatusRes;
  mutateStatus: () => void;
  pointStatusLoading: boolean;
}

export default function Boxes({
  pointsData,
  showConnectModal,
  user_address,
  mutateStatus,
  pointStatusLoading = false
}: IProps) {
  const { data, isLoading: mTokenLoading } = useBatchMtokenInfo();
  const { address } = useAccount();
  const [mbtc, meth, musd] = data || [null, null, null];
  const hasMtoken =
    (mbtc?.balanceOf && mbtc?.balanceOf !== '0') ||
    (meth?.balanceOf && meth?.balanceOf !== '0') ||
    (musd?.balanceOf && musd?.balanceOf !== '0');

  const onChange = async (captcha: any) => {
    if (captcha) {
      try {
        closeCaptcha();
        const res = await twitterAuth(captcha);
        const jsonRes = await res.json();
        if (jsonRes.success) {
          window.open(jsonRes.url, '_self');
        } else {
          notification.error({
            message: jsonRes.message
          });
        }
      } catch (e: any) {
        console.log('getAuthUrl error', e.message);
      }
    }
  };
  const { AuthModal, user_twitter, openCaptcha, closeCaptcha } =
    useTwitterAuthCaptcha({
      onChange
    });
  const { BoxesModal, setBoxesData } = usePointsBox({
    twitterAuthProcess: () => {
      notification.error({
        message: `Click to bind Twitter`
      });
    }
  });
  const [isLoading, setIsLoading] = useState(false);
  const showData = user_address && pointsData && pointsData?.code !== -2;

  const wasStaked = user_address && pointsData && pointsData?.code !== -2;

  const { checkInStatus } = useCheckInStatus();
  const {
    data: checkInDataRes,
    mutate: checkInStatusMutate,
    isLoading: CheckInStatusLoading
  } = checkInStatus;

  const handleOpenBoxes = async (showPoints = true) => {
    if (!showPoints) {
      setBoxesData({
        points: checkInDataRes?.last_open_box_points || '0',
        hasOpened: true
      });
    } else {
      try {
        setIsLoading(true);
        const boxIdRes = await fetcher(ENDPOINTS.getPointsBoxId, {
          method: 'GET'
        });
        const res = await fetcher(
          ENDPOINTS.openPointsBox,
          {
            method: 'POST',
            body: JSON.stringify({
              open_box_request_id: boxIdRes.open_box_request_id
            })
          },
          false
        );
        if (res?.code !== 0) {
          notification.error({
            message: res?.message || 'Open Box Error'
          });
          return;
        }
        if (res?.data?.points === 0) {
          return;
        }
        setBoxesData(res?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        mutateStatus();
        checkInStatusMutate();
      }
    }
  };
  const navigate = useNavigate();
  const navStakePage = () => {
    navigate(`/dashboard/stake`);
  };
  const handleTwitterShare = async () => {
    if (user_twitter) {
      if (pointsData?.data?.openable_box) {
        handleOpenBoxes(true);
      } else {
        handleOpenBoxes(false);
      }
    } else {
      openCaptcha();
    }
  };
  const hasSharedToday = checkInDataRes?.today_checked || false;
  const hideShareBtn =
    pointsData?.data?.openable_box === 0 &&
    checkInDataRes?.can_be_check_in === false;
  return (
    <div className="flex flex-col bg-white-80 items-center p-6 md:min-w-[437px] max-md:w-full gap-4 border border-primary-green-border rounded-2xl">
      {BoxesModal}
      <div className="flex flex-1 w-full justify-between items-center">
        <div className="flex flex-col items-start">
          <div className="text-[20px] text-black-title font-[400]">Boxes</div>
        </div>
        <div className="text-[40px] text-black-title font-[300]">
          {!(mTokenLoading || pointStatusLoading) && (showData || hasMtoken)
            ? pointsData?.data?.openable_box || 0
            : '-'}
        </div>
      </div>
      <img src={boxBg} width={311} height={157} />
      <div className="flex flex-col items-center">
        <div className="text-[40px] text-black-title font-[300]">
          {!(mTokenLoading || pointStatusLoading) && (showData || hasMtoken) ? (
            <CountdownTimer endTime={1682640000000} />
          ) : (
            '- -'
          )}
        </div>
        <div className="text-[12px] font-[400] text-black-title/70 italic">
          Updated at 00:00 UTC
        </div>
        <div className="mt-1 text-[12px] font-[400] text-green italic">
          * The unopened boxes before the 2nd snapshot will be automatically
          opened, and the points will be automatically added to the 2nd round
          points.
        </div>
      </div>
      {user_address && address ? (
        showData || hasMtoken ? (
          <button
            disabled={
              !pointsData?.data?.openable_box ||
              isLoading ||
              mTokenLoading ||
              pointStatusLoading
            }
            onClick={() => {
              handleOpenBoxes();
            }}
            className="btn-primary h-[52px] w-full"
          >
            {isLoading || mTokenLoading || pointStatusLoading ? (
              <LoadingIcon />
            ) : (
              'Open All'
            )}
          </button>
        ) : (
          <button
            disabled={mTokenLoading || pointStatusLoading}
            onClick={() => {
              navStakePage();
            }}
            className="btn-primary h-[52px] w-full"
          >
            {pointStatusLoading || mTokenLoading ? <LoadingIcon /> : 'Deposit'}
          </button>
        )
      ) : (
        <button
          onClick={() => {
            showConnectModal();
          }}
          className="btn-primary h-[52px] w-full"
        >
          Connect Wallet
        </button>
      )}
      {!hideShareBtn && wasStaked && (
        <button
          onClick={() => {
            handleTwitterShare();
          }}
          disabled={hasSharedToday || CheckInStatusLoading}
          className="btn-reverse h-[52px] w-full flex justify-center items-center gap-2"
        >
          {CheckInStatusLoading || isLoading ? (
            <LoadingIcon isDark={true} />
          ) : (
            <div className="w-full flex justify-center items-center gap-2">
              <img src={xIcon} className="w-6 h-6" alt={'xIcon'} />
              {user_twitter
                ? hasSharedToday
                  ? 'Already checked in'
                  : 'Daily Check-In'
                : 'Bind Twitter to earn Points'}
            </div>
          )}
        </button>
      )}
      {!hideShareBtn && wasStaked && <ProcessBar data={checkInDataRes} />}
      {AuthModal}
    </div>
  );
}
