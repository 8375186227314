import { useAccount, useConfig, useDisconnect } from 'wagmi';
import { signMessage } from '@wagmi/core';
import { mutate } from 'swr';
import { useState } from 'react';
import { notification } from 'antd';
import ENDPOINTS from '@/constants/apiEndpoints';
import useLoginStep from '@/store/useLoginStep';

function SignWallet({
  className,
  isFromWatchAccount = false,
  hideModal,
  showBlockModal
}: {
  className?: string;
  isFromWatchAccount?: boolean;
  hideModal?: () => void;
  showBlockModal?: () => void;
}) {
  const { address } = useAccount();
  const { step, setStep } = useLoginStep();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const wagmiConfig = useConfig();
  const { disconnect } = useDisconnect();
  const sign = async () => {
    try {
      setIsBtnDisabled(true);
      const res = await fetch(ENDPOINTS.getSignData, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ address })
      });
      const data = await res.json();
      if (data.success) {
        const message = data.message;
        const signature = await signMessage(wagmiConfig, {
          message
        });
        const res = await fetch(ENDPOINTS.login, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ address, signature, message })
        });
        const _data = await res.json();
        if (_data.success) {
          if (isFromWatchAccount) {
            if (step === 4) {
              hideModal && hideModal();
              setStep(3);
            } else {
              mutate(ENDPOINTS.userInfo);
              hideModal && hideModal();
            }
          } else {
            setStep(1);
            mutate(ENDPOINTS.userInfo);
          }
        }
      } else {
        if (data?.code === -13) {
          hideModal && hideModal();
          showBlockModal && showBlockModal();
          disconnect();
        } else {
          notification.error({
            message: data.message
          });
        }
      }
    } catch (e: any) {
      console.log(e.message);
    } finally {
      setIsBtnDisabled(false);
    }
  };

  return (
    <div>
      <button
        disabled={isBtnDisabled}
        className={`btn-primary w-[200px] ${className}`}
        onClick={sign}
      >
        Sign Wallet
      </button>
    </div>
  );
}

export default SignWallet;
