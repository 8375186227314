import useSWR from 'swr';
import ENDPOINTS from '@/constants/apiEndpoints';

const fetcher = (url: string) =>
  fetch(url, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => res.json());

type UserInfo = {
  is_address_deposited?: number;
  user_name?: string;
  user_twitter?: string;
  user_address?: string;
  user_avatar?: string;
  invite_code?: string;
  invite_user_name?: string;
};

function useUserInfo() {
  const { data, isLoading, mutate } = useSWR(ENDPOINTS.userInfo, fetcher, {
    revalidateOnFocus: false
  });

  let userInfo: UserInfo = {};
  if (data && data.success) {
    userInfo = data.userInfo;
  }
  return {
    data,
    userInfo,
    isLoading,
    mutate
  };
}
export default useUserInfo;
