import numbro from 'numbro';
import React from 'react';
import Marquee from 'react-fast-marquee';

import btcCoin from '@/assets/svgs/btc-coin.svg';
import discordIcon from '@/assets/svgs/discord.svg';
import ethCoin from '@/assets/svgs/eth-coin.svg';
import mediumIcon from '@/assets/svgs/medium.svg';
import usdtCoin from '@/assets/svgs/usdt-coin.svg';
import xIcon from '@/assets/svgs/x.svg';
import { DECIMAL_PLACES } from '@/store/useBatchMtoken';
import useTVL from '@/store/useTVL';

type TokenKey = 'BTC' | 'ETH' | 'WBETH' | 'USD';

interface CoinInfoProps {
  coinImg: string;
  coinName: string;
  value: string;
  apy: string;
}

const mediaLinks = [
  {
    icon: discordIcon,
    link: 'https://discord.com/invite/mantanetwork'
  },
  {
    icon: xIcon,
    link: 'https://twitter.com/MantaNetwork'
  },
  {
    icon: mediumIcon,
    link: 'https://mantanetwork.medium.com/'
  }
];

const coinConfigs: Array<{
  coinImg: string;
  coinName: string;
  tokenKey: TokenKey;
}> = [
  { coinImg: btcCoin, coinName: 'BTC', tokenKey: 'BTC' },
  { coinImg: ethCoin, coinName: 'ETH', tokenKey: 'ETH' },
  { coinImg: ethCoin, coinName: 'WBETH', tokenKey: 'WBETH' },
  { coinImg: usdtCoin, coinName: 'USDT/USDC/wUSDM', tokenKey: 'USD' }
];

function formatTVLValue(value: number | undefined, isLoading: boolean): string {
  if (isLoading) return '--';
  if (!value) return '0';

  return numbro(value).format({
    thousandSeparated: true,
    mantissa: DECIMAL_PLACES,
    trimMantissa: true
  });
}

function CoinInfo({ coinImg, coinName, value, apy }: CoinInfoProps) {
  return (
    <div className="flex flex-shrink-0 flex-row items-center px-4">
      <img src={coinImg} alt={coinName} className="w-4 h-4 rounded-full" />
      <span className="text-[14px] text-black-title/60 ml-1">{coinName}</span>
      <span className="text-[14px] text-black-title ml-1 max-md:font-[600]">
        ${value}
      </span>
      <span className="text-[14px] text-black-title/60 ml-2">
        APR(variable)
      </span>
      <span className="text-[14px] text-black-title ml-1 max-md:font-[600]">
        {apy}
      </span>
    </div>
  );
}

function Divider() {
  return (
    <div className="flex-shrink-0 w-[1px] h-[13px] text-black-title bg-black-title"></div>
  );
}

function Banner() {
  const { data: tvlData, isLoading } = useTVL();

  return (
    <div className="border-t border-b border-green/60 max-md:w-[100vw] max-md:overflow-hidden">
      <Marquee play className="banner-marquee">
        <div className="flex flex-row flex-shrink-0 justify-between items-center py-2 px-12 max-md:justify-start">
          <div className="flex items-center">
            <div className="flex flex-shrink-0 gap-1 items-center pr-4">
              <span className="text-[14px] font-[400] text-black-title/60">
                TVL
              </span>
              <span className="text-[14px] font-[400] text-black-title max-md:font-[600]">
                $ {formatTVLValue(tvlData?.tvl, isLoading)}
              </span>
            </div>
            {coinConfigs.map((config, index) => (
              <React.Fragment key={config.tokenKey}>
                <Divider />
                <CoinInfo
                  coinImg={config.coinImg}
                  coinName={config.coinName}
                  value={formatTVLValue(
                    tvlData?.tokenList?.[config.tokenKey]?.tvl,
                    isLoading
                  )}
                  apy={
                    !isLoading
                      ? tvlData?.tokenList?.[config.tokenKey]?.apr || '0'
                      : '--'
                  }
                />
              </React.Fragment>
            ))}
          </div>
          <div className="flex flex-shrink-0 gap-2 max-md:pl-16">
            {mediaLinks.map(({ icon, link }) => (
              <a
                key={link}
                href={link}
                target="_blank"
                rel="noreferrer"
                className="hover:opacity-80"
              >
                <img src={icon} alt={link} className="w-6 h-6" />
              </a>
            ))}
          </div>
        </div>
      </Marquee>
    </div>
  );
}

export default Banner;
