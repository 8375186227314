import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';
import useSWR from 'swr';

export type TVLObj = {
  tvl: number;
  apr: string;
  timestamp: number;
};
export type Result = {
  data: {
    tvl: number;
    tokenList: {
      BTC: TVLObj;
      ETH: TVLObj;
      USD: TVLObj;
      WBETH: TVLObj;
    };
  };
};

const useTVL = () => {
  const { data, isLoading } = useSWR<Result>(
    `${ENDPOINTS.stakeTvl}`,
    (url: string) => {
      return fetcher(url, { method: 'GET' }, false);
    }
  );
  return {
    data: data?.data,
    isLoading: isLoading
  };
};

export default useTVL;
