import Decimal from 'decimal.js';
import { ethers, BigNumber } from 'ethers';

export const cleanNumString = (s: string, decimals: number = 0) => {
  let out = '';
  let decimalSeen = false;
  s.split('').forEach((c: string) => {
    if (c === '.' && !decimalSeen) {
      out += c;
      decimalSeen = true;
      return;
    }
    if ('0123456789'.includes(c)) {
      out += c;
    }
  });

  const parts = out.split('.');
  if (parts.length > 1 && decimals > 0) {
    parts[1] = parts[1]!.substring(0, decimals);
  }

  return parts.join('.');
};

export const numStringToBigNumber = (ns: string, units: number) => {
  if (!ns) {
    return ethers.BigNumber.from(0);
  }
  return ethers.utils.parseUnits(ns, BigNumber.from(units));
};

Decimal.set({ toExpPos: 9e15, precision: 1e9, toExpNeg: -9e15 });

export const roundDownNumString = (value: string, decimal: number = 6) => {
  return new Decimal(value)
    ?.toDecimalPlaces(decimal, Decimal.ROUND_DOWN)
    ?.toString();
};
