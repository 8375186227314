import { Chain } from 'viem';
import { StakeToken } from '@/pages/Stake';
import config from '@/config';
import { useAddToken } from '@/hooks/useAddToken';
import type { IModalContentProps } from '@/hooks/useModal';

const { MANTA_PACIFIC_CHAIN } = config;

export interface StakeSuccessContentProps {
  amount: string;
  selectedToken: StakeToken;
  selectedChain: Chain;
  txHash: string;
  isMantaPacificChain: boolean;
  onConfirm: () => void;
}

function StakeSuccessContent({
  data
}: IModalContentProps<StakeSuccessContentProps>) {
  const {
    amount,
    selectedToken,
    selectedChain,
    txHash,
    isMantaPacificChain,
    onConfirm
  } = data;

  const { addTokenToWalletWithCertainChain } = useAddToken();
  const renderArrivalTime = () => {
    if (isMantaPacificChain) {
      return '1 minute';
    }
    return '30 minutes';
  };
  const addTokenToWallet = async () => {
    onConfirm();
    addTokenToWalletWithCertainChain(MANTA_PACIFIC_CHAIN?.id, {
      address: selectedToken?.mToken?.address,
      symbol: selectedToken?.mToken?.name,
      decimals: selectedToken?.mToken?.decimals
    });
  };
  return (
    <div className="flex flex-col mt-4 gap-4 leading-normal  text-black-title text-sm">
      <p>
        You&apos;ve successfully deposited {amount} {selectedToken?.name}!
      </p>
      <a
        href={`${selectedChain?.blockExplorers?.default?.url}/tx/${txHash}`}
        className="text-green"
        target="_blank"
        rel="noreferrer"
      >
        Transaction Receipt
      </a>
      <div>
        You should see {selectedToken?.name} arrive in your wallet within{' '}
        {renderArrivalTime()}.
      </div>
      <button onClick={addTokenToWallet} className="btn-primary">
        Add {selectedToken?.mToken?.name} to your wallet
      </button>
    </div>
  );
}

const Content = (props: IModalContentProps<StakeSuccessContentProps>) => (
  <StakeSuccessContent {...props} />
);

export default Content;
