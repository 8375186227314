import numbro from 'numbro';
import { useAccount } from 'wagmi';
import BigNumber from 'bignumber.js';
import arrowBlack from '@/assets/svgs/arrow-black.svg';
import { MTokenStakeResponse } from '@/store/useGetMTokenStake';
import { fromWei } from '@/utils/format';
import { DECIMAL_PLACES } from '@/store/useBatchMtoken';
import { useContracts } from '@/contexts/ContractsContext';
import useTransaction from '@/hooks/useTransaction';
import LoadingIcon from '@/components/Loading';

export interface MTokenUnstakeItemProps {
  record: MTokenStakeResponse & { name?: string; price?: number };
  handleRefresh: () => Promise<void>;
}

const formula = (amount: number) => {
  return 100 * amount;
};

function MTokenUnstakeItem({ record, handleRefresh }: MTokenUnstakeItemProps) {
  const { mTokenStateContract } = useContracts();
  const { isConnected } = useAccount();

  const { run, loading } = useTransaction(mTokenStateContract?.unstake, {
    wait: true
  });

  const stakedAmount = fromWei(record?.stakedAmount || '0');

  const totalAmount = new BigNumber(stakedAmount).times(record?.price || '0');

  const points = totalAmount.toNumber()
    ? formula(Math.floor(totalAmount.toNumber() / 20))
    : '0';

  const handleUnstake = async () => {
    if (loading) return;

    await run(record?.mToken, record?.stakedAmount);
    await handleRefresh();
  };

  return (
    <div className="flex justify-between items-center mb-2">
      <div className="flex gap-2 max-md:flex-col">
        <span className="text-black-title font-normal">
          {isConnected
            ? stakedAmount.gt(0)
              ? numbro(stakedAmount ?? 0).format({
                  thousandSeparated: true,
                  mantissa: DECIMAL_PLACES,
                  trimMantissa: true
                })
              : '0'
            : '--'}{' '}
          {record?.name}
        </span>
        <span className="text-sm text-black-title/70 font-normal italic">
          + approximately{' '}
          {isConnected
            ? stakedAmount.gt(0)
              ? numbro(points ?? 0).format({
                  thousandSeparated: true,
                  mantissa: DECIMAL_PLACES,
                  trimMantissa: true
                })
              : '0'
            : '--'}{' '}
          Points/Day
        </span>
      </div>
      <div
        onClick={handleUnstake}
        className="flex items-center gap-2 hover:cursor-pointer hover:opacity-70"
      >
        <span className="font-medium text-black-title ">Unstake</span>
        {loading ? (
          <LoadingIcon className="w-4 h-4" isDark />
        ) : (
          <img src={arrowBlack} />
        )}
      </div>
    </div>
  );
}
export default MTokenUnstakeItem;
