import { create } from 'zustand';

interface InviteCodeState {
  inviteCode: string;
  setInviteCode: (_: string) => void;
}

const useInviteCode = create<InviteCodeState>(set => ({
  inviteCode: '',
  setInviteCode: (inviteCode: string) => set({ inviteCode })
}));

export default useInviteCode;
