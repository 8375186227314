import config from '@/config';
import { UnstakeToken } from '@/pages/Stake/UnstakeContent';
import type { IModalContentProps } from '@/hooks/useModal';

const { MANTA_PACIFIC_CHAIN } = config;

export interface UnstakeSuccessContentProps {
  amount: string;
  fromToken: UnstakeToken;
  toToken: UnstakeToken;
  txHash: string;
  onCancel: () => void;
}

function UnstakeSuccessContent({
  data
}: IModalContentProps<UnstakeSuccessContentProps>) {
  const { amount, fromToken, txHash, onCancel, toToken } = data;

  return (
    <div className="flex flex-col mt-4 gap-4 leading-normal  text-black-title text-sm">
      <p>
        You&apos;ve successfully withdrawed {amount} {fromToken?.name}!
      </p>
      <a
        href={`${MANTA_PACIFIC_CHAIN?.blockExplorers?.default?.url}/tx/${txHash}`}
        className="text-green"
        target="_blank"
        rel="noreferrer"
      >
        Transaction Receipt
      </a>
      <div className="p-4 border border-green/60 bg-green/5 rounded-lg">
        You should be able to claim your {toToken?.name} in the History section
        after 7 days.
      </div>
      <button
        className="flex w-full justify-center btn-primary h-[51px] max-md:h-max"
        onClick={onCancel}
      >
        Back
      </button>
    </div>
  );
}

const Content = (props: IModalContentProps<UnstakeSuccessContentProps>) => (
  <UnstakeSuccessContent {...props} />
);

export default Content;
