import ConnectWallet from '@/components/ConnectWallet';
import { MTokenInfo, MyPoint } from '@/components/SubNav';
import useInviteInfo from '@/store/useInviteInfo';
import { useResponsive } from '@/hooks/useResonposive';
import TeamContribution from './TeamContribution';
import TeamMember from './TeamMember';

const InviteTabContent = () => {
  const { isMobile } = useResponsive();
  const { data, isLoading } = useInviteInfo();
  if (!data || !data.user_id) {
    return (
      <div className="flex flex-1 items-center justify-center mt-10">
        {isLoading ? 'Loading...' : <ConnectWallet />}
      </div>
    );
  }

  return (
    <div>
      {isMobile && (
        <div className="flex flex-col items-center md:hidden">
          <MyPoint />
          <MTokenInfo />
        </div>
      )}
      <TeamContribution
        deposit={data.team_deposit}
        token={data.deposit_token}
        middle={data.goal_a_milestone}
        max={data.goal_b_milestone}
      />
      <div className="h-4"></div>
      <TeamMember
        token={data.deposit_token}
        inviteCodeList={data.invite_code_list}
        memberList={data.member_list}
      />
    </div>
  );
};

export default InviteTabContent;
