import useSWR from 'swr';
import ENDPOINTS from '@/constants/apiEndpoints';
import useUserInfo from '@/store/useUserInfo';
import { getRequest } from '@/utils/request';

const useInviteInfo = () => {
  const { userInfo } = useUserInfo();
  const inviteInfo = useSWR(
    ENDPOINTS.userInviteInfo + `?user_address=${userInfo.user_address}`,
    getRequest
  );
  return inviteInfo;
};
export default useInviteInfo;
