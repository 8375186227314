import {
  useAccount,
  useBalance,
  useConnectUI,
  useCurrentConnector,
  useDisconnect,
  useWallet
} from '@fuels/react';
import type { FuelConnector } from 'fuels';

export function getConnectorLogo(connector: FuelConnector) {
  return connector && typeof connector.metadata?.image === 'object'
    ? connector.metadata.image.dark ?? ''
    : (connector?.metadata?.image as string) ?? '';
}

export const useFuelWallet = () => {
  const {
    connect,
    isConnected,
    isConnecting,
    isLoading: isLoadingConnectors
  } = useConnectUI();
  const { disconnect } = useDisconnect();
  const { currentConnector: _currentConnector } = useCurrentConnector();
  const connectImage = _currentConnector
    ? getConnectorLogo(_currentConnector)
    : '';
  const currentConnector = {
    logo: connectImage,
    name: _currentConnector?.name ?? 'Wallet Demo'
  };
  const {
    account,
    isLoading: isLoadingAccount,
    isFetching: isFetchingAccount
  } = useAccount();
  const {
    balance,
    isLoading: isLoadingBalance,
    isFetching: isFetchingBalance,
    refetch: refetchBalance
  } = useBalance({
    account,
    query: {
      refetchInterval: 5000,
      refetchOnWindowFocus: true
    }
  });
  const { wallet } = useWallet({ account });
  const isLoading = [isLoadingAccount, isLoadingBalance].some(Boolean);
  const isFetching = [isFetchingAccount, isFetchingBalance].some(Boolean);

  return {
    account,
    balance,
    currentConnector,
    isConnected,
    isConnecting,
    isLoading,
    isFetching,
    isLoadingConnectors,
    wallet,
    connect,
    disconnect,
    refetchBalance
  };
};
