export const fetcher = async (
  url: string,
  init?: RequestInit | undefined,
  shouldFormat = true
) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      ...init
    });
    const jsonRes = await response.json();
    return shouldFormat ? jsonRes?.data : jsonRes;
  } catch (error) {
    console.log('error', error);
  }
};

export const getRequest = async (
  url: string,
  init?: RequestInit | undefined
) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      ...init
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.error('error', error);
  }
};
