import useModal, { ModalCloseStatus } from '@/hooks/useModal';
import { useEffect } from 'react';
import BindFuelAddressContent from '../ModalContent/BindFuelAddressContent';
import BindFuelAddressSuccessContent from '../ModalContent/BindFuelAddressSuccessContent';
import MyFuelBoundAddressContent from '../ModalContent/MyFuelBoundAddressContent';

interface FuelModalsProps {
  initialView?: FuelModalKeys | '';
  onMyBoundAddressClose?: () => void;
  onBindFuelClose?: () => void;
  timestamp?: number;
}

export enum FuelModalKeys {
  MY_BOUND_ADDRESS = 'MY_BOUND_ADDRESS',
  BIND_FUEL = 'BIND_FUEL',
  BIND_SUCCESS = 'BIND_SUCCESS'
}

const FuelModals = ({ initialView, timestamp }: FuelModalsProps) => {
  const [
    BindFuelAddressSuccessModal,
    { onOpen: openBindFuelSuccess, onCancel: closeBindFuelSuccess }
  ] = useModal(BindFuelAddressSuccessContent, {
    title: 'Congratulations!',
    cancelCallback: status => {
      if (status === ModalCloseStatus.SUCCESS) {
        handleOpenMyBoundAddress();
      }
    }
  });

  const [
    BindFuelAddressModal,
    { onOpen: openBindFuel, onCancel: closeBindFuel }
  ] = useModal(BindFuelAddressContent, {
    title: 'Bind Fuel Address',
    cancelCallback: status => {
      if (status === ModalCloseStatus.SUCCESS) {
        handleOpenBindFuelSuccess();
      } else if (status === ModalCloseStatus.MY_BOUND_ADDRESSES) {
        handleOpenMyBoundAddress();
      }
    }
  });

  const [
    MyFuelBoundAddressModal,
    { onOpen: openMyBoundAddress, onCancel: closeMyBoundAddress }
  ] = useModal(MyFuelBoundAddressContent, {
    title: 'My Bound Addresses',
    cancelCallback: status => {
      if (status === ModalCloseStatus.SUCCESS) {
        handleOpenBindFuel();
      }
    }
  });

  const handleOpenBindFuelSuccess = () => {
    closeBindFuel();
    closeMyBoundAddress();
    openBindFuelSuccess();
  };

  const handleOpenBindFuel = () => {
    closeBindFuelSuccess();
    closeMyBoundAddress();
    openBindFuel();
  };

  const handleOpenMyBoundAddress = () => {
    closeBindFuelSuccess();
    closeBindFuel();
    openMyBoundAddress();
  };

  useEffect(() => {
    if (initialView) {
      switch (initialView) {
        case FuelModalKeys.BIND_FUEL:
          handleOpenBindFuel();
          break;
        case FuelModalKeys.MY_BOUND_ADDRESS:
          handleOpenMyBoundAddress();
          break;
      }
    }
  }, [initialView, timestamp]);

  return (
    <>
      {MyFuelBoundAddressModal}
      {BindFuelAddressModal}
      {BindFuelAddressSuccessModal}
    </>
  );
};

export default FuelModals;
