import useSWR from 'swr';
import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';
import useUserInfo from './useUserInfo';

export function useCheckInStatus() {
  const { userInfo } = useUserInfo();
  const checkInStatus = useSWR<ICheckStatusRes>(
    userInfo?.user_address
      ? [`${ENDPOINTS.checkInStatus}`, userInfo?.user_address]
      : null,
    ([url, address]: [string, string]): Promise<ICheckStatusRes> => {
      return fetcher(
        url,
        {
          method: 'POST'
        },
        false
      );
    },
    {
      revalidateOnFocus: false
    }
  );

  return { checkInStatus };
}
