import config from '@/config';
import useSWR from 'swr';
import useUserInfo from './useUserInfo';

const { SUBGRAPH_URL, mBTC, mETH, mUSD, mwBETH } = config;

export interface MTokenStakeResponse {
  account: string;
  id: string;
  mToken: string;
  stakedAmount: string;
}

function useGetMtokenStake() {
  const {
    userInfo: { user_address }
  } = useUserInfo();

  const state = useSWR(
    user_address && config ? ['/mtokenStakeList', user_address] : null,
    async () => {
      return mTokenStakes();
    },
    {
      revalidateOnFocus: false,
      refreshInterval: 5 * 60 * 1000
    }
  );

  const mTokenStakes = async () => {
    const res = await fetch(SUBGRAPH_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        query: `
            query {
              mtokenStakes(where: {
                account:  "${user_address}"
              }) {
                id
                account
                mToken
                stakedAmount
              }
            }
          `
      })
    });
    const json = await res?.json();
    const data: MTokenStakeResponse[] = json?.data?.mtokenStakes;

    const btcStake = (data || []).find(
      item => item?.mToken?.toLowerCase() == mBTC?.address?.toLowerCase()
    );
    const ethStake = (data || []).find(
      item => item?.mToken?.toLowerCase() == mETH?.address?.toLowerCase()
    );

    const musdStake = (data || []).find(
      item => item?.mToken?.toLowerCase() == mUSD?.address?.toLowerCase()
    );

    const mwbethStake = (data || []).find(
      item => item?.mToken?.toLowerCase() == mwBETH?.address?.toLowerCase()
    );

    // const filterNotEmpty = [btcStake, ethStake, musdStake];

    return [btcStake, ethStake, musdStake, mwbethStake];
  };

  return state;
}
export default useGetMtokenStake;
