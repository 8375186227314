import { useFuelWalletsList } from '@/hooks/useFuelWalletsList';
import { ModalCloseStatus, type IModalContentProps } from '@/hooks/useModal';
import classNames from 'classnames';
import FuelModalButton from '../FuelModalButton';
import { COMMON_STYLES } from '@/constants/fuelStyles';

export interface MyFuelBoundAddressContentProps {
  onCancel: (status: ModalCloseStatus) => void;
}

function MyFuelBoundAddressContent({
  onCancel
}: IModalContentProps<MyFuelBoundAddressContentProps>) {
  const { fuelWallets } = useFuelWalletsList();
  const addFuelAddressDisabled = fuelWallets?.length >= 3;

  const handleBindNowOrAddAddress = async () => {
    if (addFuelAddressDisabled) return;
    onCancel(ModalCloseStatus.SUCCESS);
  };

  return (
    <div className="fuel-modal" onClick={e => e.stopPropagation()}>
      {!fuelWallets?.length ? (
        <div className={`py-4 ${COMMON_STYLES.text}`}>
          You haven&apos;t bound your Fuel address yet
        </div>
      ) : (
        <div className={`py-4 flex flex-col gap-2 ${COMMON_STYLES.text}`}>
          {fuelWallets.map((address, index) => (
            <div key={index} className={COMMON_STYLES.flexRow}>
              <div className={COMMON_STYLES.textMuted}>
                Address #{index + 1}
              </div>
              <div
                className={`grow shrink basis-0 text-right break-all ${COMMON_STYLES.text}`}
              >
                {address}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={COMMON_STYLES.flexColumn}>
        <FuelModalButton
          onButtonClick={handleBindNowOrAddAddress}
          text={fuelWallets?.length ? 'Add Address' : 'Bind Now'}
          className={classNames(
            addFuelAddressDisabled ? 'opacity-50 cursor-not-allowed' : ''
          )}
        />
        <div className={COMMON_STYLES.textMuted}>
          *You can bind up to 3 Fuel Ignition addresses
        </div>
      </div>
    </div>
  );
}

const Content = (props: IModalContentProps<MyFuelBoundAddressContentProps>) => (
  <MyFuelBoundAddressContent {...props} />
);

export default Content;
