interface IProps {
  showWarning: boolean;
  onClose: () => void;
}
export default function BlockContent({ showWarning, onClose }: IProps) {
  return (
    <div className="max-md:w-[350px] md:w-[540px] bg-fourth -mx-6 -my-6 rounded-[16px] p-6">
      <div className="text-[16px] font-[500] text-[#010811] text-center">
        Manta CeDeFi IS NOT AVAILABLE IN YOUR LOCATION
      </div>
      <div className="text-[14px] text-black-title mt-4">
        cedefi.manta.network is unavailable in your country or region due to
        local regulations. We apologize for the inconvenience and appreciate
        your understanding.
      </div>
      {showWarning && (
        <div className="w-full flex justify-center mt-4">
          <button className="btn-primary w-full" onClick={onClose}>
            Confirm
          </button>
        </div>
      )}
    </div>
  );
}
