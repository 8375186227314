import { useCallback, useEffect, useState } from 'react';

export const useLocalStorage = (key: string, address: string) => {
  const getStorageData = (): Record<string, boolean> => {
    try {
      const storedData = localStorage.getItem(key);
      const parsedData = storedData ? JSON.parse(storedData) : {};
      return typeof parsedData === 'boolean' ? {} : parsedData;
    } catch {
      return {};
    }
  };

  const [value, setValue] = useState(() => {
    const data = getStorageData();
    return Boolean(data[address]);
  });

  const updateValue = useCallback(
    (newAddress: string, isValid = true) => {
      if (!newAddress) return;
      try {
        const data = getStorageData();
        data[newAddress] = isValid;
        localStorage.setItem(key, JSON.stringify(data));
        if (newAddress === address) setValue(isValid);
      } catch (error) {
        console.error('Error updating localStorage:', error);
      }
    },
    [address]
  );

  useEffect(() => {
    const data = getStorageData();
    setValue(Boolean(data[address]));
  }, [address]);

  return [value, updateValue] as const;
};
