import useSWR from 'swr';
import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';
import useUserInfo from './useUserInfo';

export function useMindInfo() {
  const {
    userInfo: { user_address }
  } = useUserInfo();

  const mindInfo = useSWR<IPointsResponse<IMindInfoData>>(
    user_address ? [ENDPOINTS.mindInfo, user_address] : null,
    ([url, address]) => {
      return fetcher(
        `${url}?address=${address}`,
        {
          method: 'GET'
        },
        false
      );
    },
    {
      revalidateOnFocus: false
    }
  );

  return mindInfo;
}
