import { useAccount, useSwitchChain } from 'wagmi';
import config from '@/config';

export const useCheckNetwork = () => {
  const { switchChainAsync } = useSwitchChain();
  const { chain } = useAccount();

  const isMantaPacific = chain?.id === config.MANTA_PACIFIC_CHAIN.id;

  const switchToMantaPacific = async () =>
    await switchChainAsync?.({ chainId: config.MANTA_PACIFIC_CHAIN.id });

  return { isMantaPacific, switchToMantaPacific };
};
