import ENDPOINTS from '@/constants/apiEndpoints';
import useSWR from 'swr';

interface FuelWallet {
  address: string;
  balance: string;
  addresses?: string[];
  partnerType?: string;
}

interface FuelWalletResponse {
  data: FuelWallet[];
}

export const useFuelWalletsList = () => {
  const { data, error, isLoading, mutate } = useSWR<FuelWalletResponse>(
    ENDPOINTS.fuelWallets,
    async () => {
      const response = await fetch(ENDPOINTS.fuelWallets, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('get fuel wallets failed');
      }
      return response.json();
    }
  );
  const fuelWallets = Array.isArray(data?.data)
    ? data?.data?.filter(
        (item: FuelWallet) => item?.partnerType === 'FUEL'
      )?.[0]?.addresses || []
    : [];

  return {
    fuelWallets,
    isLoading,
    isError: error,
    mutate
  };
};
