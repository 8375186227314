import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';
import useSWR from 'swr';

type Result = {
  data: {
    'binance-bitcoin': number;
    bitcoin: number;
    ethereum: number;
    'manta-network': number;
    'stakestone-ether': number;
    tether: number;
    'usd-coin': number;
    'wrapped-bitcoin': number;
    'wrapped-usdm': number;
    'wrapped-beacon-eth': number;
    mBTC: number;
    mETH: number;
    mUSD: number;
    mwBETH: number;
  };
};
const useUsdPrices = () => {
  const { data } = useSWR<Result>(ENDPOINTS.usdPrices, (url: string) => {
    return fetcher(url, { method: 'GET' }, false);
  });
  const ret = data?.data;
  return {
    BTCB: ret?.['binance-bitcoin'] ?? 0,
    BTC: ret?.bitcoin,
    ETH: ret?.ethereum,
    MANTA: ret?.['manta-network'],
    STONE: ret?.['stakestone-ether'],
    USDT: ret?.tether,
    USDC: ret?.['usd-coin'],
    wBTC: ret?.['wrapped-bitcoin'], // using in testnet
    WBTC: ret?.['wrapped-bitcoin'],
    wUSDM: ret?.['wrapped-usdm'] ?? 1,
    mBTC: ret?.mBTC ?? 0,
    mETH: ret?.mETH ?? 0,
    mUSD: ret?.mUSD ?? 0,
    mwBETH: ret?.['wrapped-beacon-eth']
  } as Record<string, number>;
};
export default useUsdPrices;
