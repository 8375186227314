import { ModalCloseStatus, type IModalContentProps } from '@/hooks/useModal';
import FuelModalButton from '../FuelModalButton';
import { COMMON_STYLES } from '@/constants/fuelStyles';
import classNames from 'classnames';
import { useFuelWalletsList } from '@/hooks/useFuelWalletsList';

export interface BindFuelAddressSuccessContentProps {
  onCancel: (status: ModalCloseStatus) => void;
}

function BindFuelAddressSuccessContent({
  onCancel
}: IModalContentProps<BindFuelAddressSuccessContentProps>) {
  const { mutate } = useFuelWalletsList();
  const handleBindAddress = async () => {
    await mutate();
    onCancel(ModalCloseStatus.SUCCESS);
  };

  return (
    <div className="fuel-modal" onClick={e => e.stopPropagation()}>
      <div className={classNames(COMMON_STYLES.text, 'mb-4')}>
        Binding successful.
      </div>
      <FuelModalButton
        onButtonClick={handleBindAddress}
        text="My Bound Addresses"
      />
    </div>
  );
}

const Content = (
  props: IModalContentProps<BindFuelAddressSuccessContentProps>
) => <BindFuelAddressSuccessContent {...props} />;

export default Content;
