import { useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import numbro from 'numbro';
import boxBg from '@/assets/points/box-modal-bg.png';
import LoadingIcon from '@/components/Loading';
import useTVL from '@/store/useTVL';
import useInviteInfo from '@/store/useInviteInfo';
import ProcessBar from '@/components/CheckInProcessBar';
import { useCheckInStatus } from '@/store/useCheckInStatus';
import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';
import useUserInfo from '@/store/useUserInfo';
import { usePointsStatus } from '@/store/usePointsStatus';
import { shareToTwitter } from '@/utils/shareTwitter';
import { useUserRank } from '@/store/useUserRank';
import type { IModalContentProps } from '@/hooks/useModal';

export interface IPointsBoxContent {
  boxData?: IPointsBoxData;
  onConfirm: () => void;
  twitterAuthProcess: () => void;
}

function PointsBoxContent({ data }: IModalContentProps<IPointsBoxContent>) {
  const { userInfo } = useUserInfo();
  const { user_twitter } = userInfo;
  const tvlStatus = useTVL();
  const { data: tvlData } = tvlStatus;
  const { data: inviteData } = useInviteInfo();
  const inviteCodeList = inviteData?.invite_code_list;
  const [isLoading, setIsLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const showHistoryPoints = data?.boxData?.hasOpened;
  const pointsDisplay = numbro((data.boxData?.points || 0)?.toString()).format({
    thousandSeparated: true
  });
  const boostPointsDisplay = numbro(
    (data.boxData?.boost?.data?.score || 0)?.toString()
  ).format({
    thousandSeparated: true
  });
  const totalPointsDisplay = numbro(
    (
      (parseInt(`${data.boxData?.points}`) || 0) +
      (parseInt(`${data.boxData?.boost?.data?.score}`) || 0)
    )?.toString()
  ).format({
    thousandSeparated: true
  });
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  const normaInviteCodes = ((inviteCodeList || []) as InviteCodeItem[])
    .filter(item => item.max_available_times === 1)
    .map(item => item.invite_code)
    .slice(0, 5)
    .join(', ');

  const permanentInviteCode = ((inviteCodeList || []) as InviteCodeItem[]).find(
    item => item.max_available_times === -1
  )?.invite_code;
  const { checkInStatus } = useCheckInStatus();
  const { pointStatus } = usePointsStatus();
  const { userRankStatus } = useUserRank();
  const {
    data: checkInDataRes,
    mutate: checkInStatusMutate,
    isLoading: CheckInStatusLoading
  } = checkInStatus;
  const tokenList = tvlData?.tokenList;
  const pointsText = `I have just earned ${totalPointsDisplay} Points on Manta CeDeFi! \nJoin Manta CeDefi now to utilize your assets and earn more in Cefi, Defi, and Manta Tokens simultaneously. #mantacedefi`;
  const inviteCodeText =
    permanentInviteCode || normaInviteCodes
      ? `\n\nUse Invite Code: ${permanentInviteCode || normaInviteCodes}`
      : '';
  const shareText = pointsText + inviteCodeText;
  const shareOnTwitter = () => {
    if (!user_twitter) {
      data.twitterAuthProcess();
      return;
    }

    shareToTwitter({
      url: 'https://cedefi.manta.network',
      text: shareText
    });

    setIsLoading(true);
    const timeoutId = setTimeout(() => {
      checkIn();
    }, 20000);

    timeoutRef.current = timeoutId;
  };
  const checkIn = async () => {
    try {
      setIsLoading(true);
      const res = await fetcher(
        ENDPOINTS.checkIn,
        {
          method: 'POST'
        },
        false
      );
      if (res?.success === false) {
        notification.error({
          message: res?.message || 'Check-In Failed'
        });
      } else {
        notification.success({
          message: 'Check-In Success'
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      checkInStatusMutate();
      pointStatus.mutate();
      userRankStatus.mutate();
    }
  };
  const sharedBtnPoints =
    checkInDataRes?.reward_points_list[(checkInDataRes?.days_checked || 1) - 1];

  return (
    <div className="flex text-[14px] flex-col mt-4 gap-4 items-center leading-normal  text-black-title">
      <img className="max-md:hidden" src={boxBg} width={386} height={204} />
      {!showHistoryPoints && (
        <div className="flex flex-col justify-center items-center gap-1">
          <div className="text-[24px] font-[500]">+{pointsDisplay} Points</div>
          {boostPointsDisplay !== '0' && (
            <div className="text-[24px] font-[500] gradient-text-5">
              +{boostPointsDisplay} Boost Points
            </div>
          )}
        </div>
      )}
      <div className="text-[14px] self-start">
        Join Manta CeDefi now to utilize your assets and earn more in Cefi,
        Defi, and Manta Tokens simultaneously.
      </div>
      {tokenList && (
        <div className="w-full bg-green/10 gap-1 p-2 rounded-[8px] flex-col items-start font-[14px]">
          <div>{`Today's Estimated APR`}</div>
          {Object.entries(tokenList).map(([key, value]) => {
            return (
              <div
                key={key}
                className="flex w-full justify-between items-center"
              >
                <div>{key}</div>
                <div>{value.apr}</div>
              </div>
            );
          })}
        </div>
      )}
      {!checkInDataRes?.today_checked && (
        <div className="text-[14px] self-start">
          You can also earn extra points by sharing this on X!
        </div>
      )}
      <ProcessBar data={checkInDataRes} showPoints={true} />
      <div className="self-start whitespace-pre-wrap leading-normal rounded-[8px] border bg-gray-5 p-2 border-black-title/30">
        {shareText}
      </div>
      <div className="flex flex-col gap-2 w-full">
        <button
          onClick={() => {
            shareOnTwitter();
          }}
          disabled={
            isLoading || CheckInStatusLoading || checkInDataRes?.today_checked
          }
          className="btn-primary w-full"
        >
          {isLoading || CheckInStatusLoading ? (
            <LoadingIcon />
          ) : checkInDataRes?.today_checked ? (
            `Post on X  +${sharedBtnPoints} Points`
          ) : (
            'Post on X'
          )}
        </button>
        <button
          disabled={isLoading}
          onClick={() => {
            data.onConfirm();
          }}
          className="btn-reverse w-full"
        >
          {isLoading ? <LoadingIcon isDark={true} /> : 'Confirm'}
        </button>
      </div>
    </div>
  );
}

const Content = (props: IModalContentProps<IPointsBoxContent>) => (
  <PointsBoxContent {...props} />
);

export default Content;
