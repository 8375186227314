import {
  useConnectUI,
  useCurrentConnector,
  useIsConnected,
  useWallet
} from '@fuels/react';
import { hasSignMessageCustomCurve } from 'fuels-connectors-without-wagmi';
import { useCallback, useState } from 'react';
import { useLoading } from './useLoading';

interface SignMessageResult {
  signMessage: (message: string) => Promise<string | void>;
  isLoading: boolean;
  error: string | null;
  signedMessage: string;
}

export function useFuelSignMessage(): SignMessageResult {
  const { isConnected } = useIsConnected();
  const { wallet } = useWallet();
  const { connect } = useConnectUI();
  const { currentConnector } = useCurrentConnector();
  const [signedMessage, setSignedMessage] = useState<string>('');

  const handleSignMessage = useCallback(
    async (message: string): Promise<string | void> => {
      if (!isConnected) {
        // @ts-ignore
        await connect(undefined);
        return;
      }

      if (!wallet) {
        throw new Error('Wallet not connected');
      }

      try {
        const hasCustomCurve = hasSignMessageCustomCurve(currentConnector);

        if (hasCustomCurve) {
          const { signature } = await currentConnector.signMessageCustomCurve(
            message
          );
          setSignedMessage(signature);
          return signature;
        }

        const signature = await wallet.signMessage(message);
        setSignedMessage(signature);
        return signature;
      } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'sign failed');
      }
    },
    [isConnected, connect, wallet]
  );

  const [signMessage, isLoading, error] = useLoading(handleSignMessage, [
    wallet
  ]);

  return {
    signMessage: (message: string) => signMessage(message),
    isLoading,
    error: error || null,
    signedMessage
  };
}
