import { createWeb3Modal } from '@web3modal/wagmi/react';
import ReactDOM from 'react-dom/client';

import { FuelProvider } from '@fuels/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { ConfigProvider, notification } from 'antd';
import {
  FuelWalletConnector,
  FueletWalletConnector
} from 'fuels-connectors-without-wagmi';
import { ClientContext, GraphQLClient } from 'graphql-hooks';
import { WagmiProvider, http } from 'wagmi';
import { Chain, bsc, bscTestnet, mainnet, sepolia } from 'wagmi/chains';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './antd.css';
import AppRouter from './AppRouter';
import config from './config';
import { theme } from './constants/antdTheme';
import { ContractProvider } from './contexts/ContractsContext';
import './index.css';
import { updateRootFontSize } from './utils/responsive';

const projectId = config.WALLET_CONNECT_PROJECT_ID;
const graphql = config.SUB_GRAPHQL_URL;
const queryClient = new QueryClient();

const client = new GraphQLClient({
  url: graphql
});

const metadata = {
  name: 'Manta CeDeFi',
  description: 'Manta CeDeFi',
  url: 'https://cedefi.manta.network',
  icons: ['https://renewparadigm.manta.network/favicon.ico']
};
const ethChain = config.IS_TESTNET ? sepolia : mainnet;
const bscChain = config.IS_TESTNET ? bscTestnet : bsc;
const chains = [
  ethChain,
  config.MANTA_PACIFIC_CHAIN as Chain,
  bscChain
] as const;
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableWalletConnect: true,
  transports: {
    [ethChain.id]: http(),
    [config.MANTA_PACIFIC_CHAIN.id]: http(),
    [bscChain.id]: http()
  },
  auth: {
    email: false
  }
});

// 3. Create modal
createWeb3Modal({
  themeMode: 'light',
  themeVariables: {
    '--w3m-font-family': 'Tomorrow'
  },
  wagmiConfig,
  projectId,
  featuredWalletIds: [
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f',
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
    '1aedbcfc1f31aade56ca34c38b0a1607b41cccfa3de93c946ef3b4ba2dfab11c',
    '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
    'ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef'
  ],
  includeWalletIds: [
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f',
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
    '1aedbcfc1f31aade56ca34c38b0a1607b41cccfa3de93c946ef3b4ba2dfab11c',
    '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
    'ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef'
  ]
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

notification.config({
  closeIcon: null,
  prefixCls: 'paradigm'
});

updateRootFontSize(); // with pxtorem

root.render(
  <WagmiProvider config={wagmiConfig}>
    <QueryClientProvider client={queryClient}>
      <FuelProvider
        uiConfig={{
          suggestBridge: false
        }}
        fuelConfig={{
          connectors: [new FuelWalletConnector(), new FueletWalletConnector()]
        }}
      >
        <ContractProvider>
          <ConfigProvider theme={theme}>
            <ClientContext.Provider value={client}>
              <AppRouter />
            </ClientContext.Provider>
          </ConfigProvider>
        </ContractProvider>
      </FuelProvider>
    </QueryClientProvider>
  </WagmiProvider>
);
