import depositManagerAbi from '@/abis/DepositManager.json';
import managerAdapterAbi from '@/abis/managerAdapter.json';
import routerAbi from '@/abis/Router.json';
import config from '@/config';
import { useEthersSigner } from '@/hooks/useEthersSigner';
import useTVL from '@/store/useTVL';
import dayjs from '@/utils/dayjsWithExtends';
import { Popover } from 'antd';
import { BigNumber, Contract, ethers } from 'ethers';
import numbro from 'numbro';
import { useEffect, useMemo, useState } from 'react';
import { Chain, parseUnits } from 'viem';
import { useAccount, useFeeData } from 'wagmi';
import StakeButton from '../StakeButton';
import { StakeFrom } from '../StakeFrom';
import { StakeTo } from '../StakeTo';

export type StakeChain = Chain & {
  value: number;
  label: string;
};

export type StakeMToken = {
  tokenContract: Contract;
  name: string;
  address: string;
  decimals: number;
};
export type StakeToken = {
  mToken: StakeMToken;
  address: string; // stake token's address
  decimals: number; // token's decimals
  stakePaused: boolean; // whether stake is paused
  minDepositAmount: BigNumber; // min amount to deposit
  name: string;
  value: string;
  label: string;
  isNativeToken: boolean;
  tokenContract: Contract;
  fee?: BigNumber; // just used for manta pacific chain
};

const { stakeContractMap, MANTA_PACIFIC_CHAIN } = config;

const btcTokenList = ['wBTC', 'WBTC', 'BTCB'];
const ethTokenList = ['STONE', 'ETH', 'wBETH'];
const usdTokenList = ['USDC', 'USDT', 'wUSDM'];

const AprPopoverContent = ({ time }: { time: number }) => {
  const formattedTime = dayjs(time * 1000)
    .utc()
    .format('MMM Do YYYY');

  return (
    <div className="text-sm bg-[#1D314C99] text-white p-2 rounded-[4px] max-w-[312px]">
      <div className="flex items-center justify-between my-4">
        Annualized returns derived from the asset management companies&apos;
        performance over the last 30 days. This figure does not assure or
        predict future outcomes.
      </div>
      <div>Last Updated: {formattedTime}</div>
    </div>
  );
};

function StakeContent() {
  const [selectedChain, setSelectedChain] = useState<StakeChain | null>(null);
  const [amount, setAmount] = useState('');
  const [balance, setBalance] = useState('');
  const [selectedToken, setSelectedToken] = useState<StakeToken | null>(null);
  const [layerZeroGasFee, setLayerZeroGasFee] = useState(BigNumber.from(0));
  const [estimatedGasFee, setEstimatedGasFee] = useState(BigNumber.from(0));

  const { address } = useAccount();

  const { data } = useTVL();

  const { data: feeData } = useFeeData();
  const signer = useEthersSigner();

  const isMantaPacificChain = useMemo(() => {
    return selectedChain?.id === MANTA_PACIFIC_CHAIN.id;
  }, [selectedChain?.id]);

  const [managerContract, routerContract] = useMemo(() => {
    if (!selectedChain) {
      return [null, null];
    }
    const rpcUrl = selectedChain?.rpcUrls?.default?.http?.[0];
    if (!rpcUrl) {
      return [null, null];
    }
    const contractAddress =
      stakeContractMap?.[selectedChain?.id]?.managerContract;
    const routerAddress = stakeContractMap?.[selectedChain?.id]?.routerContract;

    if (!contractAddress || !routerAddress) {
      return [null, null];
    }
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
    const routerContract = new Contract(routerAddress, routerAbi, provider);
    if (selectedChain?.id === MANTA_PACIFIC_CHAIN.id) {
      return [
        new Contract(contractAddress, depositManagerAbi, provider),
        routerContract
      ];
    } else {
      return [
        new Contract(contractAddress, managerAdapterAbi, provider),
        routerContract
      ];
    }
  }, [selectedChain]);

  const [aprValue, aprTimestamp] = useMemo(() => {
    const name = selectedToken?.name ?? '';
    if (btcTokenList.includes(name)) {
      return [data?.tokenList?.BTC?.apr, data?.tokenList?.BTC?.timestamp];
    } else if (ethTokenList.includes(name)) {
      return [data?.tokenList?.ETH?.apr, data?.tokenList?.ETH?.timestamp];
    } else if (usdTokenList.includes(name)) {
      return [data?.tokenList?.USD?.apr, data?.tokenList?.USD?.timestamp];
    }
    return '';
  }, [data?.tokenList, selectedToken?.name]);

  useEffect(() => {
    const getLayerZeroGasFee = async () => {
      if (isMantaPacificChain || !selectedToken) {
        setLayerZeroGasFee(BigNumber.from(0));
        return;
      }
      const [gasFee] =
        (await managerContract?.quote(
          selectedToken?.mToken?.address,
          selectedToken?.address,
          parseUnits('0.000001', selectedToken?.decimals ?? 0),
          address
        )) ?? [];

      setLayerZeroGasFee(gasFee);
    };
    getLayerZeroGasFee();
  }, [isMantaPacificChain, managerContract, selectedToken, address]);

  useEffect(() => {
    const getEstimateGas = async () => {
      if (!managerContract || !signer || !selectedToken) {
        return null;
      }
      let gasAmount: BigNumber | undefined = BigNumber.from(0);
      const mockAmount = selectedToken?.minDepositAmount ?? BigNumber.from(0);

      try {
        if (!isMantaPacificChain) {
          const params = {
            value: selectedToken?.isNativeToken
              ? mockAmount.add(layerZeroGasFee)
              : layerZeroGasFee
          };
          gasAmount = await managerContract
            ?.connect(signer)
            ?.estimateGas?.stake?.(
              selectedToken?.mToken?.address,
              selectedToken?.address,
              mockAmount,
              address,
              address,
              params
            );
        } else {
          const params = {
            value: selectedToken?.isNativeToken
              ? selectedToken?.fee?.add(mockAmount)
              : selectedToken?.fee
          };
          gasAmount = await managerContract
            ?.connect(signer)
            ?.estimateGas?.deposit?.(
              selectedToken?.address,
              mockAmount,
              address,
              params
            );
        }
        const gasPrice = feeData?.gasPrice;
        const gasFeeInWei =
          gasPrice && gasAmount
            ? gasAmount?.mul(gasPrice)
            : BigNumber.from('0');

        setEstimatedGasFee(gasFeeInWei);
      } catch (e) {
        // console.log('estimate gas error: ', e);
      }
    };
    getEstimateGas();
  }, [
    address,
    feeData?.gasPrice,
    isMantaPacificChain,
    layerZeroGasFee,
    managerContract,
    selectedToken,
    signer
  ]);

  return (
    <>
      <div className="flex w-full gap-4 mb-4 text-sm bg-white/80 text-black-title max-md:flex-col">
        <div className="flex flex-1 flex-col items-center justify-center border border-green/60 rounded-lg py-3 max-md:flex-row max-md:justify-between max-md:py-4 max-md:px-6">
          <span>TVL</span>
          <span className="text-2xl mt-2">
            $
            {numbro(data?.tvl ?? 0).format({
              average: true,
              thousandSeparated: true,
              mantissa: 2
            })}
          </span>
        </div>
        <Popover
          overlayInnerStyle={{
            padding: '0',
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }}
          arrow={false}
          placement="bottomRight"
          trigger="hover"
          content={() => <AprPopoverContent time={Number(aprTimestamp ?? 0)} />}
        >
          <div className="cursor-pointer flex flex-1 flex-col items-center justify-center border border-green/60 rounded-lg py-3 max-md:flex-row max-md:justify-between max-md:py-4 max-md:px-6">
            <span>Estimated {selectedToken?.name} CeFi APR</span>
            <span className="text-2xl mt-2">{aprValue}</span>
          </div>
        </Popover>
      </div>
      <div className="border border-green/60 bg-white/80 rounded-2xl p-4 w-full text-primary-black/80">
        <StakeFrom
          selectedChain={selectedChain}
          setSelectedChain={setSelectedChain}
          amount={amount}
          setAmount={setAmount}
          isMantaPacificChain={isMantaPacificChain}
          managerContract={managerContract}
          setBalance={setBalance}
          balance={balance}
          selectedToken={selectedToken}
          setSelectedToken={setSelectedToken}
          layerZeroGasFee={layerZeroGasFee}
          estimatedGasFee={estimatedGasFee}
        />
        <StakeTo
          selectedToken={selectedToken}
          amount={amount}
          isMantaPacificChain={isMantaPacificChain}
          estimatedGasFee={estimatedGasFee}
          layerZeroGasFee={layerZeroGasFee}
        />
        <StakeButton
          selectedChain={selectedChain as Chain}
          balance={balance}
          amount={amount}
          selectedToken={selectedToken}
          managerContract={managerContract}
          isMantaPacificChain={isMantaPacificChain}
          layerZeroGasFee={layerZeroGasFee}
          routerContract={routerContract}
        />
      </div>
    </>
  );
}
export default StakeContent;
