export const SidebarData = [
  {
    title: 'Manta CeDeFi',
    path: '/'
  },
  {
    title: 'Refer Friends',
    path: '/dashboard/invites'
  },
  {
    title: 'Deposit',
    path: '/dashboard/stake'
  },
  {
    title: 'Stake M-Tokens',
    path: '/dashboard/m_token'
  },
  {
    title: 'Points',
    path: '/dashboard/points'
  },
  {
    title: 'Earn More',
    path: '/dashboard/earn_more'
  }
];
