import numbro from 'numbro';
import {
  ArrowIcon,
  FRENZY_BONUS,
  ProgressBarProps,
  TextWrapper
} from './TeamContribution';

const ProgressBar = ({ value, middle, max, token }: ProgressBarProps) => {
  const thousandSeparated = (num: number) =>
    `${numbro(num ?? 0).format({
      thousandSeparated: true
    })} ${token}`;

  let width = 0;
  if (value === 0) {
    width = 0;
  } else if (value > 0 && value < 1000) {
    width = 2;
  } else if (value >= 1000 && value < 5000) {
    width = 8;
  } else if (value >= 5000 && value < 10000) {
    width = 28;
  } else if (value >= 10000 && value < 20000) {
    width = 42;
  } else if (value >= 20000 && value < 50000) {
    width = 55;
  } else if (value >= 50000 && value < 100000) {
    width = 76;
  } else if (value >= 100000) {
    width = 100;
  }

  // 0 2 8 28 42 55 72 100
  const Step0 = width === 0;
  const Step1 = width === 2;
  const Step2 = width >= 8 && width < 28;
  const Step3 = width >= 28 && width < 42;
  const Step4 = width >= 42 && width < 55;
  const Step5 = width >= 55 && width < 72;
  const Step6 = width >= 72 && width < 100;

  const barStyle = {
    width: `${width}%`,
    height: '8px',
    borderRadius: '20px',
    background: 'linear-gradient(89deg, #3FFFDC 0%, #FF96EE 100%)'
  };

  const frenzyStyle = {
    background: 'linear-gradient(89.93deg, #56EDB6 -7.25%, #CB63F9 99.94%)',
    webkitBackgroundClip: 'text',
    webkitTextFillColor: 'transparent',
    fontStyle: 'italic'
  };

  return (
    <div>
      <div className=" bg-green-1 h-[8px] rounded-[20px]">
        <div className="bar" style={barStyle}></div>
      </div>
      <div className="h-4"></div>
      <div className="flex flex-row justify-between items-center">
        <TextWrapper isActive={width > 0}>
          <div className="ml-[38px] mr-[10px]">
            <ArrowIcon />
          </div>
          <div className="flex flex-col">
            <span className="min-h-[18px] font-normal text-black-title">
              {Step0 || Step1 ? 'Next Milestone' : null}
            </span>
            <span className="text-base font-medium">
              {thousandSeparated(1000)}
            </span>
            <span style={frenzyStyle}>{FRENZY_BONUS[1000].points}</span>
            <span className="leading-3">{FRENZY_BONUS[1000].bonus}</span>
          </div>
        </TextWrapper>
        <div className="ml-[25px]">
          <TextWrapper isActive={width > 2}>
            <div className="mr-[10px]">
              <ArrowIcon />
            </div>
            <div className="flex flex-col">
              <span className="min-h-[18px] font-normal text-black-title">
                {Step2 ? 'Next Milestone' : null}
              </span>
              <span className="text-base font-medium">
                {thousandSeparated(5000)}
              </span>
              <span style={frenzyStyle}>{FRENZY_BONUS[5000].points}</span>
              <span className="leading-3">{FRENZY_BONUS[5000].bonus}</span>
            </div>
          </TextWrapper>
        </div>

        <TextWrapper isActive={width > 8}>
          <div className="mr-[10px]">
            <ArrowIcon />
          </div>
          <div className="flex flex-col">
            <span className="min-h-[18px] font-normal text-black-title">
              {Step3 ? 'Next Milestone' : null}
            </span>
            <span className="text-base font-medium">
              {thousandSeparated(10000)}
            </span>
            <span style={frenzyStyle}>{FRENZY_BONUS[10000].points}</span>
            <span className="leading-3">{FRENZY_BONUS[10000].bonus}</span>
          </div>
        </TextWrapper>

        <TextWrapper isActive={width > 28}>
          <div className="mr-[10px]">
            <ArrowIcon />
          </div>
          <div className="flex flex-col">
            <span className="min-h-[18px] font-normal text-black-title">
              {Step4 ? 'Next Milestone' : null}
            </span>
            <span className="text-base font-medium">
              {thousandSeparated(20000)}
            </span>
            <span style={frenzyStyle}>{FRENZY_BONUS[20000].points}</span>
            <span className="leading-3">{FRENZY_BONUS[20000].bonus}</span>
          </div>
        </TextWrapper>

        <TextWrapper isActive={width > 42}>
          <div className="mr-[10px]">
            <ArrowIcon />
          </div>
          <div className="flex flex-col">
            <span className="min-h-[18px] font-normal text-black-title">
              {Step5 ? 'Next Milestone' : null}
            </span>
            <span className="text-base font-medium">
              {thousandSeparated(50000)}
            </span>
            <span style={frenzyStyle}>{FRENZY_BONUS[50000].points}</span>
            <span className="leading-3">{FRENZY_BONUS[50000].bonus}</span>
          </div>
        </TextWrapper>

        <TextWrapper isActive={width > 55}>
          <div className="flex flex-col">
            <span className="min-h-[18px] font-normal text-black-title">
              {Step6 ? 'Next Milestone' : null}
            </span>
            <span className="text-base font-medium">
              {thousandSeparated(100000)}
            </span>
            <span style={frenzyStyle}>{FRENZY_BONUS[100000].points}</span>
            <span className="leading-3">{FRENZY_BONUS[100000].bonus}</span>
          </div>
          <div className="ml-4">
            <ArrowIcon />
          </div>
        </TextWrapper>
      </div>
    </div>
  );
};

export default ProgressBar;
