import React from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import useSWR from 'swr';
import { getRequest } from '@/utils/request';
import ENDPOINTS from '@/constants/apiEndpoints';

interface RankRowProps extends React.HTMLAttributes<HTMLDivElement> {
  item: ILeaderboardItem;
}

const RankRow = ({ item, ...rest }: RankRowProps) => {
  const { rank, name, inviter, score } = item;
  return (
    <div {...rest}>
      <div className="flex items-center justify-between px-4 h-[41px] rounded-[4px] text-[14px]">
        <div className="text-center w-[7.5%]">{rank}</div>
        <div className="w-[30.8%] text-center overflow-hidden break-words">
          {name}
        </div>
        <div className="w-[30.8%] text-center overflow-hidden break-words">
          {inviter ? inviter : '-'}
        </div>
        <div className="w-[30.8%] text-center overflow-hidden break-words">
          {score}
        </div>
      </div>
    </div>
  );
};

const Row = ({ index, style, data }: ListChildComponentProps) => {
  const item = data.list[index];
  return <RankRow item={item} style={style} className="mb-[4px]" />;
};

const Header = () => {
  return (
    <div className="flex items-center justify-between px-4 bg-green-bg-2 rounded-[4px] h-[41px] text-[#1D341C]/60 text-[14px]">
      <div className="text-center w-[7.5%]">RANK</div>
      <div className="text-center w-[30.8%]">NAME</div>
      <div className="text-center w-[30.8%]">INVITE BY</div>
      <div className="text-center w-[30.8%]">POINTS</div>
    </div>
  );
};

const CurrentUserRank = ({ currentUserRankInfo }: RankProps) => {
  if (!currentUserRankInfo?.data || currentUserRankInfo?.data?.rank <= 0) {
    return null;
  }

  return <RankRow item={currentUserRankInfo.data} />;
};

const RankList = () => {
  const { data } = useSWR(ENDPOINTS.leaderboard, getRequest);

  if (!data?.data) {
    return null;
  }
  const list = data?.data || [];

  return (
    <List
      className="customScroll mt-1"
      style={{
        overflowX: 'hidden',
        WebkitOverflowScrolling: 'touch',
        scrollbarWidth: 'none'
      }}
      itemData={{ list }}
      itemCount={list.length}
      height={360}
      itemSize={45}
      width="100%"
    >
      {Row}
    </List>
  );
};

interface RankProps {
  currentUserRankInfo?: IUserRankRes;
}

const LeaderboardList = ({ currentUserRankInfo }: RankProps) => {
  return (
    <div
      className="md:min-w-[900px] max-md:w-full"
      style={{ overflowX: 'auto' }}
    >
      <div className="max-md:min-w-[900px]">
        <Header />
        <div
          className="mt-2 rounded-lg"
          style={{
            background:
              'linear-gradient(90deg, rgba(86, 237, 182, 0.30) 47.08%, rgba(203, 99, 249, 0.30) 100%)'
          }}
        >
          <CurrentUserRank currentUserRankInfo={currentUserRankInfo} />
        </div>
        <RankList />
        <div className="max-md:h-5" />
      </div>
    </div>
  );
};

export default LeaderboardList;
