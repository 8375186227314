import React from 'react';
import classNames from 'classnames';
import { useResponsive } from '@/hooks/useResonposive';

interface ProgressBarProps {
  data?: ICheckStatusRes;
  showPoints?: boolean;
}

const TextWrapper = ({
  children,
  isActive
}: {
  children: React.ReactNode;
  isActive: boolean;
}) => {
  return (
    <div
      className={classNames(
        'flex flex-row justify-center text-sm text-black-title w-[14.28%]',
        {
          'opacity-70': !isActive
        }
      )}
    >
      {children}
    </div>
  );
};

const ProgressBar = ({ data, showPoints = false }: ProgressBarProps) => {
  const daysChecked = data?.days_checked || 0;
  const { isMobile } = useResponsive();
  const width = [0, 15, 29, 43, 57, 72, 86, 100][Math.min(daysChecked, 7)];

  const days = (data?.reward_points_list || []).map((item, index) => {
    if (isMobile) {
      return { label: `Day\n ${index + 1}`, points: item };
    }

    return { label: `Day ${index + 1}`, points: item };
  });

  const barStyle = {
    width: `${width}%`,
    height: '8px',
    borderRadius: '20px',
    background: 'linear-gradient(89deg, #3FFFDC 0%, #FF96EE 100%)'
  };

  return (
    <div className="w-full">
      <div className=" bg-green-1 h-[8px] rounded-[20px]">
        <div className="bar" style={barStyle}></div>
      </div>
      <div className="h-[12px]"></div>
      <div className="flex flex-row justify-around items-center">
        {days.map((day, index) => {
          return (
            <TextWrapper key={index} isActive={daysChecked > index}>
              <div className="flex flex-col items-center">
                <div className="whitespace-pre-wrap">{day.label}</div>
                {showPoints && <div className="max-md:mt-1">+{day.points}</div>}
              </div>
            </TextWrapper>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
