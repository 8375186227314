import { useState } from 'react';
import { Modal } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import useUserInfo from '@/store/useUserInfo';

interface Props {
  onChange: (captcha: any) => void;
}
interface IUseTwitterAuthCaptcha {
  openCaptcha: () => void;
  user_twitter?: string;
  AuthModal: React.JSX.Element;
  closeCaptcha: () => void;
}
export default function useTwitterAuthCaptcha(
  data: Props
): IUseTwitterAuthCaptcha {
  const { userInfo } = useUserInfo();
  const { user_twitter } = userInfo;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openCaptcha = () => {
    setIsModalOpen(true);
  };
  const closeCaptcha = () => {
    setIsModalOpen(false);
  };
  const AuthModal = (
    <Modal
      open={isModalOpen}
      footer={null}
      width="400px"
      onCancel={closeCaptcha}
    >
      <ReCAPTCHA
        sitekey="6LcQmjspAAAAAH07niZGMWnggKAQyv-G1YJ8ohsT"
        onChange={data.onChange}
      />
    </Modal>
  );
  return {
    AuthModal,
    user_twitter,
    openCaptcha,
    closeCaptcha
  };
}
