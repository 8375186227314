import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useState, useEffect } from 'react';
import VerificationInput from 'react-verification-input';
import { isMobile } from 'react-device-detect';
import LoadingIcon from '@/components/Loading';
import ENDPOINTS from '@/constants/apiEndpoints';
import useInviteCode from '@/store/useInviteCode';
import BaseModal from '../BaseModal';

const CODE_LENGTH = 5;

function ConnectWallet({ bare }: { bare?: boolean }) {
  const { open } = useWeb3Modal();
  const [inviteCode, setInviteCode] = useState('');
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [inviteCodeVerified, setInviteCodeVerified] = useState(false);
  const [inviteCodeVefifiedFailed, setInviteCodeVefifiedFailed] =
    useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { setInviteCode: _setInviteCodeStore } = useInviteCode();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('inviteCode') || undefined;
    if (code) {
      setModalOpen(true);
      setInviteCode(code);
      if (code.length === CODE_LENGTH) {
        setIsBtnDisabled(false);
      }
    }
  }, []);

  const onCancel = () => {
    setModalOpen(false);
  };

  const handleCodeChange = (value: string) => {
    setInviteCode(value);
    setInviteCodeVefifiedFailed(false);
    if (value.length === CODE_LENGTH) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  };

  const redeem = async () => {
    setIsLoading(true);
    setIsBtnDisabled(true);
    try {
      const response = await fetch(ENDPOINTS.checkInviteCode, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inviteCode: inviteCode })
      });
      const res = await response.json();
      if (res.success) {
        setModalOpen(false);
        setInviteCodeVerified(true);
        _setInviteCodeStore(inviteCode);
      } else {
        console.error('redeem error1', res);
        setInviteCodeVefifiedFailed(true);
        // notification.error({
        //   message: 'Invalid Invite Code. Try Another one.'
        // });
      }
      setIsLoading(false);
    } catch (e: any) {
      console.log('redeem error', e.message);
    } finally {
      setIsLoading(false);
      setIsBtnDisabled(false);
    }
  };

  if (bare) {
    return (
      <button onClick={() => open()} className="btn-primary">
        Connect Wallet
      </button>
    );
  }

  return (
    <div className="p-4 border border-[#05D9A699] rounded-2xl bg-[#FFFFFFCC] py-12 px-6 flex flex-col items-center">
      <div className="mb-6 text-2xl leading-[1.2]">
        Begin your CeFi/DeFi yield farming with Manta
      </div>
      <div className="flex flex-col">
        <button
          onClick={() => open()}
          className="btn-primary min-w-[324px] max-md:min-w-[290px]"
        >
          Connect Wallet
        </button>
        <button
          disabled={!!inviteCodeVerified}
          onClick={() => setModalOpen(true)}
          className="btn-reverse mt-2"
        >
          {inviteCodeVerified
            ? `Invited by ${inviteCode}`
            : 'Join via an Invite Code and Earn Extra Boost (optional)'}
        </button>
      </div>

      <BaseModal
        open={modalOpen}
        onCancel={onCancel}
        title="Join via an Invite Code"
        width={isMobile ? 341 : 517}
      >
        <div className="mt-6">
          <VerificationInput
            length={CODE_LENGTH}
            value={inviteCode}
            autoFocus
            onChange={handleCodeChange}
            placeholder=""
            classNames={{
              container: '_vi_container',
              character: inviteCodeVefifiedFailed
                ? '_vi_character_error'
                : '_vi_character',
              characterSelected: inviteCodeVefifiedFailed
                ? '_vi_character--selected_error'
                : '_vi_character--selected'
            }}
          />
        </div>
        {inviteCodeVefifiedFailed && (
          <div className=" text-[#f00] text-[12px] mt-4">
            Invalid Invite Code. Try Another one.
          </div>
        )}
        <button
          disabled={isBtnDisabled}
          onClick={redeem}
          className="btn-primary w-full h-[52px] mt-6"
        >
          {isLoading ? <LoadingIcon /> : 'Redeem Invite Code'}
        </button>
      </BaseModal>
    </div>
  );
}

export default ConnectWallet;
