import ENDPOINTS from '@/constants/apiEndpoints';

const twitterAuth = async (captcha: any) => {
  return fetch(ENDPOINTS.getAuthUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ captcha })
  });
};
export default twitterAuth;
