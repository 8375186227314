import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useModal } from '../WatchAccount/useModal';
import BlockContent from './BlockContent';

const blockedCountries = ['KP', 'IR', 'CU', 'SY', 'MM', 'IQ'];
const blockedRegions = ['Crimea', "Luhans'k", "Donets'k"];
const warningCountries = ['US', 'GB', 'HK', 'CN', 'SG'];
const IPDATA_APIKEY =
  'f47f1429b7dfb0d01a6d049b7cd283087b1b75fc3891f249d9c0919b';

function IPBlockingModal() {
  const { ModalWrapper, showModal, hideModal } = useModal({
    closeDisabled: true
  });
  const [showWarning, setShowWarning] = useState(false);
  useEffect(() => {
    async function getUserGeolocation() {
      const res = await axios.get(
        `https://api.ipdata.co?api-key=${IPDATA_APIKEY}`
      );
      if (res.status === 200) {
        const country_code = res?.data?.country_code;
        const region = res?.data?.region;
        if (
          blockedCountries.includes(country_code) ||
          blockedRegions.includes(region)
        ) {
          showModal();
        }
        if (warningCountries.includes(country_code)) {
          setShowWarning(true);
          showModal();
        }
      }
    }
    getUserGeolocation().catch(console.error);
  }, []);

  return (
    <ModalWrapper>
      <BlockContent
        onClose={() => {
          hideModal();
          setShowWarning(false);
        }}
        showWarning={showWarning}
      />
    </ModalWrapper>
  );
}

export default IPBlockingModal;
