import useSWR from 'swr';
import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';

export function useEarnMoreStatus() {
  const earnMoreStatus = useSWR<IPointsResponse<IEarnMoreData>>(
    ENDPOINTS.earnMoreInfo,
    (url: string): Promise<IPointsResponse<IEarnMoreData>> => {
      return fetcher(
        url,
        {
          method: 'GET'
        },
        false
      );
    },
    {
      revalidateOnFocus: false
    }
  );

  return earnMoreStatus;
}
