import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useState } from 'react';
import MantaNetworkLogo from '@/assets/manta-network-logo.svg';
import CloseMenu from '@/assets/menu-close.svg';
// import OpenMenu from '@/assets/menu-hamburger.svg';
import { useEarnMoreStatus } from '@/store/useEarnMoreInfo';
import './Navbar.css';
import { Profile } from '../Header';
import NewButton from '../NewButton';
import { SidebarData } from './SidebarData';

function MobileNavbar() {
  const { data: earnMoreData } = useEarnMoreStatus();
  const earnMorelist = earnMoreData?.data?.project_list || [];
  const hasNew = earnMorelist.filter(e => e?.is_new).length > 0;
  const [sidebar, setSidebar] = useState(false);
  const { address } = useAccount();
  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <div className="navbar w-screen flex justify-between items-center">
        {/* <img
          src={OpenMenu}
          alt="menu open"
          width={24}
          className="ml-8"
          onClick={() => showSidebar()}
        /> */}
        <svg
          onClick={() => showSidebar()}
          className="ml-8"
          width="28"
          height="24"
          viewBox="0 0 28 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0V3.44333H27.5466V0H0ZM0 10.2267V13.67H27.5466V10.2267H0ZM0 20.5567V24H27.5466V20.5567H0Z"
            fill="#1D314C"
          />
        </svg>
        <a
          href="https://manta.network/"
          target="_blank"
          rel="noreferrer"
          className="flex items-center gap-2 mr-7"
        >
          <img src={MantaNetworkLogo} alt="manta icon" />
        </a>
        <div></div>
      </div>

      <nav className={sidebar ? 'nav-menu nav-menu-show' : 'nav-menu'}>
        <ul className="nav-menu-items ml-6" onClick={showSidebar}>
          <li className="navbar-toggle py-4">
            <Link to="#" className="menu-bars ">
              <img src={CloseMenu} alt="menu close" width={48} />
            </Link>
          </li>
          {SidebarData.map((item, index) => {
            return (
              <li key={index} className="mb-6">
                <Link to={item.path}>
                  <div className="flex items-center">
                    <div className="mr-2">{item.title}</div>
                    {item?.title === 'Earn More' && hasNew ? (
                      <NewButton />
                    ) : null}
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>

        {address && (
          <div className="ml-6">
            <Profile />
          </div>
        )}
        <ul className="absolute bottom-12 nav-menu-items ml-6">
          <li className="navbar-toggle py-4">
            <Link to="/terms" target="_blank">
              <span>User Agreement</span>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default MobileNavbar;
