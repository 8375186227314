import { useCallback, useState, DependencyList } from 'react';

export function useLoading<T>(
  fn: (...args: any[]) => Promise<T>,
  deps?: DependencyList
): [(...args: any[]) => Promise<T>, boolean, string | null] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const execute = useCallback(
    async (...args: any[]) => {
      try {
        setError(null);
        setLoading(true);
        const result = await fn(...args);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [fn, ...(deps || [])]
  );

  return [execute, loading, error];
}
