import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(customParseFormat); // https://day.js.org/docs/en/plugin/custom-parse-format
dayjs.extend(advancedFormat); // https://day.js.org/docs/en/plugin/advanced-format
dayjs.extend(utc); // https://day.js.org/docs/en/plugin/utc
dayjs.extend(duration); // https://day.js.org/docs/en/plugin/duration
dayjs.extend(relativeTime); // https://day.js.org/docs/en/plugin/duration

export default dayjs;
