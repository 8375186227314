import useSWR from 'swr';
import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';

export function useUserMTokenBalance() {
  const userMTokenBalance = useSWR<IPointsResponse<IUserMTokenBalance>>(
    ENDPOINTS.userMTokenBalance,
    (url: string): Promise<IPointsResponse<IUserMTokenBalance>> => {
      return fetcher(
        url,
        {
          method: 'GET'
        },
        false
      );
    },
    {
      revalidateOnFocus: false,
      refreshInterval: 5 * 60 * 1000
    }
  );

  return userMTokenBalance;
}
