import { ReactComponent as ArrowRight } from '@/assets/fuel/arrowRight.svg';
import infoWarn from '@/assets/svgs/info-warn.svg';
import LoadingIcon from '@/components/Loading';
import ENDPOINTS from '@/constants/apiEndpoints';
import { COMMON_STYLES } from '@/constants/fuelStyles';
import { useFuelSignMessage } from '@/hooks/useFuelSignMessage';
import { useFuelWallet } from '@/hooks/useFuelWallet';
import { useFuelWalletsList } from '@/hooks/useFuelWalletsList';
import { ModalCloseStatus, type IModalContentProps } from '@/hooks/useModal';
import { useResponsive } from '@/hooks/useResonposive';
import useUserInfo from '@/store/useUserInfo';
import { fetcher } from '@/utils/request';
import { useWallet } from '@fuels/react';
import { notification } from 'antd';
import { useState } from 'react';
import FuelModalButton from '../FuelModalButton';

export interface BindFuelAddressContentProps {
  onCancel: (status: ModalCloseStatus) => void;
}

const maxBoundLen = 3;

export function BindFuelAddressContent({
  onCancel
}: IModalContentProps<BindFuelAddressContentProps>) {
  const { isMobile } = useResponsive();
  const { wallet } = useWallet();
  const { connect, isConnected, disconnect, currentConnector } =
    useFuelWallet();
  const { fuelWallets } = useFuelWalletsList();
  const [loading, setLoading] = useState(false);
  const fuelAddress = wallet?.address?.toString();
  const { signMessage } = useFuelSignMessage();
  const { userInfo } = useUserInfo();
  const isBound = fuelWallets?.some(
    w_address => w_address?.toLowerCase() === fuelAddress?.toLowerCase()
  );
  const isMaxBound = fuelWallets?.length >= maxBoundLen;

  const getBoundText = () => {
    const len = Math.min(fuelWallets?.length || 0, maxBoundLen);
    if (!len) {
      return '*You can bind up to 3 Fuel Ignition addresses';
    } else {
      return `*You have already bound ${len} Fuel addresses and can bind ${
        maxBoundLen - len
      } more address at most.`;
    }
  };

  const handleMyBoundAddresses = () => {
    onCancel(ModalCloseStatus.MY_BOUND_ADDRESSES);
  };

  const handleConnectFuelOrBind = async () => {
    if (!userInfo?.user_address) {
      notification.info({
        message: 'Please Login EVM Wallet First'
      });
      return;
    }
    if (isConnected) {
      try {
        setLoading(true);
        const sigRes = await fetcher(
          ENDPOINTS.fuelSignData,
          {
            method: 'POST',
            body: JSON.stringify({
              address: fuelAddress
            })
          },
          false
        );
        const { address, message } = sigRes || {};
        const signature = await signMessage(message);
        const bindRes = await fetcher(
          ENDPOINTS.fuelBindWallet,
          {
            method: 'POST',
            body: JSON.stringify({
              address,
              message,
              signature
            })
          },
          false
        );
        const { success, message: bindMessage } = bindRes || {};
        if (success) {
          setLoading(true);
          onCancel(ModalCloseStatus.SUCCESS);
        } else {
          notification.error({
            message: bindMessage ?? 'Bind Fuel address Error'
          });
        }
        setLoading(false);
      } catch (error: any) {
        console.error(error);
        notification.error({
          message: error?.message ?? 'Bind Fuel address Error'
        });
      } finally {
        setLoading(false);
      }
    } else {
      onCancel();
      // @ts-ignore
      connect(currentConnector?.name);
    }
  };
  if (isMobile) {
    return (
      <div
        onClick={e => e.stopPropagation()}
        className="w-full h-full bg-white space-y-4"
      >
        <div className={COMMON_STYLES.text}>
          To ensure that the mTokens you deposit in Fuel Ignition are accurately
          tracked and continue to earn CeDeFi points rewards, please bind your
          Fuel Ignition address.
        </div>
        <div className="inline-flex p-2 bg-fuel-warn/20 rounded-lg border border-fuel-warn/40 flex-col justify-center items-start gap-2">
          <div className="self-stretch justify-start items-start gap-1 inline-flex">
            <img src={infoWarn} alt="info-warn" className="pt-1" />
            <div className="grow shrink basis-0 text-fuel-warn text-sm font-normal font-['Tomorrow']">
              Please perform the binding operation on the web, as the H5 page
              cannot connect to the Fuel wallet.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={e => e.stopPropagation()}
      className="w-full h-full bg-white space-y-4"
    >
      <div className={COMMON_STYLES.text}>
        To ensure that the mTokens you deposit in Fuel Ignition are accurately
        tracked and continue to earn CeDeFi points rewards, please bind your
        Fuel Ignition address.
      </div>
      <div className={COMMON_STYLES.flexColumn}>
        {isConnected ? (
          <>
            <div className={COMMON_STYLES.text}>Your fuel address</div>
            <div className="w-full min-h-[3.375rem] p-4 bg-[#00d994]/5 rounded-lg border border-fuel-primary/40 break-all">
              {fuelAddress}
              <div
                onClick={() => disconnect()}
                className="mt-2 text-left text-fuel-primary text-sm font-medium cursor-pointer"
              >
                Disconnect
              </div>
            </div>
          </>
        ) : null}
      </div>
      <FuelModalButton
        onButtonClick={() => {
          if (loading || isBound || isMaxBound) return;
          handleConnectFuelOrBind();
        }}
        text={
          loading ? (
            <LoadingIcon />
          ) : (
            <>
              {isBound ? 'Bound' : isConnected ? 'Bind' : 'Connect Fuel Wallet'}
            </>
          )
        }
        className={
          loading || isBound || isMaxBound
            ? 'opacity-50 cursor-not-allowed'
            : ''
        }
      />

      <div>
        {isBound && (
          <div className="mb-2 text-black-title/70 text-sm font-normal">
            *This address has already been bound, you can disconnect it and bind
            another address.
          </div>
        )}
        <div className="text-black-title/70 text-sm font-normal">
          {getBoundText()}
        </div>
        <div
          onClick={handleMyBoundAddresses}
          className="inline-flex cursor-pointer justify-start items-center gap-1"
        >
          <div className="text-center text-fuel-primary text-sm font-medium">
            My Bound Addresses
          </div>
          <ArrowRight className="w-4 h-4 fill-fuel-primary" />
        </div>
      </div>
    </div>
  );
}

const Content = (props: IModalContentProps<BindFuelAddressContentProps>) => (
  <BindFuelAddressContent {...props} />
);

export default Content;
