import React, { useState, useEffect } from 'react';
import Countdown, { CountdownRendererFn } from 'react-countdown';

interface CountdownTimerProps {
  endTime: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ endTime }) => {
  const calculateTargetTime = (): Date => {
    const now = new Date();
    const stakeTime = new Date(endTime);

    const todayTarget = new Date();
    todayTarget.setHours(stakeTime.getHours(), stakeTime.getMinutes(), 0, 0);

    if (now > todayTarget) {
      todayTarget.setDate(todayTarget.getDate() + 1);
    }

    return todayTarget;
  };

  const [targetTime, setTargetTime] = useState<Date>(calculateTargetTime());

  useEffect(() => {
    setTargetTime(calculateTargetTime());
  }, [endTime]);

  const renderer: CountdownRendererFn = ({
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      setTargetTime(calculateTargetTime());
      return null;
    } else {
      const remainingHours = hours % 24;

      return (
        <div className="countdown">
          {`${remainingHours?.toString().padStart(2, '0')}H : ${minutes
            ?.toString()
            .padStart(2, '0')}M : ${seconds?.toString().padStart(2, '0')}S`}
        </div>
      );
    }
  };

  return (
    <Countdown
      date={targetTime}
      renderer={renderer}
      key={targetTime.getTime()}
    />
  );
};

export default CountdownTimer;
