export const theme = {
  components: {
    Input: {
      addonBg: 'rgba(0, 217, 148, 0.05)',
      activeBorderColor: 'rgba(0, 217, 148, 0.40)',
      colorBorder: 'rgba(0, 217, 148, 0.40)',
      hoverBorderColor: 'rgba(0, 217, 148, 0.50)',
      paddingBlock: 16,
      paddingInline: 16,
      colorText: 'rgba(29, 49, 76, 0.8)'
    },
    Collapse: {
      headerBg: 'white',
      colorBorder: 'rgba(5, 217, 166, 0.4)'
    },
    Spin: {
      colorPrimary: '#05D9A6'
    }
  },
  token: {
    fontFamily: 'Tomorrow, sans-serif'
  }
};
