import { notification } from 'antd';
import Decimal from 'decimal.js';
import { BigNumber, Contract, ethers } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useState } from 'react';
import { Chain } from 'viem';
import { useAccount, useSwitchChain } from 'wagmi';

import managerAbi from '@/abis/Manager.json';
import LoadingIcon from '@/components/Loading';
import StakeSuccessContent, {
  StakeSuccessContentProps
} from '@/components/ModalContent/StakeSuccessContent';
import config from '@/config';
import { TX_SUCCESS } from '@/constants/walletConstants';
import { useEthersSigner } from '@/hooks/useEthersSigner';
import useModal from '@/hooks/useModal';
import { StakeToken } from '..';

const { stakeContractMap, MANTA_PACIFIC_CHAIN } = config;

export type StakeButtonProps = {
  selectedChain: Chain;
  amount: string;
  balance: string;
  selectedToken: null | StakeToken;
  managerContract: Contract | null;
  routerContract: Contract | null;
  isMantaPacificChain: boolean;
  layerZeroGasFee: BigNumber;
};
const commonCls =
  'flex w-full justify-center btn-primary h-[51px] max-md:h-max';

const StakeButton = ({
  selectedChain,
  amount,
  balance,
  managerContract,
  selectedToken,
  layerZeroGasFee,
  routerContract,
  isMantaPacificChain
}: StakeButtonProps) => {
  const [stakeLoading, setStakeLoading] = useState(false);

  const { chain } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const { address } = useAccount();
  const signer = useEthersSigner();

  const [StakeSuccessModal, { onOpen, onCancel }] =
    useModal<StakeSuccessContentProps>(StakeSuccessContent, {
      title: 'Deposit Successful!',
      width: 434
    });

  const amountBigNumber = parseUnits(
    amount || '0',
    selectedToken?.decimals ?? 0
  );

  const finalMinDepositAmount =
    selectedToken?.minDepositAmount ?? BigNumber.from(0);

  if (amount && amountBigNumber.lt(finalMinDepositAmount)) {
    return (
      <button className={commonCls} disabled>
        Deposit minimum{' '}
        {formatUnits(
          finalMinDepositAmount ?? BigNumber.from(0),
          selectedToken?.decimals ?? 0
        )}{' '}
        {selectedToken?.name}
      </button>
    );
  }

  if (
    (!!amount && +amount <= 0) ||
    // can not using '' as argument for new Decimal Constructor
    new Decimal(amount === '' ? '0' : amount).gt(
      new Decimal(balance == '' ? '0' : balance)
    )
  ) {
    return (
      <button className={commonCls} disabled>
        Insufficient Balance
      </button>
    );
  }

  if (chain?.id !== selectedChain?.id) {
    return (
      <button
        className={commonCls}
        onClick={async () => {
          try {
            await switchChainAsync?.({ chainId: selectedChain?.id });
          } catch (e) {
            console.log('switch network error', e);
          }
        }}
      >
        Switch to {selectedChain?.name}
      </button>
    );
  }
  const handleStake = async () => {
    setStakeLoading(true);
    try {
      if (!signer) {
        return;
      }
      if (!selectedToken?.isNativeToken) {
        const tokenContract = selectedToken?.tokenContract;
        const allowance: BigNumber = await tokenContract
          ?.connect(signer)
          .allowance(address, routerContract?.address);
        if (allowance.lt(amountBigNumber)) {
          await (
            await tokenContract
              ?.connect(signer)
              ?.approve(routerContract?.address, amountBigNumber)
          )?.wait();
        }
      }
      let tx: any;
      if (isMantaPacificChain) {
        const params = {
          value: selectedToken?.isNativeToken
            ? selectedToken?.fee?.add(amountBigNumber)
            : selectedToken?.fee
        };

        if (selectedToken?.usingMantaManager) {
          const rpcUrl = selectedChain?.rpcUrls?.default?.http?.[0];
          const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
          const managerContractAddress =
            stakeContractMap[MANTA_PACIFIC_CHAIN?.id]?.mantaManagerContract;
          const mantaManagerContract = new Contract(
            managerContractAddress ?? '',
            managerAbi,
            provider
          );

          tx = await (
            await mantaManagerContract?.connect(signer)?.stake(
              selectedToken?.mToken?.address,
              selectedToken?.address,
              amountBigNumber,
              address,
              address, // refundReceiver
              {
                value: amountBigNumber
              }
            )
          )?.wait();
        } else {
          tx = await (
            await managerContract
              ?.connect(signer)
              ?.deposit(
                selectedToken?.address,
                amountBigNumber,
                address,
                params
              )
          )?.wait();
        }
      } else {
        const params = {
          value: selectedToken?.isNativeToken
            ? amountBigNumber.add(layerZeroGasFee)
            : layerZeroGasFee
        };
        tx = await (
          await managerContract?.connect(signer)?.stake(
            selectedToken?.mToken?.address,
            selectedToken?.address,
            amountBigNumber,
            address,
            address, // refundReceiver
            params
          )
        )?.wait();
      }

      if (tx.status === TX_SUCCESS) {
        if (selectedToken) {
          onOpen({
            amount,
            selectedToken,
            selectedChain,
            txHash: tx.transactionHash,
            isMantaPacificChain,
            onConfirm: () => {
              onCancel();
            }
          });
        }
      }
    } catch (e: any) {
      console.log('deposit action error:', e);
      let message = e?.data?.message ?? e?.error?.message ?? e?.message;
      if (message.includes('insufficient funds for gas * price')) {
        message = `Error: Not enough gas to cover the transaction.`;
      } else if (message.includes('user rejected transaction')) {
        return;
      }
      notification.error({
        message: message ?? 'Deposit action error'
      });
    } finally {
      setStakeLoading(false);
    }
  };

  return (
    <>
      {StakeSuccessModal}
      <button
        className={commonCls}
        disabled={!amount || stakeLoading}
        onClick={handleStake}
      >
        {stakeLoading ? <LoadingIcon className="ml-2" /> : 'Deposit'}
      </button>
    </>
  );
};

export default StakeButton;
