import mTokenAbi from '@/abis/MToken.json';
import unstakeAbi from '@/abis/unstakeAbi.json';
import config from '@/config';
import useTVL from '@/store/useTVL';
import { roundDownNumString } from '@/utils/formatNumber';
import { Contract, ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { useEffect, useMemo, useState } from 'react';
import { Chain } from 'viem';
import { bsc, bscTestnet, mainnet, manta, sepolia } from 'viem/chains';
import UnstakeButton from './components/UnstakeButton';
import { UnstakeFrom } from './components/UnstakeFrom';
import UnstakePending from './components/UnstakePending';
import { UnstakeTo } from './components/UnstakeTo';
import useMTokenBalance from './hooks/useTokenBalance';

export type UnstakeToken = {
  name: string;
  address: string;
  decimals: number;
  label: string;
  value: string;
};

export type UnstakeChain = Chain & { label: string; value: string };

export type UnstakeChainAndTokenList = {
  chain: UnstakeChain;
  tokenList: Array<UnstakeToken>;
};
const {
  mBTC,
  mETH,
  mUSD,
  mwBETH,
  IS_TESTNET,
  ETH_USDC,
  ETH_USDT,
  ETH_WBTC,
  ETH_WBETH,
  BNB_BTCB,
  BNB_USDC,
  BNB_USDT,
  UNSTAKE_CONTRACT,
  MANTA_PACIFIC_CHAIN
} = config;

export const fromTokenList: Array<UnstakeToken> = [
  {
    ...mUSD,
    label: mUSD.name,
    value: mUSD.name
  },
  {
    ...mBTC,
    label: mBTC.name,
    value: mBTC.name
  },
  {
    ...mETH,
    label: mETH.name,
    value: mETH.name
  },
  {
    ...mwBETH,
    label: mwBETH.name,
    value: mwBETH.name
  }
];

export const ethChain = IS_TESTNET ? sepolia : mainnet;
export const bnbChain = IS_TESTNET ? bscTestnet : bsc;

export const layerzeroDesIdMap: Record<number, number> = {
  [manta.id]: 30217,
  [mainnet.id]: 30101,
  [bsc.id]: 30102,
  3441006: 40272, // mantaSepoliaTestnet
  [sepolia.id]: 40161,
  [bscTestnet.id]: 40102
};
const mantaProvider = new ethers.providers.JsonRpcProvider(
  MANTA_PACIFIC_CHAIN.rpcUrls.default.http[0]
);

export const unstakeContract = new Contract(
  UNSTAKE_CONTRACT,
  unstakeAbi,
  mantaProvider
);

export const toChainMap: Record<string, Array<UnstakeChainAndTokenList>> = {
  mBTC: [
    {
      chain: {
        ...ethChain,
        label: ethChain.name,
        value: ethChain.name
      },
      tokenList: [
        {
          ...ETH_WBTC,
          label: 'WBTC',
          value: 'WBTC'
        }
      ]
    },
    {
      chain: {
        ...bnbChain,
        label: bnbChain.name,
        value: bnbChain.name
      },
      tokenList: [
        {
          ...BNB_BTCB,
          label: 'BTCB',
          value: 'BTCB'
        }
      ]
    }
  ],
  mETH: [
    {
      chain: {
        ...MANTA_PACIFIC_CHAIN,
        label: MANTA_PACIFIC_CHAIN.name,
        value: MANTA_PACIFIC_CHAIN.name
      } as UnstakeChain,
      tokenList: [
        {
          name: 'ETH',
          address: ethers.constants.AddressZero,
          decimals: 18,
          label: 'ETH',
          value: 'ETH'
        }
      ]
    },
    {
      chain: {
        ...ethChain,
        label: ethChain.name,
        value: ethChain.name
      },
      tokenList: [
        {
          name: 'ETH',
          address: ethers.constants.AddressZero,
          decimals: 18,
          label: 'ETH',
          value: 'ETH'
        }
      ]
    }
  ],
  mwBETH: [
    {
      chain: {
        ...ethChain,
        label: ethChain.name,
        value: ethChain.name
      },
      tokenList: [
        {
          ...ETH_WBETH,
          label: 'wBETH',
          value: 'wBETH'
        }
      ]
    }
  ],
  mUSD: [
    {
      chain: {
        ...ethChain,
        label: ethChain.name,
        value: ethChain.name
      },
      tokenList: [
        {
          ...ETH_USDC,
          label: 'USDC',
          value: 'USDC'
        },
        {
          ...ETH_USDT,
          label: 'USDT',
          value: 'USDT'
        }
      ]
    },
    {
      chain: {
        ...bnbChain,
        label: bnbChain.name,
        value: bnbChain.name
      },
      tokenList: [
        {
          ...BNB_USDC,
          label: 'USDC',
          value: 'USDC'
        },
        {
          ...BNB_USDT,
          label: 'USDT',
          value: 'USDT'
        }
      ]
    }
  ]
};

export const UnstakeContent = () => {
  const [amount, setAmount] = useState('');
  const [fromToken, setFromToken] = useState(fromTokenList[0]!);
  const [toChain, setToChain] = useState<UnstakeChainAndTokenList | null>(null);
  const [toToken, setToToken] = useState<UnstakeToken | null>(null);

  const { data: tvlData } = useTVL();

  const {
    data: { value: balanceBigNumber } = { value: 0 },
    isLoading: queryMTokenBalanceLoading
  } = useMTokenBalance({
    address: fromToken?.address as `0x${string}`
  });
  const tokenBalance = useMemo(() => {
    const finalBalance: string = balanceBigNumber
      ? formatUnits(balanceBigNumber, fromToken?.decimals)
      : '0';

    return roundDownNumString(finalBalance, 5);
  }, [fromToken?.decimals, balanceBigNumber]);

  const toChainList = useMemo(() => {
    const nextToChainAndTokenMap = toChainMap[fromToken.name];
    return nextToChainAndTokenMap ?? [];
  }, [fromToken?.name]);

  useEffect(() => {
    const firstChain = toChainList?.[0];
    setToChain(firstChain ?? null);
    setToToken(firstChain?.tokenList?.[0] ?? null);
  }, [toChainList]);

  const fromTokenContract = useMemo(() => {
    return new Contract(fromToken.address, mTokenAbi, mantaProvider);
  }, [fromToken]);

  const renderApr = () => {
    if (fromToken.name === 'mBTC') {
      return tvlData?.tokenList?.BTC?.apr;
    } else if (fromToken.name === 'mETH') {
      return tvlData?.tokenList?.ETH?.apr;
    } else if (fromToken.name === 'mUSD') {
      return tvlData?.tokenList?.USD?.apr;
    } else if (fromToken.name === 'mwBETH') {
      return tvlData?.tokenList?.WBETH?.apr;
    }
  };

  return (
    <>
      <div className="flex gap-4 w-full text-sm mb-2 text-black-title max-md:flex-col">
        <div className="bg-white flex flex-1 flex-col items-center justify-center border border-green/60 rounded-lg py-3 max-md:flex-row max-md:justify-between max-md:py-4 max-md:px-6">
          <span>Est. {fromToken?.name} CeFi APR</span>
          <span className="text-2xl mt-2">{renderApr()}</span>
        </div>
        <div className="bg-white flex flex-1 flex-col items-center justify-center border border-green/60 rounded-lg py-3 max-md:flex-row max-md:justify-between max-md:py-4 max-md:px-6">
          <span>My holding ({fromToken?.name})</span>
          <span className="text-2xl mt-2">
            {queryMTokenBalanceLoading ? '--' : tokenBalance}
          </span>
        </div>
      </div>
      <UnstakePending fromToken={fromToken} />
      <div className="mb-4 px-6 py-4 border border-green/80 rounded-lg w-full bg-white text-black-title/80">
        <UnstakeFrom
          amount={amount}
          setAmount={setAmount}
          fromToken={fromToken}
          setFromToken={setFromToken}
        />
        <UnstakeTo
          amount={amount}
          fromToken={fromToken}
          toChain={toChain}
          setToChain={setToChain}
          toChainList={toChainList}
          toToken={toToken}
          setToToken={setToToken}
          fromTokenContract={fromTokenContract}
        />
        <UnstakeButton
          amount={amount}
          toToken={toToken}
          fromToken={fromToken}
          tokenBalance={tokenBalance}
          toChain={toChain?.chain ?? null}
          fromTokenContract={fromTokenContract}
        />
      </div>
    </>
  );
};
