import { notification } from 'antd';
import _ from 'lodash';
import {
  VariableSizeList as List,
  ListChildComponentProps
} from 'react-window';
import PermanentImg from '@/assets/invites/permanent.png';
import ShareAllButtonImg from '@/assets/invites/share-all-button.svg';
import CopySvg from '@/assets/svgs/copy.svg';
import DefaultAvatar from '@/assets/svgs/default-avatar.svg';
import ShareSvg from '@/assets/svgs/share.svg';
import LockSvg from '@/assets/svgs/lock.svg';
import { shareToTwitter } from '@/utils/shareTwitter';
import { useResponsive } from '@/hooks/useResonposive';

const WEB_SITE_URL = window.location.origin;

interface TeamMemberProps {
  inviteCodeList: InviteCodeItem[];
  memberList: MemberItem[];
  token: string;
}

const RowContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-row justify-between p-4 md:p-6 primary-border bg-green-bg rounded-lg h-[88px] md:h-[74px]">
      {children}
    </div>
  );
};

const MemberRow = ({ item, token }: { item: MemberItem; token: string }) => {
  return (
    <RowContainer>
      <div className="flex-row flex items-center">
        {item.user_avatar ? (
          <img
            src={item.user_avatar}
            alt="avatar"
            className="w-[20px] h-[20px] mr-4 md:mr-5"
          />
        ) : (
          <img
            src={DefaultAvatar}
            alt="default-avatar"
            className="w-[20px] h-[20px] mr-4 md:mr-5"
          />
        )}
        <p className="text-[#010811]">{`${item.user_name.slice(
          0,
          6
        )}***${item.user_name.slice(-6)}`}</p>
      </div>
      <div className="text-[#010811] text-[16px] font-semibold text-right md:text-left">
        {item.user_deposit} {token}
      </div>
    </RowContainer>
  );
};

const InviteCodeRow = ({ item }: { item: InviteCodeItem }) => {
  const inviteUrl = `${WEB_SITE_URL}?inviteCode=${item.invite_code}`;

  const onClickShare = () => {
    shareToTwitter({
      url: inviteUrl,
      hashtags: 'CeDeFi',
      text: `🌊Diving into the CeDeFi deposit rewards with @MantaNetwork 💎 You can join the adventure too! 👫Use my invite code: ${item.invite_code} And earn rewards together! 🚀 Check it out here:`
    });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(inviteUrl);
      notification.success({ message: 'Copied to clipboard' });
    } catch (err) {
      console.error('copy error:', err);
    }
  };

  return (
    <RowContainer>
      <div
        className="flex-row flex items-center hover:cursor-pointer text-sm md:text-base"
        onClick={handleCopy}
      >
        <img src={CopySvg} alt="copy-icon" className="w-[24px] h-[24px] mr-5" />
        <div className="flex flex-col md:flex-row">
          {inviteUrl}
          {item.max_available_times === -1 && (
            <img
              src={PermanentImg}
              alt="copy-icon"
              className="mt-1 md:mt-0 md:ml-4 w-[168px] h-[24px]"
            />
          )}
        </div>
      </div>
      <button onClick={onClickShare}>
        <img src={ShareSvg} alt="copy-icon" className="w-[20px] h-[20px]" />
      </button>
    </RowContainer>
  );
};

const Row = ({ index, style, data }: ListChildComponentProps) => {
  const item = data.list[index];
  return _.has(item, 'user_id') ? (
    <div style={style} className="mb-[16px]">
      <MemberRow item={item} token={data.token} />
    </div>
  ) : (
    <div style={style} className="mb-[8px]">
      <InviteCodeRow item={item} />
    </div>
  );
};

const TeamMember = ({ inviteCodeList, memberList, token }: TeamMemberProps) => {
  const { isMobile } = useResponsive();
  let contentElement;
  const list = [...inviteCodeList, ...memberList];
  if (list.length > 0) {
    contentElement = (
      <List
        className="customScroll mt-4"
        style={{
          overflowX: 'hidden',
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'none'
        }}
        itemData={{ list, token }}
        itemCount={list.length}
        height={565}
        itemSize={index =>
          isMobile ? 94 : _.has(list[index], 'user_id') ? 90 : 82
        }
        width="100%"
      >
        {Row}
      </List>
    );
  } else {
    contentElement = (
      <RowContainer>
        <div className="flex-row flex items-center">
          <img
            src={LockSvg}
            alt="lock-icon"
            className="w-[24px] h-[24px] mr-5"
          />
          Invite Code Locked
        </div>
      </RowContainer>
    );
  }

  const onClickShare = () => {
    const normaInviteCodes = inviteCodeList
      .filter(item => item.max_available_times === 1)
      .map(item => '🌟' + item.invite_code)
      .join('');

    const permanentInviteCode = inviteCodeList.find(
      item => item.max_available_times === -1
    )?.invite_code;

    shareToTwitter({
      url: WEB_SITE_URL,
      text: `🌊Diving into the CeDeFi deposit rewards with @MantaNetwork 💎 You can join the adventure too!
👫Use my invite code:${
        permanentInviteCode
          ? '\n🌟' + permanentInviteCode + '(Permanently)\n'
          : `\n${normaInviteCodes}`
      } And earn rewards together! 🚀 Check it out here:`
    });
  };

  return (
    <div className="primary-border rounded-lg p-6 bg-white-80">
      <div className="flex justify-between mb-6">
        <h1 className="text-[16px] font-medium">Team Member</h1>
        {inviteCodeList.length > 0 && (
          <button onClick={onClickShare}>
            <img
              src={ShareAllButtonImg}
              alt="share-all-button"
              className="md:w-[255px] md:h-[52px]"
            />
          </button>
        )}
      </div>
      {contentElement}
    </div>
  );
};

export default TeamMember;
