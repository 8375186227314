import { Collapse, CollapseProps } from 'antd';
import FaqList from '@/components/FaqList';

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: <h3 className="text-2xl">FAQ</h3>,
    children: <FaqList />
  }
];
const Faq = () => {
  return (
    <Collapse
      items={items}
      defaultActiveKey={'1'}
      className="w-full mt-4"
      expandIconPosition={'end'}
    />
  );
};

export default Faq;
