import React from 'react';
// import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Popover } from 'antd';
import LineNormal from '@/assets/invites/line-normal.svg';
import MobileLineLeft from '@/assets/invites/mobile-line-left.svg';
import MobileLineRight from '@/assets/invites/mobile-line-right.svg';
import infoIcon from '@/assets/svgs/info-gray.svg';
import { useResponsive } from '@/hooks/useResonposive';
import MobileProgressBar from './MobileProgressBar';
import ProgressBar from './ProgressBar';

export interface ProgressBarProps {
  value: number;
  middle: number;
  max: number;
  token: string;
}

export const FRENZY_BONUS = {
  1000: { points: '+ 10,000 Points', frenzy: '5%', bonus: '+ Invite Code x2' },
  5000: { points: '+ 50,000 Points', frenzy: '5%', bonus: '+ Invite Code x3' },
  10000: {
    points: '+ 100,000 Points',
    frenzy: '5%',
    bonus: '+ Invite Code x4'
  },
  20000: {
    points: '+ 200,000 Points',
    frenzy: '10%',
    bonus: '+ Invite Code x5'
  },
  50000: {
    points: '+ 500,000 Points',
    frenzy: '10%',
    bonus: '+ Unlimited Invite code'
  },
  100000: {
    points: '+ 1,000,000 Points',
    frenzy: '10%',
    bonus: '+ Manta Airdrop'
  }
};

export const ArrowIcon = () => <img src={LineNormal} alt="" className="" />;

export const MobileArrowLeftIcon = () => (
  <img src={MobileLineLeft} alt="MobileLineLeft" className="" />
);

export const MobileArrowRightIcon = () => (
  <img src={MobileLineRight} alt="MobileLineRight" className="" />
);

export const TextWrapper = ({
  children,
  isActive,
  styles
}: {
  children: React.ReactNode;
  isActive: boolean;
  styles?: string;
}) => {
  return (
    <div
      className={classNames(
        'flex flex-row text-sm text-black-title',
        {
          'opacity-70': !isActive
        },
        styles
      )}
    >
      {children}
    </div>
  );
};

const TeamContribution = ({
  deposit,
  token,
  middle,
  max
}: {
  deposit: number;
  token: string;
  middle: number;
  max: number;
}) => {
  const { isMobile } = useResponsive();

  return (
    <div className="primary-border bg-white-80 rounded-lg p-6 mt-4 md:mt-0">
      <div className="flex flex-row gap-2 items-center mb-6">
        {isMobile ? (
          <div className="flex flex-col">
            <h1 className="text-[16px] font-medium">
              Current Team Contribution:
            </h1>
            <div className="flex">
              <h1 className="mr-2 text-[16px] font-medium">
                {deposit} {token}
              </h1>
              <Popover
                overlayInnerStyle={{
                  padding: '0',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  width: '312px'
                }}
                arrow={false}
                placement="bottom"
                content={() => (
                  <div className="bg-[#1D314C99] text-white p-2 rounded-[4px]">
                    After completing the milestone, invite codes will be
                    unlocked immediately. Points rewards might be delayed. If
                    you do not receive the points within 24 hours, please
                    contact us.
                  </div>
                )}
                trigger="hover"
              >
                <img src={infoIcon} width={17} height={17} alt={'infoIcon'} />
              </Popover>
            </div>
          </div>
        ) : (
          <>
            <h1 className="text-[16px] font-medium">
              Current Team Deposit Contribution: {deposit} {token}
            </h1>
            <Popover
              overlayInnerStyle={{
                padding: '0',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                width: '312px'
              }}
              arrow={false}
              placement="bottom"
              content={() => (
                <div className="bg-[#1D314C99] text-white p-2 rounded-[4px]">
                  After completing the milestone, invite codes will be unlocked
                  immediately. Points rewards might be delayed. If you do not
                  receive the points within 24 hours, please contact us.
                </div>
              )}
              trigger="hover"
            >
              <img src={infoIcon} width={17} height={17} alt={'infoIcon'} />
            </Popover>
          </>
        )}

        {/* <Link to="staking" className="text-green">
          Stake more to get new invite codes
          <span className="ml-2">{'>'}</span>
        </Link> */}
      </div>
      <div className="mb-[16px] flex justify-between">
        {/* <p className="text-black text-[16px] font-semibold">
          {deposit} {token}
        </p> */}
        {/* {deposit < max && (
          <p>
            Next Milestone: {deposit < middle ? middle : max} {token}
          </p>
        )} */}
      </div>
      {isMobile ? (
        <MobileProgressBar
          value={deposit}
          middle={middle}
          max={max}
          token={token}
        />
      ) : (
        <ProgressBar value={deposit} middle={middle} max={max} token={token} />
      )}
    </div>
  );
};

export default TeamContribution;
