import useSWR from 'swr';
import { useAccount } from 'wagmi';
import ENDPOINTS from '@/constants/apiEndpoints';
import { getRequest } from '@/utils/request';

function useHistoryRecordStatus() {
  const { address } = useAccount();
  const shouldFetch = address ? `${ENDPOINTS.getStakingHistoryStatus}` : null;

  const { data, mutate } = useSWR(shouldFetch, getRequest, {
    revalidateOnFocus: false
  });

  let history = [];
  if (data && data.code === 0) {
    history = data.data || [];
  }
  return {
    history,
    mutate
  };
}
export default useHistoryRecordStatus;
