import { Popover, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import Decimal from 'decimal.js';
import { formatUnits } from 'ethers/lib/utils';
import { BigNumber, Contract, ethers } from 'ethers';
import { useAccount, useBalance, useFeeData } from 'wagmi';
import { parseUnits } from 'viem';
import { roundDownNumString } from '@/utils/formatNumber';
import useUsdPrices from '@/store/useUsdPrices';
import { useErc20TokenBalance } from '@/pages/Stake/UnstakeContent/hooks/useTokenBalance';
import { useAddToken } from '@/hooks/useAddToken';
import metamask from '@/assets/imgs/metamask.png';
import { useEthersSigner } from '@/hooks/useEthersSigner';
import {
  UnstakeChainAndTokenList,
  UnstakeToken,
  layerzeroDesIdMap,
  unstakeContract
} from '../..';

export type UnstakeToProps = {
  amount: string;
  toChain: UnstakeChainAndTokenList | null;
  setToChain: (toChain: UnstakeChainAndTokenList) => void;
  fromToken: UnstakeToken;
  toToken: UnstakeToken | null;
  setToToken: (fromToken: UnstakeToken | null) => void;
  toChainList: UnstakeChainAndTokenList[];
  fromTokenContract: Contract;
};
export const UnstakeTo = ({
  amount,
  toChain,
  setToChain,
  fromToken,
  toToken,
  setToToken,
  toChainList,
  fromTokenContract
}: UnstakeToProps) => {
  const [ratio, setRatio] = useState(0);
  const [gasFee, setGasFee] = useState(BigNumber.from(0));

  const { addTokenToWalletWithCertainChain } = useAddToken();

  const usdPrices = useUsdPrices();
  const { address } = useAccount();
  const { data: nativeToken } = useBalance({
    address
  });
  const { data: feeData } = useFeeData();

  const signer = useEthersSigner();

  const {
    data: { value: balanceBigNumber } = { formatted: 0 },
    isLoading: queryBalanceLoading
  } = useErc20TokenBalance({
    address: toToken?.address as `0x${string}`,
    chain: toChain?.chain
  });
  const tokenBalance = useMemo(() => {
    const finalBalance: string = balanceBigNumber
      ? formatUnits(balanceBigNumber, toToken?.decimals)
      : '0';

    return roundDownNumString(finalBalance, 5);
  }, [toToken?.decimals, balanceBigNumber]);

  const toTokenList = useMemo(() => {
    return toChain?.tokenList;
  }, [toChain]);

  useEffect(() => {
    const getGasFee = async () => {
      if (!signer || toChain?.chain?.id == undefined) {
        return;
      }
      let gasAmount: BigNumber | undefined;
      try {
        const desId = layerzeroDesIdMap[toChain?.chain?.id];
        gasAmount = await unstakeContract
          ?.connect(signer)
          ?.estimateGas?.requestWithdraw?.(
            fromToken.address,
            parseUnits('0.000001', nativeToken?.decimals ?? 0),
            desId,
            toToken?.address
          );
      } catch (e) {
        console.log('estimate withdraw gas error: ', e);
        // it will caused error if the user have not approve the token
        // just use it to mock gas amount when error
        gasAmount = BigNumber.from(202889);
      } finally {
        const gasPrice = feeData?.gasPrice;
        const gasFeeInWei =
          gasPrice && gasAmount
            ? gasAmount?.mul(gasPrice)
            : BigNumber.from('0');

        setGasFee(gasFeeInWei);
      }
    };
    getGasFee();
  }, [
    feeData?.gasPrice,
    fromToken.address,
    nativeToken?.decimals,
    signer,
    toChain?.chain?.id,
    toToken?.address
  ]);

  const handleTokenChange = (value: string) => {
    const token = toTokenList?.find(token => token.value === value);
    if (token) {
      setToToken(token);
    } else {
      throw new Error('can not find token');
    }
  };
  const handleChainChange = (value: string) => {
    const chain = toChainList?.find(token => token?.chain?.value === value);
    if (chain) {
      setToChain(chain);
      setToToken(chain?.tokenList?.[0] ?? null);
    } else {
      throw new Error('can not find chain');
    }
  };

  const addTokenToWallet = async () => {
    if (toToken && toChain?.chain?.id != undefined) {
      addTokenToWalletWithCertainChain(toChain?.chain?.id, {
        address: toToken?.address,
        symbol: toToken.name,
        decimals: toToken.decimals
      });
    }
  };

  const displayBalance = () => {
    const decimalBalance = new Decimal(tokenBalance || '0');
    if (decimalBalance.gt(new Decimal(Math.pow(10, 14)))) {
      return decimalBalance.toExponential(5, Decimal.ROUND_HALF_DOWN);
    }
    return tokenBalance;
  };

  const chainOptions = useMemo(() => {
    return toChainList.map(item => item.chain);
  }, [toChainList]);

  useEffect(() => {
    const getRatio = async () => {
      const ratio = await fromTokenContract?.rewardMultiplier();
      if (ratio) {
        const formatRatio = formatUnits(ratio, 18); // Using static 18
        setRatio(Number(formatRatio));
      }
    };
    getRatio();
  }, [fromTokenContract]);

  return (
    <>
      <div className="mb-2 flex items-center">
        <span className="font-medium mr-2">To: </span>
        <Select
          options={chainOptions}
          className="stake-select stake-select-chain min-w-[170px] w-max max-md:flex-1"
          value={toChain?.chain?.name}
          onChange={handleChainChange}
          popupClassName="chain-select-menu"
        />
      </div>
      <div className="flex flex-col mb-4 border border-green/40 p-4 rounded-lg text-primary-black">
        <div className="flex items-center mb-1">
          <span className="flex-1 font-medium text-xl max-md:min-w-[20px]">
            {(Number(amount) * ratio).toFixed(5)}
          </span>
          <Select
            options={toTokenList}
            className="stake-select w-[155px] max-md:w-[120px]"
            value={toToken?.value}
            onChange={handleTokenChange}
            popupClassName="stake-select-menu"
          />
        </div>
        <div className="flex items-center text-sm max-md:justify-end">
          {toToken?.name && (
            <span className="mr-auto max-md:hidden">
              $
              {(
                (usdPrices[toToken.name] ?? 0) *
                Number(amount) *
                ratio
              ).toFixed(5)}
            </span>
          )}

          <span>
            <span className="font-medium">Balance: </span>
            <span className="break-all">
              {queryBalanceLoading ? '--' : displayBalance()}
            </span>
          </span>
        </div>
      </div>
      <div className="mb-4 font-normal text-primary-black/80 text-sm">
        <span className="font-medium">Note:</span>
        {toToken?.name} will be available to claim after 7 days.
      </div>
      <div className="flex items-center justify-between border-t-[1px] border-green/40 pt-4 text-sm text-primary-black/80">
        <span>Exchange rate</span>
        {fromToken && (
          <div className="flex items-center">
            <span className="text-primary-black">
              1 {fromToken?.label} ≈ {ratio} {toToken?.name}
            </span>
            {toToken?.address !== ethers.constants.AddressZero && (
              <img
                src={metamask}
                alt="metamask"
                className="ml-2 w-5 h-5 hover:opacity-80 hover:cursor-pointer"
                onClick={addTokenToWallet}
              />
            )}
          </div>
        )}
      </div>
      <div className="mb-4 flex items-center justify-between pt-1 text-sm text-primary-black/80">
        <span>Gas Fee</span>
        {Number(
          formatUnits(gasFee.toBigInt(), nativeToken?.decimals ?? 18)
        ).toFixed(9)}{' '}
        {nativeToken?.symbol}
      </div>
    </>
  );
};
