import { Collapse, CollapseProps } from 'antd';
import { Link } from 'react-router-dom';

const faqList: CollapseProps['items'] = [
  {
    key: '0',
    label: (
      <h3 className="text-base font-medium">
        How do I migrate my assets from Renew Paradigm to CeDeFi?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        You can go through the normal unstaking process from{' '}
        <a
          href="https://renewparadigm.manta.network/"
          className="text-green"
          target="_blank"
          rel="noreferrer"
        >
          Renew Paradigm
        </a>{' '}
        and then deposit into CeDeFi. In Renew Paradigm, you first need to
        unstake your derivative token from the Renew Paradigm page. After that,
        go to the project (e.g., Zerolend) where you staked your assets to
        withdraw. After you withdraw your staked asset, you can take it over to
        the{' '}
        <Link to="/dashboard/stake" className="text-green">
          CeDeFi page to deposit
        </Link>
        .
      </p>
    )
  },
  {
    key: '1',
    label: (
      <h3 className="text-base font-medium">
        What are the rewards we can expect on the points side for the campaign?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        To encourage the adoption of Manta CeDeFi, we are running a 2-month
        campaign for users to collect Manta points corresponding to their
        deposits. These points will provide additional rewards at the end of the
        campaign (July 27, 2024) in the form of Manta tokens. The total amount
        of rewards will be disclosed once the campaign is concluded.
      </p>
    )
  },
  {
    key: '2',
    label: (
      <h3 className="text-base font-medium">
        When will points rewards for Manta CeDeFi end?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        The points rewards for Manta CeDeFi ends on July 27, 2024. For a limited
        time (69 total days starting from May 19, 2024 until July 27, 2024),
        users will be able to also earn Manta token rewards through Points. To
        earn points, simply deposit your crypto assets into the Manta CeDeFi
        product. Please note that points will only be earned for users who
        deposited their assets prior to hitting the total $500m Manta CeDeFi
        TVL. Once Manta CeDeFi surpasses $500m in TVL, no additional deposits
        will be eligible for points. For full details, please refer to the Manta
        CeDeFi blog post.
      </p>
    )
  },
  {
    key: '3',
    label: (
      <h3 className="text-base font-medium">
        I deposited my assets but I don’t see my points accumulating.
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        The Manta points are accrued every 24 hours from the moment when you
        first begin deposit your assets. In order to collect your points, you
        must open the Points Box once your points refresh after the 24-hour
        period. You can access your points box through the{' '}
        <Link to="/dashboard/points" className="text-green">
          Points section
        </Link>{' '}
        of the CeDeFi dashboard.
      </p>
    )
  },
  {
    key: '4',
    label: (
      <h3 className="text-base font-medium">How is CeFi APY estimated?</h3>
    ),
    children: (
      <p className="text-sm font-normal">
        CeFi APY is estimated and not a guarantee of any future yield. The
        estimates are based on historical data. From May 18, 2024 to May 25,
        2024 (the first seven days of the CeDeFi product launch), the estimated
        APY is based on the previous 30-day (April 18 to May 18, 2024) yield
        data provided by asset management partners. From May 26th and onward,
        estimated yield data is updated based on historical performance of the
        past 7 days.
      </p>
    )
  },
  {
    key: '6',
    label: (
      <h3 className="text-base font-medium">
        What happens to the points I can accumulate when Manta CeDeFi TVL hits
        $300m and $500m, respectively?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        For every $10 of crypto deposited while Manta CeDeFi is under $300m TVL,
        you will receive 100 points as a base reward. For every $10 of crypto
        deposited while Manta CeDeFi TVL is between $300 to $500m, you will
        receive 50 points as a base reward. Any crypto deposited after $500m TVL
        will not receive Manta points, but is still eligible to earn yield from
        CeFi and DeFi. For full details, please refer to the Manta CeDeFi blog
        post.
      </p>
    )
  },
  {
    key: '7',
    label: (
      <h3 className="text-base font-medium">
        What are Time Multipliers for the Manta points?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        Time Multipliers are additional multipliers to boost your Manta points
        rewards. They are triggered based on the amount of time you have
        deposited (and thus, hold mTokens) your crypto into Manta CeDeFi.
        <br />
        30-day Time Multiplier: after holding your mTokens for 30 days, your
        points will increase 2x. This applies to any points you’ve already
        accumulated, as well as any points you will receive in the future. For
        example, if you accumulated 3,000 points in the first 30 days, you will
        receive an additional 3,000 points; furthermore, your daily points that
        you continue to accrue will also be increased.
        <br />
        60-day Time Multiplier: after holding your mTokens for 60 days, your
        points will increase an additional 2x for a total of 4x (including the
        30-day Multiplier).
        <br />
        You are qualified for the Time Multipliers as long as you continue to
        hold 50% or more of your mTokens throughout the time period. If at any
        time your mTokens drop below 50% of your original amount, you will lose
        all your Time Multiplier bonuses.
        <br />
        You are qualified for the Time Multipliers as long as you continue to
        hold 50% or more of your mTokens throughout the time period. If at any
        time your mTokens drop below 50% of your original amount, you will lose
        all your Time Multiplier bonuses.
      </p>
    )
  },
  {
    key: '8',
    label: (
      <h3 className="text-base font-medium">
        When will yield rewards for Manta CeDeFi end?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        Manta CeDeFi is a new product with ongoing rewards from the CeFi and
        DeFi yields. There is no end to the yield rewards from CeFi and DeFi on
        your deposits.
      </p>
    )
  },
  {
    key: '9',
    label: (
      <h3 className="text-base font-medium">
        Where do the CeFi yields come from?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        The CeFi yields come from Ceffu, a regulated and qualified custodian of
        crypto assets{' '}
        <a
          href="https://www.ceffu.com/blog/binance-custody-is-now-ceffu"
          className="text-green"
          target="_blank"
          rel="noreferrer"
        >
          that was previously Binance Custody
        </a>
        . Ceffu offers institutional-grade management of crypto assets that
        traditionally could only be accessed by high-net-worth individuals.
        Manta CeDeFi unlocks that access for the users within the Manta
        ecosystem.
      </p>
    )
  },
  {
    key: '10',
    label: <h3 className="text-base font-medium">What are mTokens?</h3>,
    children: (
      <p className="text-sm font-normal">
        mTokens are a collection of LSTs you receive on Manta Pacific when you
        deposit your tokens into Manta CeDeFi.
      </p>
    )
  },
  {
    key: '11',
    label: (
      <h3 className="text-base font-medium">
        What networks are supported for Manta CeDeFi?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        Currently, BNB Chain (BTCB, USDC, USDT), Ethereum (WBTC, USDT, USDC,
        ETH) and Manta Pacific (ETH, STONE, wUSDM) are supported to give users
        the flexibility from where to deposit their assets.
      </p>
    )
  },
  {
    key: '12',
    label: (
      <h3 className="text-base font-medium">
        Can I deposit Bitcoin (BTC) natively?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        No. Please do not attempt to deposit any Bitcoin or other assets that
        are not specifically instructed in the product, or you will risk losing
        your funds.
      </p>
    )
  },
  {
    key: '13',
    label: (
      <h3 className="text-base font-medium">
        What is the custodian wallet address?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal break-words">
        Manta Pacific strives to provide transparency for these on-chain,
        custodied assets. The wallet address that custodies all assets is
        0x0Ef3F47Af549546A2d59f32fdcA33CB1CfF4AabC.
      </p>
    )
  },
  {
    key: '14',
    label: (
      <h3 className="text-base font-medium">
        What is the lockup period for my assets?
      </h3>
    ),
    children: (
      <p className="text-sm font-normal">
        There will be a brief lockup at launch (May 17, 2024) that will expire
        in early June. You can withdraw your funds at any time after that
        period. If you withdraw your funds, you will no longer receive yield or
        accumulate Manta points. The withdrawal period processing will take up
        to 7 days.
      </p>
    )
  }
];

export const FaqList = () => (
  <Collapse items={faqList} expandIconPosition={'end'} />
);

export default FaqList;
