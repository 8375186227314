import React from 'react';

const getNowTime = () => {
  return typeof window !== 'undefined' && window.performance
    ? window.performance.now()
    : +new Date();
};

interface ErrorBoundaryProps {
  children: any;
  name: string;
  onReset?: (args: any) => void;
  onError?: (error: any, info: any) => void;
  fallbackRender?: any;
  FallbackComponent?: any;
  fallback?: any;
}

interface ErrorBoundaryState {
  start: number;
  hasError: boolean;
  error: any;
  info: any;
}
const initialState: ErrorBoundaryState = {
  hasError: false,
  error: null,
  info: null,
  start: 0
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state.start = getNowTime();
  }

  state: ErrorBoundaryState = initialState;

  resetErrorBoundary: (args: any[]) => void = (...args) => {
    this.props.onReset?.(...args);
    this.setState(initialState);
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    this.props.onError?.(error, info?.componentStack);

    console.log('widget_error', {
      widget: this.props.name
    });

    console.log('COMPONENT_JS_ERROR', {
      t: 'COMPONENT_JS_ERROR',
      componentName: this.props.name,
      errorInfo: info,
      renderError: 1
    });
    console.error(error);
    this.setState({ error, info, hasError: true });
    console.log(`${this.props.name || 'Something'} went wrong.`);
    console.error(error);
  }

  componentDidMount() {
    console.log('COMPONENT_JS_MOUNT', {
      t: 'COMPONENT_JS_MOUNT',
      componentName: this.props.name,
      renderDuration: getNowTime() - this.state.start,
      mountTime: window.performance ? window.performance.now() : null,
      renderSuccess: 1
    });
  }

  render() {
    const { error, info, hasError } = this.state;
    const { fallbackRender, FallbackComponent, fallback } = this.props;

    if (hasError) {
      const props = {
        componentStack: info?.componentStack,
        error,
        resetErrorBoundary: this.resetErrorBoundary
      };
      if (React.isValidElement(fallback)) {
        return fallback;
      }
      if (typeof fallbackRender === 'function') {
        return fallbackRender(props);
      }
      if (typeof FallbackComponent === 'function') {
        return <FallbackComponent {...props} />;
      }
      return (
        <div className="p-3">
          {`${this.props.name || 'Something'} went wrong.`}
        </div>
      );
    }
    return this.props.children;
  }
}

export function withErrorBoundary(Component: any, errorBoundaryProps: any) {
  function Wrapped(props: any) {
    return (
      <ErrorBoundary {...errorBoundaryProps}>
        <Component {...props} />
      </ErrorBoundary>
    );
  }

  // Format for display in DevTools
  const name = Component.displayName || Component.name || 'Unknown';
  Wrapped.displayName = `withErrorBoundary(${name})`;

  return Wrapped;
}

export default ErrorBoundary;
