import numbro from 'numbro';
import { capitalizeFirstLetter } from '@/utils/format';
import { DECIMAL_PLACES } from '@/store/useBatchMtoken';

export interface MtokenDetailProps {
  details: {
    amount: string;
    protocol: string;
  }[];
  token: {
    address: string;
    decimals: number;
    name: string;
  };
  stake: string | number;
  mtokenBalance: string;
}

function MtokenDetail({
  details,
  token,
  stake,
  mtokenBalance
}: MtokenDetailProps) {
  const detailsWithoutBalance = details.filter(d => d.protocol !== 'balance');

  return (
    <div className="flex flex-col items-end p-2">
      <div>
        <span>Freely Usable:</span>
        <span className="ml-1">
          {numbro(mtokenBalance ?? 0).format({
            thousandSeparated: true,
            mantissa: DECIMAL_PLACES,
            trimMantissa: true
          })}
        </span>
        <span className="ml-2">{token.name}</span>
      </div>
      {detailsWithoutBalance?.map((d, index) => (
        <div key={`mETH-${index}`}>
          <span>
            {capitalizeFirstLetter(d?.protocol.split('_')?.[0] || '')}:
          </span>
          <span className="ml-1">
            {numbro(d?.amount || 0).format({
              thousandSeparated: true,
              mantissa: DECIMAL_PLACES,
              trimMantissa: true
            })}
          </span>
          <span className="ml-2">{token.name}</span>
        </div>
      ))}
      <div>
        <span>Manta CeDeFi Staking :</span>
        <span className="ml-1">
          {numbro(stake ?? 0).format({
            thousandSeparated: true,
            mantissa: DECIMAL_PLACES,
            trimMantissa: true
          })}
        </span>
        <span className="ml-2">{token.name}</span>
      </div>
    </div>
  );
}

export default MtokenDetail;
