import numbro from 'numbro';
import {
  MobileArrowLeftIcon,
  MobileArrowRightIcon,
  FRENZY_BONUS,
  ProgressBarProps,
  TextWrapper
} from './TeamContribution';

const MobileProgressBar = ({ value, middle, max, token }: ProgressBarProps) => {
  const thousandSeparated = (num: number) =>
    `${numbro(num ?? 0).format({
      thousandSeparated: true
    })} ${token}`;

  let width = 0;
  if (value === 0) {
    width = 0;
  } else if (value > 0 && value < 1000) {
    width = 4;
  } else if (value >= 1000 && value < 5000) {
    width = 12;
  } else if (value >= 5000 && value < 10000) {
    width = 28;
  } else if (value >= 10000 && value < 20000) {
    width = 45;
  } else if (value >= 20000 && value < 50000) {
    width = 65;
  } else if (value >= 50000 && value < 100000) {
    width = 78;
  } else if (value >= 100000) {
    width = 100;
  }

  const barStyle = {
    height: `${width}%`,
    width: '8px',
    borderRadius: '20px',
    background: 'linear-gradient(180deg, #56EDB6 47.08%, #CB63F9 100%)'
  };

  return (
    <div className="flex">
      <div className="flex m-auto">
        <div className="left w-[108px]">
          <TextWrapper isActive={width > 0} styles="justify-end mt-4">
            <div className="flex flex-col text-right text-nowrap">
              <div className="flex justify-end h-3">
                <MobileArrowLeftIcon />
              </div>
              <span className="text-base font-medium">
                {thousandSeparated(1000)}
              </span>
              <span className="leading-3">{FRENZY_BONUS[1000].bonus}</span>
            </div>
          </TextWrapper>

          <TextWrapper isActive={width >= 28} styles="justify-end mt-20">
            <div className="flex flex-col text-right text-nowrap">
              <div className="flex justify-end h-3">
                <MobileArrowLeftIcon />
              </div>
              <span className="text-base font-medium">
                {thousandSeparated(10000)}
              </span>
              <span className="leading-3">{FRENZY_BONUS[10000].bonus}</span>
            </div>
          </TextWrapper>

          <TextWrapper isActive={width >= 65} styles="justify-end mt-20">
            <div className="flex flex-col text-right text-nowrap">
              <div className="flex justify-end h-3">
                <MobileArrowLeftIcon />
              </div>
              <span className="text-base font-medium">
                {thousandSeparated(50000)}
              </span>
              <span className="leading-3 text-wrap">
                + Unlimited Invite code
              </span>
            </div>
          </TextWrapper>
        </div>
        <div className="mx-4 bg-green-1 h-[394px] w-[8px] rounded-[20px]">
          <div className="bar" style={barStyle}></div>
        </div>
        <div className="right">
          <TextWrapper isActive={width >= 12} styles="justify-start mt-20">
            <div className="flex flex-col text-left text-nowrap">
              <div className="flex justify-start h-3">
                <MobileArrowRightIcon />
              </div>
              <span className="text-base font-medium">
                {thousandSeparated(5000)}
              </span>
              <span className="leading-3">{FRENZY_BONUS[5000].bonus}</span>
            </div>
          </TextWrapper>

          <TextWrapper isActive={width >= 45} styles="justify-start mt-20">
            <div className="flex flex-col text-left text-nowrap">
              <div className="flex justify-start h-3">
                <MobileArrowRightIcon />
              </div>
              <span className="text-base font-medium">
                {thousandSeparated(20000)}
              </span>
              <span className="leading-3">{FRENZY_BONUS[20000].bonus}</span>
            </div>
          </TextWrapper>

          <TextWrapper isActive={width >= 78} styles="justify-start mt-20">
            <div className="flex flex-col text-left text-nowrap">
              <div className="flex justify-start h-3">
                <MobileArrowRightIcon />
              </div>
              <span className="text-base font-medium">
                {thousandSeparated(100000)}
              </span>
              <span className="leading-3">{FRENZY_BONUS[100000].bonus}</span>
            </div>
          </TextWrapper>
        </div>
      </div>
    </div>
  );
};

export default MobileProgressBar;
