import React from 'react';

import checked from '@/assets/svgs/check.svg';

export interface PointItemProps {
  name: string;
  isFinished: boolean;
  children?: React.ReactNode;
  textClass?: string;
  align?: string;
}

export function CheckBox({ isChecked }: { isChecked: boolean }) {
  return (
    <div
      className={`w-4 h-4  flex justify-center items-center border  rounded ${
        isChecked ? '' : 'bg-[#05D9A6]/5 border-[#05D9A6]/40'
      }  `}
    >
      {isChecked && <img src={checked} alt="checked" />}
    </div>
  );
}

export default function PointItem({
  name,
  isFinished,
  children,
  textClass,
  align = 'items-center'
}: PointItemProps) {
  return (
    <div className={`flex justify-between ${align} `}>
      <div className="flex items-center gap-2">
        <CheckBox isChecked={isFinished} />

        <span
          className={`${textClass} text-black-title text-base font-medium `}
        >
          {name}
        </span>
      </div>
      {children}
    </div>
  );
}
