import BigNumber from 'bignumber.js';
import numbro from 'numbro';

export function formatCountdown(durationInMilliseconds: number): string {
  const durationInSeconds: number = Math.floor(durationInMilliseconds / 1000);
  const days: number = Math.floor(durationInSeconds / (24 * 60 * 60));
  const hours: number = Math.floor(
    (durationInSeconds % (24 * 60 * 60)) / (60 * 60)
  );
  const minutes: number = Math.floor((durationInSeconds % (60 * 60)) / 60);
  const seconds: number = durationInSeconds % 60;

  let formattedTime: string = '';

  if (days > 0) {
    formattedTime += `${days}D:`;
  }
  if (hours > 0 || formattedTime !== '') {
    formattedTime += `${hours}H:`;
  }
  if (minutes > 0 || formattedTime !== '') {
    formattedTime += `${minutes}M:`;
  }

  formattedTime += `${seconds}S`;

  return formattedTime;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const portalErrorTranslation = (e: any) => {
  const reason = e?.reason ? e?.reason : '';
  const errMessage =
    reason || e?.message || e?.error?.message || e || 'Unknown error';
  return errMessage;
};

export const calculateDaysSincePastDate = (pastDate: number): number => {
  const currentDate = new Date();
  const timeDiff = currentDate.getTime() - pastDate;
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // 每天的毫秒数

  return daysDiff;
};

export function formatCountdownFromFutureTime(difference: number): string {
  if (difference <= 0) {
    return '0D:00H:00M:00S'; // 如果差值小于等于零，返回倒计时结束的字符串
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return `${days}D:${padZero(hours)}H:${padZero(minutes)}M:${padZero(
    seconds
  )}S`;
}

export function padZero(number: number): string {
  return number < 10 ? `0${number}` : number?.toString();
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// from token's address and decimals
export const toTokenDecimals = (value: string | number, decimals: number) => {
  return new BigNumber(value || '').times(new BigNumber(10).pow(decimals));
};
// to token's address and decimals
export const fromTokenDecimals = (value: string | number, decimals: number) => {
  return new BigNumber(value || '').div(new BigNumber(10).pow(decimals));
};

export const toWei = (value?: string | number) => {
  return new BigNumber(value || '').times(new BigNumber(10).pow(18));
};
// fromWei
export const fromWei = (value?: string | number) => {
  return new BigNumber(value || '').div(new BigNumber(10).pow(18));
};

export const numbroSafe = (
  value: BigNumber | string | number | undefined | null | 'NaN'
) => (Number.isNaN(value) ? numbro(0) : numbro(value ?? 0));
