const WEB_INTEL_URL = 'https://twitter.com/intent/tweet?';

interface ShareToTwitterParameters {
  text: string;
  url?: string;
  hashtags?: string;
  via?: string;
}

export const shareToTwitter = async (parameters: ShareToTwitterParameters) => {
  try {
    const params = new URLSearchParams();
    Object.entries(parameters).forEach(([key, value]) => {
      if (value) params.append(key, value);
    });

    const finalUrl = `${WEB_INTEL_URL}${params.toString()}`;
    console.log('[Twitter Share]', {
      text: parameters.text,
      url: finalUrl,
      hashtags: parameters.hashtags,
      via: parameters.via,
      length: parameters.text.length
    });

    // Open the URL in X App
    // can't use window.open because of Metamask's popup blockers
    const a = document.createElement('a');
    a.href = finalUrl;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    console.error('[Twitter Share Error]', {
      error: error instanceof Error ? error.message : 'Unknown error',
      params: parameters
    });
    throw error;
  }
};
