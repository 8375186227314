import { useAccount, useChains, useDisconnect, useSwitchChain } from 'wagmi';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import Davater from '@davatar/react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Chain, bsc, bscTestnet } from 'viem/chains';
import useUserInfo from '@/store/useUserInfo';
import { ReactComponent as DropDownIcon } from '@/assets/drop-down-icon.svg';
import { truncateAddress } from '@/utils/truncatAddress';
// import X from '@/assets/X.svg';
import './index.css';
import MantaLogo from '@/assets/manta-logo.svg';
import ETHLogo from '@/assets/svgs/eth-coin.svg';
import BNBLogo from '@/assets/svgs/bnb-logo.svg';
import MantaNetworkLogo from '@/assets/manta-network-logo.svg';
import config from '@/config';

export function Profile() {
  const { userInfo } = useUserInfo();
  const { address, chain } = useAccount();
  const chains = useChains();
  const { switchChain } = useSwitchChain();
  const { disconnect } = useDisconnect();
  const [wrongNetwork, setWrongNetwrok] = useState(false);

  useEffect(() => {
    if (chain && chains) {
      const selectedChain = chains.find(_chain => _chain.id === chain?.id);
      if (!selectedChain) {
        setWrongNetwrok(true);
      } else {
        setWrongNetwrok(false);
      }
    }
    if (!chain) {
      setWrongNetwrok(true);
    }
  }, [chain, chains]);

  const [showDisconnect, setShowDisconnect] = useState(false);
  // const [showUserInfo, setShowUserInfo] = useState(false);
  const [showNetworksDropDown, setShowNetworksDropDown] = useState(false);

  const renderChainIcon = (chain?: Chain) => {
    if (!chain) {
      return null;
    }
    let imgSrc = ETHLogo;
    if (chain?.id === bscTestnet.id || chain?.id === bsc?.id) {
      imgSrc = BNBLogo;
    } else if (chain?.id === config.MANTA_PACIFIC_CHAIN?.id) {
      imgSrc = MantaLogo;
    }
    return <img src={imgSrc} width={20} alt="chain logo" />;
  };
  return (
    <div className="flex max-md:flex-col items-center max-md:items-start gap-2">
      <OutsideClickHandler
        onOutsideClick={() => setShowNetworksDropDown(false)}
      >
        <div className="relative">
          <button
            onClick={() => setShowNetworksDropDown(!showNetworksDropDown)}
            className="header-button"
          >
            {wrongNetwork ? (
              <span className=" text-[rgba(255,0,0,0.6)]">Wrong Network</span>
            ) : (
              <span className="header-button-chain flex items-center gap-2">
                {renderChainIcon(chain)}
                {chain?.name}
              </span>
            )}
            <DropDownIcon />
          </button>
          {showNetworksDropDown && (
            <div className="absolute top-[60px] header-dropdown z-10">
              {chains.map(network => (
                <button
                  className="dropdown-item flex items-center gap-2"
                  key={network.name}
                  onClick={() => switchChain?.({ chainId: network.id })}
                >
                  {renderChainIcon(network)}
                  {network.name}
                </button>
              ))}
            </div>
          )}
        </div>
      </OutsideClickHandler>

      {/* {userInfo.user_twitter && (
        <OutsideClickHandler onOutsideClick={() => setShowUserInfo(false)}>
          <div className="relative">
            <button
              onClick={() => setShowUserInfo(v => !v)}
              className="header-button flex items-center gap-2"
            >
              <img src={X} alt="X logo" />
              <span>@{userInfo.user_twitter}</span>
              <DropDownIcon />
            </button>
            {showUserInfo && (
              <div className="absolute top-[60px] w-full header-dropdown">
                <div className="dropdown-item">{userInfo.user_name}</div>
                <div className="dropdown-item">
                  <div className="text-[12px] mb-4">Invited by</div>
                  <div>{userInfo.invite_user_name}</div>
                </div>
                <div className="dropdown-item">
                  <div className="text-[12px] mb-4">Invited by Code</div>
                  <div>{userInfo.invite_code}</div>
                </div>
              </div>
            )}
          </div>
        </OutsideClickHandler>
      )} */}

      <OutsideClickHandler onOutsideClick={() => setShowDisconnect(false)}>
        <div className="relative">
          <button
            onClick={() => setShowDisconnect(!showDisconnect)}
            className="header-button flex items-center gap-2"
          >
            {address && <Davater size={20} address={address} />}
            <span className="flex items-center gap-2">
              {truncateAddress(address)}
              <DropDownIcon />
            </span>
          </button>
          {showDisconnect && (
            <div className="absolute top-[60px] w-full header-dropdown z-10">
              <button className="dropdown-item" onClick={() => disconnect()}>
                Disconnect
              </button>
              <div className="dropdown-item">
                <div className="text-[12px] mb-4">Invited by Code</div>
                <div>{userInfo.invite_code || '--'}</div>
              </div>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
}

function Header() {
  const { address } = useAccount();
  const { pathname } = useLocation();
  const activeRNPNav =
    pathname === '/' ||
    (pathname.includes('/dashboard') && !pathname.includes('invites'));
  return (
    <header className="flex justify-between items-center h-[84px] px-12 bg-white backdrop-blur-[5px] relative z-10">
      <div className="flex items-center gap-8 relative">
        <a
          href="https://manta.network/"
          target="_blank"
          rel="noreferrer"
          className="flex items-center gap-2"
        >
          <img className="h-[32px]" src={MantaNetworkLogo} alt="manta icon" />
        </a>
        <Link
          to="/"
          className={classNames(
            'header-link flex items-center gap-1 text-center ml-10',
            {
              'text-green': activeRNPNav,
              'text-[rgba(21,21,34,0.70)]': !activeRNPNav
            }
          )}
        >
          Manta CeDeFi
        </Link>
        <Link
          to="/dashboard/invites"
          className={classNames('header-link', {
            'text-green': pathname === '/dashboard/invites',
            'text-[rgba(21,21,34,0.70)]': pathname !== '/dashboard/invites'
          })}
        >
          Refer friends
        </Link>
        {/* <Link
          to="/about"
          className={classNames('header-link', {
            'text-green': pathname === '/about',
            'text-[rgba(21,21,34,0.70)]': pathname !== '/about'
          })}
        >
          About
        </Link> */}
      </div>
      {address && <Profile />}
    </header>
  );
}

export default Header;
