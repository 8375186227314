import { useAccount, useSwitchChain, useWalletClient } from 'wagmi';

export type Token = {
  address: string;
  decimals: number;
  symbol: string;
};

export const useAddToken = () => {
  const { chain } = useAccount();
  const { switchChainAsync } = useSwitchChain();

  const { data: walletClient } = useWalletClient();

  const addTokenToWalletWithCertainChain = async (
    chainId: number,
    token: Token
  ) => {
    try {
      if (chain?.id !== chainId) {
        await switchChainAsync?.({ chainId });
      }

      (walletClient as any)?.watchAsset({
        type: 'ERC20',
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals
        }
      });
    } catch (ex) {
      console.error(ex);
    }
  };

  return {
    addTokenToWalletWithCertainChain
  };
};
