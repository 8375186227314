import useSWR from 'swr';
import ENDPOINTS from '@/constants/apiEndpoints';
import { fetcher } from '@/utils/request';
import useUserInfo from './useUserInfo';

export function useUserRank() {
  const { userInfo } = useUserInfo();
  const userRankStatus = useSWR<IUserRankRes>(
    userInfo?.user_address
      ? [`${ENDPOINTS.leaderboardUser}`, userInfo?.user_address]
      : null,
    ([url, address]: [string, string]): Promise<IUserRankRes> => {
      return fetcher(
        url,
        {
          method: 'GET'
        },
        false
      );
    },
    {
      revalidateOnFocus: false
    }
  );

  return { userRankStatus };
}
