const termsText = `Last update: 1 June 2024

This Term of Use Agreement, together with the privacy policy ( “Privacy Policy”) and/or any additional agreement (collectively the “Agreement”) that Manta Network Ltd and/or its affiliates (collectively as “we”, “our”, “us”, “Manta CeDeFi” or the “Company”) publicly posts under this website or make available to any visitors on this website, are entered into between Manta CeDeFi and you as a visitor of this website or as a user access to any Manta CeDeFi application (“User”,  “you” or “your”), in relation to your use of, and access to this website, including all web applications, services and/or any associated websites or applications linked thereto with Manta CeDeFi (any materials, services, applications available therein are collectively referred to as the “Site” or the “Website”).

PLEASE READ THE AGREEMENT CAREFULLY BEFORE USING OR ACCESSING THE WEBSITE AND REVIEW THEM PERIODICALLY AND REGULARLY FOR CHANGES. BY VISITING OR ACCESSING THE WEBSITE, REGISTERING FOR OR USING OR ANY PART THEREOF, YOU AGREE TO BE BOUND BY THE AGREEMENT AND AGREE THAT SUCH AGREEMENT CONSTITUTE A LEGALLY BINDING AGREEMENT BETWEEN YOU AND US. IF YOU DO NOT AGREE TO BE FULLY BOUND BY ALL OR ANY OF THE AGREEMENT, YOU SHOULD EXIT THE SITE IMMEDIATELY AND NOT TO MAKE ANY USE OF THE WEBSITE OR OUR SITES.

PLEASE NOTE THAT WE RESERVE THE RIGHT, AT OUR SOLE DISCRETION, TO REVISE THE AGREEMENT, AT ANY TIME WITHOUT NOTICE.  IF YOU DO NOT AGREED WITH ANY REVISED TERM UNDER THE AGREEMENT, YOU MUST EXIT THE WEBSITE IMMEDIATELY AND STOP USING THE SITE.

THE WEBSITE AND ANY SERVICES PROVIDED BY THE COMPANY ARE NOT AVAILABLE TO PERSONS OR ENTITIES WHO ARE RESIDE IN, CITIZENS OF, LOCATED IN, INCORPORATED IN OR HAVING A REGISTERED  OFFICE IN THE UNITED STATES OF AMERICA, CANADA, UNITED KINGDOM, MAINLAND CHINA, HONG KONG, SINGAPORE, NORTH KOREA, IRAN, CUBA, SYRIA, BURMA (MYANMAR), IRAQ AND THE REGIONS OF UKRAINE SUCH AS CRIMEA, DONETSK AND LUHANSK (“BLOCKED PERSONS”).  Manta CeDeFi  DOES NOT MAKE ANY EXCEPTIONS.  THEREFORE, IF YOU ARE A BLOCKED PERSON, DO NOT ATTEMPT TO USE THE WEBSITE OR ANY SERVICE OF THE COMPANY.  USE OF A VIRTUAL PRIVATE NETWORK (VPN) TO CIRCUMVENT THE RESTRICTIONS SET FORTH IS PROHIBITED.

1. THE WEBSITE, ACCESS AND USE OF THE SITE.
1.1 The Website provides information of the Company, the Company’s applications, software and blockchain protocol developed by the Company and allow User to access with such information and to use of the Company’s application and blockchain protocol.
1.2 The Website is offered on a self-service basis and may charge fees for interacting with the blockchain, i.e. “gas fees”, for the use or access on the Site. 
1.4 When visit or use the Website, you represent and warrant that: 
(i) you have reached the legal age and capacity to form a binding contract in your jurisdiction and have the full capacity to accept this Agreement and enter into transactions as a result of using the website; 
(ii) in the event of registering with us, you have provided us with accurate, true and complete information about yourself (including, but not limited to, your name and email address); 
(iii) you are not a Blocked Persons or a resident, national, or agent of Cuba, Iran, Iraq,  Myanmar (Burma), North Korea, Syria, regions of Ukraine (Crimea, Donetsk and Luhansk), Mainland China, Hong Kong, Singapore, the United States, the United Kingdom, Canada or any jurisdictions as may be designated by the Company or any country to which the United States, the United Kingdom or the European Union impose sanctions from time to time (“Restricted Jurisdictions”);
(iv) you are not a member of any sanctions list or equivalent maintained by the United States, the United Kingdom or the European Union government;
(v) if you are entering into this Agreement as an entity or you represent one or more businesses or franchises, that you are acting as their authorized representative, and that both you and the entity you represent will be bound by the Agreement;
(vi) your usage of the Website is and will be in full compliance with all laws and regulations, applicable to your use of the website is not prohibited by any applicable laws or regulations, and is not for illegal or immoral purpose;
(vii) you do not, and will not, use VPN software or any other privacy or anonymization tools or techniques to circumvent, or attempt to circumvent, any restrictions that apply to the Site; 
(viii) your registration or your use of the website will be consistent with the foregoing license, covenants and restrictions, and you are not infringing nor violating the rights of any other party; 
(ix) you certify that any digital assets used by you to connect to any applications or blockchain protocol on the Site are either owned by you or that you are validly authorized to carry out the transaction, and that all transactions initiated from your own digital asset wallet and not on behalf of any other person or entity; 
(xi) in connection with using the Site or any applications or blockchain protocol on the Site, you will only transfer legally obtained digital assets that belong to you;
(xii) you will maintain the security and confidentiality of your private keys associated with digital asset wallet(s), passwords, API keys, and/or other credentials;
(xiii) you agree to abide by any relevant laws or regulations in your jurisdiction, including but not limited to reporting any trading activities or profits for taxation purposes, if any.
1.5 As a condition to visiting the Website and/or using or accessing any applications or blockchain protocol on the Website, you acknowledge and agree the following:
(i) we reserve the right to suspend or terminate, according to our sole discretion and without providing any prior notice, our service and/or your access to the Website, in the event that (a) when we reasonably suspect that you have breached any of the Agreement, (b) when we reasonably suspect fraudulent or illegal activities, or (c) when the Agreement has been terminated;
(ii) from time to time the Site and the applications or protocol linked to the Site may be inaccessible or inoperable for any reason, including but not limited to (a) technical malfunctions; (b) periodic maintenance procedures or repairs that Manta CeDeFi or its vendors or contractors may undertake; (c) causes or events beyond the Company’s control or reasonably foresee; (d) disruption and temporary or permanent unavailability of underlying blockchain infrastructure; or (e) unavailability of third-party service providers or external partners for any reason;
(iii) the Website and the applications or protocol linked therein may evolve, which means Manta CeDeFi may apply changes, replacement or discontinue (whether temporarily or permanently) the Website and/or any applications or protocol at any time at our sole discretion;
(iv) Manta CeDeFi does not act as an agent for you or any other Users of the Site;
(v) we owe no responsibilities or liabilities to you or any other party, and to the extent any such responsibilities or liabilities may exist, you hereby irrevocably waive, disclaim and withdraw all these responsibilities and liabilities;
(vi) we have no control over, or liable to, the delivery, safety, legality or any other aspect of any digital assets that you may transfer to or from a third party, whether under the Manta CeDeFi’s application or protocol, and if you experience any problem with any transactions of digital assets, you bear all foreseeable or non-foreseeable risks;
(vii) All information provided in connection with your visit or your access and use of the Site is for informational purposes only and should not be construed as investment, financial, legal, tax, or any professional advice.  You should not take, or refrain from taking, any action based on any information contained on the Site, or any information that we make available at any time.  You should take independent professional advice if you are in doubt of whether visit, use or access to the Site. 
1.6 You are solely and wholly responsible for the security of your digital asset wallet and we urge you to keep your wallet private address, your account password secure and to always log-off when leaving the website.
1.7 You acknowledge and agree that you are solely and wholly responsible for obtaining and maintaining any hardware, facilities, connections, licenses, permits, database, equipment, external software or any other resources as may be required and/or necessary for the use of the website (such as, computer, mobile device, internet connection and telecommunications) and any relevant payment system.  You agree that we will not bear any liability for any cost, expense, loss or other damage you may suffer directly or indirectly with respect to such hardware, facilities, connections, licenses, permits, database, equipment or external software or any other resources as may be required, in connection with use of the website.  
1.8 You agree to defend and indemnify the Company and any of their officers, directors, employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of (1) your breach of this agreement or the documents referenced herein; (2) your violation of any law or the rights of a third party; or (3) your use and access of our service and/or this Website. 

2. PROHIBITED ACTIVITY

By visiting, using or accessing the Site, you confirm that you will not use the Site for the following:

(i) violate any laws in any competent jurisdictions, including but not limited to any anti-money laundering and anti-terrorist financing laws and sanctions programs;
(ii) engage in any transactions or dealing that infringe or violate any copyright, trademark, patent, privacy or any other proprietary right under any laws of a competent jurisdictions;
(iii) use the Site in any manner that could interfere with, disrupt, affect or inhibit other visitors or users from fully enjoying the Site, or that may damage, disable, overburden, or impair the functioning of the Site in any manner;
(iv) circumvent any content-filtering techniques, security measures or access controls that Manta CeDeFi employs on the Site, such as through the use of VPN;
(v) use any software or any automated means not provided or consent by us, to access the Site or to extract data, or introduce any malware, virus, trojan horse, worm, logic bomb, drop deal device, backdoor, shutdown mechanism or other harmful material to the Site;
(vi) providing false, inaccurate, or misleading information while using the Site;
(vii) use or access the Site to transmit or exchange any digital assets that are related to any criminal or illegal activities;
(viii) use the Site from a jurisdiction that the use of the Site is prohibited;
(ix) harass, abuse, or harm of any other person, entity, or any staffs of Manta CeDeFi or service providers; and
(x) impersonate another user or otherwise misrepresent yourself.

3. OWNERSHIP.

3.1 The Website, including any enhancements, derivatives and improvements thereof, are the sole property of Manta CeDeFi. Without limitation of the foregoing, all the text, images, sounds, music, marks, logos, compilations (meaning the collection, arrangement and assembly of information) and other content on the Website (collectively, the “Content”), and all software, blockchain protocol, API keys, source code, and/or any technical material embodied in the website or otherwise used by Manta CeDeFi to deliver the website’s function (“Software”), are the property of Manta CeDeFi and are protected by copyright and other intellectual property laws. Nothing in the Agreement shall grant the User any of such property and/or ownership.
3.2 Any rights with respect to the Software or the Content which are not expressly granted hereby Manta CeDeFi are reserved by Manta CeDeFi.
3.3 You agree not to copy, imitate, or use the Content without prior consent of Manta CeDeFi or the applicable licensors.

4. INTELLECTUAL PROPERTY LICENSE, RESTRICTION.

4.1 We grant you a personal, revocable, non-assignable, non-perpetual and non-exclusive right to access and use the website as a licensee, provided that you fully comply with the Agreement.
4.2 You shall not remove any copyright, trademark or other proprietary notices that have been placed on the website.
4.3 You shall not exploit the website in any unauthorized way whatsoever, including but not limited to trespass or materially burden network capacity and you shall not use the website for any illegal or immoral purpose, or in violation of any applicable laws and regulations, such as laws governing intellectual property and other proprietary rights, data protection and privacy, anti-money laundering and currency control. You acknowledge that you are fully responsible for satisfying any legal and regulatory requirements applicable in countries and territories in which you operate.
4.4 We reserve the right to apply limits on the use of the website, for example, by limiting the available features, or number of supported Users and / or transactions. We further reserve the right to publish such limitations, make them different from User to User, or change such limitations at will, in each case in our sole discretion.
4.5 You hereby grant us a royalty-free, fully paid-up, sublicensable, transferable, perpetual, irrevocable, non-exclusive, worldwide license to use, copy, modify, create, display, perform, publish and distribute, in any form, medium, or manner, any content that is available to other users as a result of your use of the Site (“User Content”), including but not limited to for promoting Manta CeDeFi, its affiliates, or the Site.
4.6 You warrant that you own the User Content and have the right to grant and license to Manta CeDeFi and that such license does not and will not violate, misappropriate or infringe any third party’s rights.

5. USER VERIFICATION, COMPLIANCE AND RESTRICTION.

5.1 You hereby authorize us, directly or through our third-party service providers, to take any measures that we consider necessary to verify your identity, conduct analysis on your digital wallet address(es), and to take any action we deem necessary based on the results.
5.2 Blocked Persons or any persons or located in the countries specified as Restricted Jurisdictions are not permitted to use the Website. This restriction also applies to residents and citizens of other nations while located in a Restricted Jurisdictions. The fact that the Website is accessible in a Restricted Jurisdictions, or that the Website allows the use of the official language of a Restricted Jurisdictions, shall not be construed as a license to use the Website in such Restricted Jurisdictions. Any attempt to circumvent this restriction, for example, by using a virtual private network, proxy or similar service that masks or manipulates the identification of your real location, or by otherwise providing false or misleading information regarding your citizenship, location or place of residence, or by using the website through a third party or on behalf of a third party located in a Restricted Jurisdictions is a breach of this Agreement. If it becomes apparent, or we have reasonable grounds to suspect, that you are a Blocked Persons or located in any of the Restricted Jurisdictions, this may result in blocking your access to the Website or our service, without an obligation to provide you with advance notice, all in accordance with applicable laws and agreements.

6. FEES

In accessing any applications or blockchain protocol on the Site or linked to the Site, you may require to pay all necessary fees for any transaction on the blockchain network, including “gas fees”, as well as any other fees.  The fees may vary from time to time due to various of considerations, including but not limited to network congestion. 

7. DATA COLLECTION.

7.1 You agree that we may periodically collect, store and use data (including, but not limited to technical information) in connection with your registration or use of the Site and other Website ( “Usage Data”). We may also collect information about Users who access through the website (“User Data”). In addition, our systems may automatically collect other data about your operations, depending on the specific technology used by you for the implementation of the website.
7.2 The Usage Data and User Data is our property. We may use such data: to provide our service to you and to others; to improve the website; to personalize and enhance the use of the website; to determine those areas which are useful or popular and those that are less; to keep record of correspondence with you; to address any issues that you are having with the website; to evaluate your use, preferences and trends for internal statistical and analytical purposes and in respect of operations and product development. We may also use the Usage Data and User Data to offer cross-promotions of Manta CeDeFi.
7.3 We may, at our sole discretion, provide you with information (“Confidential Information”). Any such information shall remain the property of Manta CeDeFi. The User shall hold the Confidential Information in strict confidence. Without Manta CeDeFi prior written consent, such Confidential Information shall not be disclosed by the User in whole or in part to any person other than those agents and employees of the User who need to know about the Confidential Information for the sole purposes of using our website. The User further agrees that Manta CeDeFi’s Confidential Information, whether in written or electronic form, shall not be copied or reproduced at any time without Manta CeDeFi’s prior written consent. In addition, in case the User receives and uses the Confidential Information, the User shall abide by our privacy policy as if you were Manta CeDeFi in such privacy policy.
7.4 Manta CeDeFi may share your personal and other information (“Information”) with our affiliate companies or third party to verify and validate information, and to address users matters. By using the Website and/or our service, you hereby agree to your Information will be passed to our affiliate companies or third party solely for the purpose to facilitate your usage of the Website or our service. While Manta CeDeFi does not sell your information, we may need to share or pass your information with third parties acting on our behalf or as may be required by law, regulations or court order. We will use reasonable efforts to ensure that your Information is used in a reasonable manner and you hereby agree and permit Manta CeDeFi to share your Information with regulators, law enforcement authorities and government agencies if Manta CeDeFi is so requested.
7.5 Manta CeDeFi will store your Information in accordance with the General Data Protection Regulation under European Union regulations (EU) 2016/679. All of our policies related to your Information shall be contained in the Private Policy.

8. PROHIBITED USE, PROHIBITED BUSINESSES AND CONDITIONAL USE.

8.1 You may not engage in the following categories of activity ("Prohibited Uses"). The specific types of use listed below are representative, but not exhaustive. By accessing the Website or using our service, you confirm that you will not do any of the following:
Unlawful Activity: Activity which would violate, or assist in violation of, any law, statute, or regulation, sanctions programs administered in any countries, including but not limited to the persons or countries listed by the United States, the United Kingdom or the European Union impose sanctions, or which would involve proceeds of any unlawful activity; publish, distribute or disseminate any unlawful material or information;
Abusive Activity: Actions which impose an unreasonable or disproportionately large load on our infrastructure, or detrimentally interfere with, intercept, or expropriate any system, data, or information; transmit or upload any material to the Site that contains viruses, Trojan horses, worms, or any other harmful or deleterious programs; attempt to gain unauthorized access to the Site, computer systems or networks connected to the Site, through password mining or any other means; use information of another party to access or use the Site, except in the case of specific merchants and/or applications which are specifically authorized by a user to access such service and information; or transfer your access to a third party, unless by operation of law or with the express permission of Manta CeDeFi;
Abuse Other Users: Interfere with another individual's or entity's access to or use of any the Services defame, abuse, extort, harass, stalk, threaten or otherwise violate or infringe the legal rights (such as, but not limited to, rights of privacy, publicity and intellectual property) of others; incite, threaten, facilitate, promote, or encourage violent acts against others; harvest or otherwise collect information from the Site about others, including without limitation email address, without proper consent;
Fraud: Activity which operates to defraud Manta CeDeFi, or any other person; provide any false, inaccurate, or misleading information to Manta CeDeFi;
Gambling: Lotteries; bidding fee auctions; sports forecasting or odds making; fantasy sports leagues with cash prizes; internet gaming; contests; sweepstakes; games of chance; and
Intellectual Property Infringement: Engage in transactions involving items that infringe or violate any copyright, trademark, right of publicity or privacy or any other proprietary right under the law, including but not limited to sales, distribution, or access to counterfeit music, movies, software, or other licensed materials without the appropriate authorization from the rights holder; use of Manta CeDeFi intellectual property, name, or logo, including use of Manta CeDeFi trade or service marks, without express consent from Manta CeDeFi or in a manner that otherwise harms Manta CeDeFi or the Manta CeDeFi brand; any action that implies an untrue endorsement by or affiliation with Manta CeDeFi.

9. WARRANTIES AND DISCLAIMER.

YOU ACKNOWLEDGE, THAT TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE WEBSITE AND OTHER MATERIALS PROVIDED TO YOU BY Manta CeDeFi, ARE PROVIDED ON AN “AS IS” BASIS. OTHER THAN EXPRESSLY PROVIDED UNDER THE AGREEMENT, Manta CeDeFi GIVES NO OTHER WARRANTIES, EXPRESS, IMPLIED, STATUTORY OR IN ANY COMMUNICATION WITH USER.
THE USE OF THE WEBSITE AND RELIANCE ON THE CONTENT AVAILABLE IS DONE SOLELY AT YOUR OWN RISK. Manta CeDeFi DISCLAIMS ANY AND ALL WARRANTIES RELATING TO THE SITE, THEIR CONTENT AND OTHER MATERIALS, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITEDTO THEIMPLIED WARRANTIES OF NON-INFRINGEMENT OF THIRD PARTY RIGHTS, SATISFACTORY QUALITY, AVAILABILITY, SECURITY, TITLE, ABILITY TO OPERATE AND FITNESS FOR A PARTICULAR PURPOSE.
ANY RELIANCE ON THE WEBSITE IS AT THE USER’S OWN RISK. Manta CeDeFi DOES NOT WARRANT THAT THE WEBSITE WILL MEET YOUR REQUIREMENTS, AND DOES NOT GUARANTEE THAT THE USE OR OTHER EXPLOITATION OF THE SITE WILL LEAD TO CERTAIN RESULTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM Manta CeDeFi OR THROUGH THE WEBSITE WILL CONSTITUTE ANY WARRANTY AND/OR REPRESENTATION.
WE DO NOT WARRANT NOR REPRESENT THAT ANY CONTENT OR THE WEBSITE PROVIDED WILL BE ACCURATE OR ERROR-FREE, THAT ACCESS THERETO WILL BE UNINTERRUPTED, THAT ALL DEFECTS WILL BE CORRECTED, OR THAT THE SERVERS, AND THE CONTENT AVAILABLE THERE THROUGH IS OR WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
Manta CeDeFi MAY PAUSE OR INTERRUPT THE WEBSITE AT ANY TIME, AND USERS SHOULD EXPECT PERIODIC DOWNTIME FOR UPDATES TO THE WEBSITE.
WE WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, OR DATA), EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FURTHER, WE WILL NOT BE RESPONSIBLE FOR ANY COMPENSATION, REIMBURSEMENT, OR DAMAGES ARISING IN CONNECTION WITH YOUR INABILITY TO USE THE WEBSITE, INCLUDING AS A RESULT OF ANY (I) TERMINATION OR SUSPENSION OF THE AGREEMENT OR YOUR USE OF OR ACCESS TO THE WEBSITE, (II) OUR DISCONTINUATION OF ANY OR ALL OF THE WEBSITE, OR, (III) WITHOUT LIMITING ANY OBLIGATIONS UNDER ANY SEPARATELY AGREED SERVICE-LEVEL AGREEMENTS, ANY UNANTICIPATED OR UNSCHEDULED DOWNTIME OF ALL OR A PORTION OF THE WEBSITE FOR ANY REASON, INCLUDING AS A RESULT OF POWER OUTAGES, SYSTEM FAILURES OR OTHER INTERRUPTIONS; (B) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR WEBSITE; (C) ANY INVESTMENTS, EXPENDITURES, OR COMMITMENTS BY YOU IN CONNECTION WITH THIS AGREEMENT OR YOUR USE OF OR ACCESS TO THE WEBSITE; OR (D) ANY UNAUTHORIZED ACCESS TO, ALTERATION OF, OR THE DELETION, DESTRUCTION, DAMAGE, LOSS OR FAILURE TO STORE ANY OF YOUR CONTENT OR OTHER DATA.

10. LIABILITIES, INDEMNITIES & DISCLAIMER

10.1 You shall, upon our demand, defend, indemnify, and hold harmless us, and each of our respective employees, officers, directors, and representatives from and against any claims, damages, losses, liabilities, costs, expenses (including but not limited to debt collection expenses) and legal fees (including reasonable attorneys’ fees) arising out of or relating to any third party claim. If we are obligated to respond to a third-party subpoena or other compulsory legal order or process described above, you shall also reimburse us for legal costs, as well as our employees’ and contractors’ time and materials spent responding to the third-party subpoena or other compulsory legal order or process at our then-current hourly rates.
10.2 You shall indemnify us on demand for any damages and additional expense (including but not limited to legal costs and debt collection expenses) Manta CeDeFi may suffer or incur, directly or indirectly, as a result of unlawful actions conducted by you. 
10.3 The information, software, products, and services published on this website may include inaccuracies or typographical errors. In particular, Manta CeDeFi does not guarantee the accuracy of and disclaim liability for inaccuracies relating to any information or descriptions displayed on this website. 
10.4 Manta CeDeFi makes no representations about the suitability of the information, software,  and services contained on this website for any purpose, and displaying or promotion of any digital assets on this website does not constitute any endorsement or recommendation of investment.  Nothing on the Website constitute any investment advice or any inducement of investment.
10.5 In no event shall Manta CeDeFi be liable for any direct, indirect, punitive, incidental, special, or consequential damages arising out of, or in any way connected with, the use of this website with the delay or inability to use this website, or for any services obtained through this website, or otherwise arising out of the use of this website, whether based on contract, tort, strict liability, or otherwise, even Manta CeDeFi, its affiliates, and/or their respective suppliers have been advised of the possibility of damages.
10.6 You agree to defend and indemnify Manta CeDeFi and any of their officers, directors, employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by:
you or on your behalf in excess of the liability described above; or 
by third parties as a result of (1) your breach of this agreement or the documents referenced herein; (2) your violation of any law or the rights of a third party; or 
(3) your use of this website. 

11. TERM, TERMINATION AND SURVIVAL.

11.1 The Agreement shall, in addition to any other terms and conditions, be as agreed between Manta CeDeFi and the User, and in absence of any other indications the Agreement shall commence upon your visit to the Website, and shall remain valid until further written notice is given by Manta CeDeFi.
11.2 Without prejudice to other provisions (especially clause 11.1 above) in the Agreement, Manta CeDeFi may terminate the Website, the Agreement and all rights granted hereunder immediately and without notice.

12. THIRD PARTY WEBSITES.

12.1 Manta CeDeFi may link to other third-party websites for convenience only and are not part of the Site. Manta CeDeFi does not endorse, confirm or support the contents of third-party websites. Manta CeDeFi is not responsible for the content of a third-party website and cannot attest to the accuracy and completeness of such content. You acknowledge that your use of any third-party website is at your own risk.

13. CYBERATTACKS AND MODIFICATIONS ON BLOCKCHAIN NETWORK.

13.1 Notwithstanding Manta CeDeFi shall use its best endeavours to provide network and cybersecurity to all users, Manta CeDeFi may not have any ability to prevent or mitigate cyberattacks and modifications on the blockchain networks (“Modification”), and Manta CeDeFi does not guarantee any insurance will be cover any cyberattack.  Manta CeDeFi reserves the right to take commercially reasonable actions in such event. 

14. RISK

14.1 By accessing the Site and/or its applications or protocol, you represent and warrant that you understand and are aware the inherent risks associated with cryptographic system, blockchain-based networks, digital assets and smart contract.
14.2 Manta CeDeFi do not have access to your private key(s) of your digital assets wallet.  Losing control of your private key(s) will permanently and irreversibly deny your access to Digital Assets and neither Manta CeDeFi nor any third party may be able to retrieve or protect your digital assets. 
14.3 Your digital assets could be impacted by regulatory inquiries or regulatory actions, which could impede or limit the ability of Manta CeDeFi to continue to make available the Site, its applications or the protocol, which may also limit your ability to access or use the Site
14.4 You hereby assume and agree that Manta CeDeFi will have no responsibility and liability for any risks related to digital assets, your use or access of the Site, and or any actions or transaction, regardless of whether it is linked or associated with the Site. 

15. MISCELLANEOUS.

15.1 Manta CeDeFi and the User are and intend to remain independent parties. Nothing in the Agreement shall be deemed or construed to create the relationship of principal and agent or of partnership or joint venture between Manta CeDeFi and the User.
15.2 The Agreement represents the entire agreement between Manta CeDeFi and User and supersedes any and all other agreements between the parties, whether written or oral, regarding the subject matter hereof (but not include any add-on services as may be requested by the User). Unless otherwise specified in writing by Manta CeDeFi, The Agreement shall govern and supersede any other agreement entered into between Manta CeDeFi and you. Manta CeDeFi may assign its rights or obligations pursuant to the Agreement while the User agrees not to assign any rights under the Agreement without Manta CeDeFi’s explicit written consent. Any translation of the Agreement is provided for your convenience only and in case of any conflict, the English version shall prevail.
15.3 No waiver of any term of the Agreement shall be deemed a further or continuing waiver of such term or any other term, and any failure to assert any right or provision under the Agreement shall not constitute a waiver of such term.
15.4 The section titles in the Agreement are solely used for the convenience and have no legal or contractual significance.
15.5 Your electronic assent or use of the services shall constitute execution of the Agreement. You agree that the electronic text of the Agreement constitutes a “writing” and your assent to the terms and conditions hereof constitutes a “signing” for all purposes.
15.6 If any part of the Agreement shall be invalid or unenforceable, such invalidity or unenforceability shall not affect the validity or enforceability of any other part or provision of the Agreement which shall remain in full force and effect. If any provision or part-provision of the Agreement is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this paragraph shall not affect the validity and enforceability of the rest of the Agreement.
15.7 Manta CeDeFi reserves the right, at its sole discretion, to change, add or remove portions of these Agreement, at any time. Such notification will be made via the Website and it is your responsibility to review the amended Agreement. Your continued use of the Website following the posting of changes will mean that you accept and agree to the changes and you agree that all subsequent transactions by you will be subject to these Agreement.
15.8 The Agreement shall be governed by the laws of the British Virgin Islands as to all matters including validity, construction, effect, performance and remedies without giving effect to the principles of choice of law thereof.`;

const risksText = `• Digital Assets are not subject to any deposit protection scheme in most jurisdictions.  Manta CeDeFi is not a banking institution or a member to the scheme.  Digital Assets held by Manta CeDeFi are not subject to the protections enjoyed by the Scheme, and the assets are not insured.
• It may be illegal, now or in the future, to own, hold, sell or use Digital Assets in one or more countries.  Although currently holding and owning Digital Assets are not regulated or are lightly regulated in most countries, future regulatory actions may restrict your ability to  holding and owning Digital Assets.
• Manta CeDeFi may use other technical service providers or partner with other service providers.  Hardware or software acquired from third parties may contain defects in design or manufacture or other problems that could unexpectedly compromise information security. If a service provider fails to adopt or adhere to adequate data security policies, or in the event of a breach of its networks, information relating to the transactions of the asses and your personal information may be lost or improperly accessed, used, or disclosed.
• Several factors may affect the price of Digital Assets, including, but not limited to: supply and demand, investors’ expectations with respect to the rate of inflation, interest rates, currency exchange rates or future regulatory measures (if any) that restrict the trading of Digital Assets or the use of Digital Assets as a form of payment. There is no assurance that Digital Assets will maintain their long-term value in terms of purchasing power in the future, or that acceptance of Digital Asset payments by mainstream retail merchants and commercial businesses will grow.
• Digital Assets are created, issued, transmitted, and stored according to protocols run by computers in the Digital Asset network.  It is possible these protocols have undiscovered flaws which could result in the loss of some or all assets. There may also be network scale attacks against these protocols which result in the loss of some or all of assets.   Digital Assets may be created, issued, or transmitted using experimental cryptography which could have underlying flaws.  Advancements in quantum computing could break the cryptographic rules of protocols
• Liquidity risk exists when particular investments are difficult to purchase or sell these illiquid investments at an advantageous price
• We may use third party wallet providers to hold a portion of the  Digital Assets and to carry out day-to-day operations, which may be prone to losses arising out of hacking, loss of passwords, compromised access credentials, malware, or cyber-attacks. 
• Digital Assets are controllable only by the possessor of unique private keys relating to the addresses in which the Digital Assets are held.  The theft, loss or destructions of a private key required to access a Digital Asset is irreversible, and such private keys would not capable of being restored
• Many Digital Asset network are online, end-user-to-end-user networks that host a public transaction ledger, known as the blockchain, and the source code that comprises the basis for the cryptographic and algorithmic protocols governing such networks. In many Digital Asset transactions, the recipient of the Digital Assets must provide its public key, which serves as an address for the digital wallet, to the party initiating the transfer. In the data packets distributed from Digital Assets software programs to confirm transaction activity, each Digital Asset user must “sign” transactions with a data code derived from entering the private key into a “hashing algorithm”, which signature serves as validation that the transaction has been authorized by the owner of such Digital Assets.  This process is vulnerable to hacking and malware, and could lead to theft 
• Many Digital Assets are open source projects and, although there may be an influential group of leaders in a specific Community, there may be no official developers or group of developers that formally control the applicable Network. 
• The blockchain code for a Digital Asset may be split, resulting in two different Digital Assets: one that is unaltered and a second, new Digital Asset whose code is based on but differs from the original Digital Asset’s code (a “Hard Fork”).  Further, new Digital Assets may be distributed via “airdrops” to holders of certain existing Digital Assets (an “Airdrop”).  New Digital Assets provided via a Hard Fork or Airdrop are provided involuntarily and without consideration.  A Hard Fork or Airdrop may affect the value of the original Digital Asset.`;
export default function Terms() {
  return (
    <div className="dashboard-bg md:px-12 md:py-6 max-md:px-6 py-4">
      <div className="border bg-white whitespace-pre-wrap md:rounded-[16px] max-md:rounded-[8px] border-green/60 md:p-6 max-md:p-4 text-[14px]">
        <div className="text-[16px] font-[500] md:pb-6 max-md:pb-2">
          MANTA CEDEFI TERM OF USE AGREEMENT
        </div>
        {termsText}
        <div className="font-[500] py-4">Risks Disclosure Statement</div>
        {risksText}
      </div>
    </div>
  );
}
