import { useEffect, useState } from 'react';
import useModal from '@/hooks/useModal';
import PointsBoxModalContent, {
  IPointsBoxContent
} from '@/components/ModalContent/OpenPointsBoxContent';
import { useResponsive } from '@/hooks/useResonposive';

interface IRewardModalsHook {
  BoxesModal: React.JSX.Element;
  openBoxes: () => void;
  setBoxesData: (boxData: IPointsBoxData) => void;
}
interface IProps {
  twitterAuthProcess: () => void;
}
function useRewardModals({ twitterAuthProcess }: IProps): IRewardModalsHook {
  const { isMobile } = useResponsive();
  const [BoxesModal, { onOpen: onOpenBoxesModal, onCancel: onCancelBoxes }] =
    useModal<IPointsBoxContent>(PointsBoxModalContent, {
      title: 'Congratulations',
      width: isMobile ? 342 : 548,
      closable: false,
      maskClosable: false
    });

  const [boxData, setBoxesData] = useState<IPointsBoxData>({
    points: '0'
  });
  useEffect(() => {
    if (boxData && !!Number(boxData.points)) {
      openBoxes();
    }
  }, [boxData]);
  const openBoxes = () => {
    onOpenBoxesModal({
      boxData: boxData,
      onConfirm: () => {
        onCancelBoxes();
      },
      twitterAuthProcess: () => {
        onCancelBoxes();
        twitterAuthProcess();
      }
    });
  };

  return { BoxesModal, openBoxes, setBoxesData };
}

export default useRewardModals;
