import { Select } from 'antd';
import { ChangeEvent, useMemo } from 'react';
import Decimal from 'decimal.js';
import { formatUnits } from 'ethers/lib/utils';
import config from '@/config';
import { cleanNumString, roundDownNumString } from '@/utils/formatNumber';
import useUsdPrices from '@/store/useUsdPrices';
import useMTokenBalance from '@/pages/Stake/UnstakeContent/hooks/useTokenBalance';
import { UnstakeToken, fromTokenList } from '../..';

const { MANTA_PACIFIC_CHAIN } = config;

export type UnstakeFromProps = {
  amount: string;
  setAmount: (amount: string) => void;
  fromToken: UnstakeToken;
  setFromToken: (fromToken: UnstakeToken) => void;
};
export const UnstakeFrom = ({
  amount,
  setAmount,
  fromToken,
  setFromToken
}: UnstakeFromProps) => {
  const usdPrices = useUsdPrices();
  const {
    data: { value: balanceBigNumber } = { formatted: 0 },
    isLoading: queryBalanceLoading
  } = useMTokenBalance({
    address: fromToken?.address as `0x${string}`
  });
  const tokenBalance = useMemo(() => {
    const finalBalance: string = balanceBigNumber
      ? formatUnits(balanceBigNumber, fromToken?.decimals)
      : '0';

    return roundDownNumString(finalBalance, 5);
  }, [fromToken?.decimals, balanceBigNumber]);

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(cleanNumString(e?.target?.value?.trim(), 5));
  };
  const handleTokenChange = (value: string) => {
    const token = fromTokenList?.find(token => token.value === value);
    if (token) {
      setFromToken(token);
    } else {
      throw new Error('can not find token');
    }
  };

  const handleMax = () => {
    setAmount(tokenBalance);
  };

  const displayBalance = () => {
    const decimalBalance = new Decimal(tokenBalance || '0');
    if (decimalBalance.gt(new Decimal(Math.pow(10, 14)))) {
      return decimalBalance.toExponential(5, Decimal.ROUND_HALF_DOWN);
    }
    return tokenBalance;
  };

  return (
    <>
      <div className="mb-2 flex items-center">
        <span className="font-medium mr-2">From:</span>
        {MANTA_PACIFIC_CHAIN.name}
      </div>
      <div className="flex flex-col mb-4 border border-green/40 p-4 rounded-lg text-primary-black">
        <div className="flex items-center mb-1">
          <input
            placeholder="0"
            className="flex-1 font-medium text-xl max-md:min-w-[20px]"
            onChange={handleAmountChange}
            value={amount}
          />
          <Select
            options={fromTokenList}
            className="stake-select w-[155px] max-md:w-[120px]"
            value={fromToken?.value}
            onChange={handleTokenChange}
            popupClassName="stake-select-menu"
          />
        </div>
        <div className="flex items-center text-sm max-md:justify-end">
          {fromToken?.name && (
            <span className="mr-auto max-md:hidden">
              ${((usdPrices[fromToken.name] ?? 0) * Number(amount)).toFixed(5)}
            </span>
          )}

          <span>
            <span className="font-medium">Balance: </span>
            <span className="break-all">
              {queryBalanceLoading ? '--' : displayBalance()}
            </span>
          </span>
          <button className="ml-2 text-green font-medium" onClick={handleMax}>
            Max
          </button>
        </div>
      </div>
    </>
  );
};
