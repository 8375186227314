import { BigNumber, Contract, ethers } from 'ethers';
import useSWR from 'swr';
import { useAccount } from 'wagmi';
import { Chain, erc20Abi } from 'viem';

import config from '@/config';
const { MANTA_PACIFIC_CHAIN } = config;

type Result = {
  value: BigNumber;
};

const useMTokenBalance = ({ address }: { address: string }) => {
  return useErc20TokenBalance({ address, chain: MANTA_PACIFIC_CHAIN as Chain });
};

export const useErc20TokenBalance = ({
  address,
  chain
}: {
  address: string;
  chain?: Chain;
}) => {
  const { address: walletAddress } = useAccount();

  const { data, isLoading } = useSWR<Result>(
    chain ? address + chain?.name : null,
    async () => {
      const provider = new ethers.providers.JsonRpcProvider(
        chain?.rpcUrls?.default?.http[0]
      );
      const tokenContract = new Contract(address, erc20Abi, provider);
      if (address === ethers.constants.AddressZero) {
        const balance = await provider.getBalance(walletAddress ?? '');
        return {
          value: balance
        };
      }
      const balance = await tokenContract.balanceOf(walletAddress);
      return {
        value: balance
      };
    }
  );
  return {
    data,
    isLoading
  };
};

export default useMTokenBalance;
