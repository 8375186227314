import useSWR from 'swr';
import { useAccount } from 'wagmi';
import ENDPOINTS from '@/constants/apiEndpoints';

const fetcher = (url: string) =>
  fetch(url, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => res.json());

function useHistoryRecord() {
  const { address } = useAccount();
  const shouldFetch = address ? `${ENDPOINTS.getStakingHistory}` : null;

  const { data, mutate } = useSWR(shouldFetch, fetcher, {
    revalidateOnFocus: false
  });

  let history = [];
  if (data && data.code === 0) {
    history = data.data || [];
  }
  return {
    history,
    mutate
  };
}
export default useHistoryRecord;
