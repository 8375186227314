import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import checked from '@/assets/svgs/check.svg';
import { useResponsive } from '@/hooks/useResonposive';
import { useModal } from '../WatchAccount/useModal';

export default function WatchTerms() {
  const { isMobile } = useResponsive();
  const { ModalWrapper, showModal, hideModal } = useModal({
    closeDisabled: true
  });
  const [isChecked, setIsChecked] = useState(false);
  const { pathname } = useLocation();
  const judgeShowModal = async () => {
    const hasRead = await localStorage.getItem('readTerms');
    if (!hasRead) {
      showModal();
    }
  };
  const confirmRead = () => {
    localStorage.setItem('readTerms', 'true');
    hideModal();
  };
  useEffect(() => {
    if (!pathname.includes('terms')) {
      judgeShowModal();
    }
  }, []);
  return (
    <ModalWrapper>
      <div className="max-md:w-[341px] text-black-title md:w-[476px] bg-white -mx-6 -my-6 rounded-[16px] p-6 flex flex-col gap-4">
        <div className="text-[16px] font-[500] whitespace-pre-wrap">
          {isMobile
            ? 'Manta CeDeFi \nUser Agreement Updated'
            : 'Manta CeDeFi User Agreement Updated'}
        </div>
        <div className="text-[14px]">
          We have updated the Manta CeDeFi User Agreement.
        </div>
        <div className="text-[14px]">
          Please click{' '}
          <Link className="font-[500] underline" to="/terms" target="_blank">
            here
          </Link>{' '}
          to review it.
        </div>
        <div className="flex items-center">
          <div
            onClick={() => {
              setIsChecked(prevState => !prevState);
            }}
            className={`w-4 h-4 mr-2 flex justify-center items-center border  rounded ${
              isChecked ? '' : 'bg-[#05D9A6]/5 border-[#05D9A6]/40'
            }  `}
          >
            {isChecked && <img src={checked} alt="checked" />}
          </div>
          <div>I have read and confirm the User Agreement.</div>
        </div>
        <button
          disabled={!isChecked}
          className="btn-primary w-full"
          onClick={confirmRead}
        >
          Confirm
        </button>
      </div>
    </ModalWrapper>
  );
}
