import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { useQueryMantaEligibleExtra } from '@/store/useQueryEligibleExtra';
import arrowGreen from '@/assets/svgs/arrow-green.svg';
import arrowUp from '@/assets/arrow-up-bold.svg';
import arrowDown from '@/assets/arrow-down-bold.svg';
import PointItem from '../PointItem';
import { ICollapseProps } from '../../ExtraPoints';

interface IProps {
  show: boolean;
  changeDisplay: (index: keyof ICollapseProps) => void;
}

export default function MantaStakerExtraPoints({
  show,
  changeDisplay
}: IProps) {
  const { data, isLoading } = useQueryMantaEligibleExtra();
  const results = data?.data?.is_eligible;
  const ratio = (data?.data?.ratio || 0) * 100;
  const isEligible = !isLoading && !!results;
  const isNotEligible = !isLoading && !results;
  const isEnded = !isLoading && dayjs().isAfter(dayjs('2024-09-12 14:00 utc'));

  const renderText = useMemo(() => {
    let status = 'init';
    if (isEligible) {
      status = 'applied';
    }
    if (isNotEligible) {
      status = 'notEligible';
    }
    if (isEnded) {
      status = 'ended';
    }

    const renderText: Record<string, React.ReactNode> = {
      init: <div className="text-black-title text-[16px] font-[500]">--</div>,
      applied: (
        <div className="flex flex-col items-end gap-1 max-md:w-[90px]">
          <span className="flex items-center text-black-title text-[16px] font-[500]">
            {!!ratio && (
              <span className="mr-1 text-green text-[16px] font-[500]">
                {ratio}%
              </span>
            )}
            Applied!
          </span>
        </div>
      ),
      notEligible: (
        <div className="flex flex-col">
          <span className="text-black-title max-md:w-[70px] text-[16px] font-[500]">
            Not Eligible
          </span>
          <div className="h-[14px]"></div>
        </div>
      ),
      ended: (
        <div className="flex flex-col items-end gap-1 max-md:w-[90px]">
          <span className="text-black-title text-base font-medium">Ended</span>
        </div>
      )
    };

    return renderText[status];
  }, [isEligible, isNotEligible]);
  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col ">
      <PointItem
        name="MANTA Stakers Direct Points Airdrop 2.0"
        align="items-start"
        textClass="max-md:max-w-[130px] max-md:text-[15px]"
        isFinished={isEligible}
      >
        <div
          onClick={() => {
            changeDisplay(0);
          }}
          className="flex items-start cursor-pointer"
        >
          {renderText}
          <div className="md:ml-4">
            {show ? <img src={arrowUp} /> : <img src={arrowDown} />}
          </div>
        </div>
      </PointItem>

      {show && (
        <div className="flex flex-col gap-2 max-md:w-full">
          <span className="w-full text-black-title text-[14px] font-[400] max-md:mt-1">
            During this event, if you have staked 500 or more MANTA on the Manta
            Atlantic Official Website, or hold 500 or more
            vMANTA/wstMANTA/stMANTA on other platforms, you will be eligible.
            Eligible users will receive a daily base points reward, along with
            an additional percentage bonus (the bonus points will not trigger
            any commission). The more vMANTA/wstMANTA/stMANTA you stake, the
            higher the percentage bonus you will receive.
          </span>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <span className="mb-1 text-black-title/70 text-[14px] max-md:text-[13px] md:mr-4">
                If you have staked 500 or more MANTA on the Manta Atlantic
                Official Website, or hold 500 or more vMANTA/wstMANT/stMANTA on
                other platforms, you will be eligible.
              </span>
              <div className="flex flex-col gap-1 text-green text-[14px] max-md:text-[13px]">
                <div className="flex items-center gap-1 hover:opacity-80 ">
                  <a
                    href="https://app.manta.network/manta/stake"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Official Manta Staking on Atlantic
                  </a>
                  <img src={arrowGreen} alt="" />
                </div>
                <div className="flex items-center gap-1 hover:opacity-80 ">
                  <a
                    href="https://bifrost.app/vstaking/vMANTA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Bifrost
                  </a>
                  <img src={arrowGreen} alt="" />
                </div>
                <div className="flex items-center gap-1 hover:opacity-80 ">
                  <a
                    href="https://accumulated.finance/stake/manta"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Accumulated Finance
                  </a>
                  <img src={arrowGreen} alt="" />
                </div>
                <div className="flex items-center gap-1 hover:opacity-80 ">
                  <a href="https://omni.ls/" target="_blank" rel="noreferrer">
                    Omni.ls
                  </a>
                  <img src={arrowGreen} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <span className="my-2 text-black-title/90 text-sm font-[400]">
              *You need to hold at least $100 or an equivalent amount of tokens
              to earn extra points.
            </span>
            <span className="text-black-title/70 text-[12px] font-[400] italic">
              Campaign Period: Aug 12, at 2PM UTC - Sep 12, at 2PM UTC
            </span>
            <span className="text-black-title/70 text-[12px] font-[400] italic">
              Snapshot/Distribute Time: Daily at 2PM UTC
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
