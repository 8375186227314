import { Popover } from 'antd';
import Arrow from '@/assets/svgs/arrow-left.svg';
import { MTokenInfo, MyPoint } from '@/components/SubNav';
import { useUserRank } from '@/store/useUserRank';
import infoIcon from '@/assets/svgs/info-gray.svg';
import LeaderboardList from './LeaderboardList';

interface IProps {
  onBack: () => void;
}

const LeaderBoardPage = ({ onBack }: IProps) => {
  const { userRankStatus } = useUserRank();
  const { data: rankDataRes } = userRankStatus;
  const currentRank =
    rankDataRes?.data?.rank && rankDataRes?.data?.rank > 0
      ? rankDataRes?.data?.rank
      : '-';
  const navPoints = () => {
    onBack();
  };
  return (
    <div className="flex flex-col max-md:items-center max-md:gap-4">
      <div className="flex flex-col items-center w-full md:hidden">
        <MyPoint />
        <MTokenInfo />
      </div>
      <div
        onClick={navPoints}
        className="w-full cursor-pointer flex justify-start items-center gap-2 md:mb-6 font-[500] text-[16px] text-[#1D314C]/80"
      >
        <img src={Arrow} alt="arrow" className="w-4 h-4" />
        Back to Points
      </div>
      <div className="w-full rounded-[16px] bg-white-80 p-6 border border-primary-green-border">
        <div className="flex w-full justify-between text-black-title font-[500] text-[16px] mb-6">
          <div className="flex items-center gap-2">
            <p>Leaderboard</p>
            <Popover
              overlayInnerStyle={{
                padding: '0',
                backgroundColor: 'transparent',
                boxShadow: 'none'
              }}
              arrow={false}
              placement="bottom"
              content={() => (
                <div className="bg-[#1D314C99] text-white p-2 rounded-[4px]">
                  Hourly Update
                </div>
              )}
              trigger="hover"
            >
              <img src={infoIcon} width={17} height={17} alt={'infoIcon'} />
            </Popover>
          </div>
          <p>Rank #{currentRank}</p>
        </div>
        <LeaderboardList currentUserRankInfo={rankDataRes} />
      </div>
    </div>
  );
};

export default LeaderBoardPage;
